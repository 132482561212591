import React, { useState } from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
    '& p': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  dialogTitle: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    lineHeight: 'normal',
    textAlign: 'start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  body: {
    margin: '16px 24px',  
  },
  doneButton: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

function PopUpAddress({ data }) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (<div>
    <span>Street number: <strong>{data.street_number}</strong></span><br />
    <span>Address line 1: <strong>{data.street_line_1}</strong></span><br />
    <span>Address line 2: <strong>{data.street_line_2}</strong></span><br />
    <span>Zip code: <strong>{data.zip_code}</strong></span><br />
    <span>City: <strong>{data.city}</strong></span><br />
    <span>State: <strong>{data.state}</strong></span><br />
    <span>Country: <strong>{data.country_name}</strong></span>
  </div>);

  const simpleContent = (<div>
    <span>{data.full_address}</span><br />
    <span>{data.country_name}</span>
  </div>);

  return (<div>
    <Tooltip title={content} placement="left">
      <Button
        variant="outlined"
        fontSize="large"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {simpleContent}
      </Button>
    </Tooltip>
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.dialogTitle}
        onClose={handleClose}
      >
        {data.type} Address
      </DialogTitle>
      <Divider />
      <div className={classes.body}>
        {content}
      </div>
      <Divider />
      <div className={classes.doneButton}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </Dialog>
  </div>);
}

export default PopUpAddress;
