import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';
import PriceCorrectionForm from './PriceCorrectionForm';
import { editInvoicePackage } from '../actions';
import useToken from '../login/useToken';
import { numberTwoDecimal } from '../common/functions';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  invoiceIcon: {
    color: '#0000008a',
  },
  dialogContent: {
    marginTop: theme.spacing(0),
    padding: '16px 32px',
  },
  gridSHeader: {
    padding: '0 32px',
    marginBottom: theme.spacing(2),
  },
  addNewLine: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    width: '100%',
  },
  divButton: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5),
  },
  saveAction: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: 150,
  },
  addItem: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  openDialog: {
    width: 110,
    marginTop: theme.spacing(2),
  },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

const DialogCustomized = withStyles(Dialog, () => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 650,
    },
  },
}));

export const KEY_NAMES = [
  'length',
  'width',
  'height',
  'weight',
];

function PriceCorrection(props) {
  const { token } = useToken();
  const { classes } = useStyles();
  const { invoiceCurrent,
    editInvoicePackage,
    invoiceOverchargingLoading,
  } = props;
  const [open, setOpen] = useState(false);
  const [packageGroup] = useState(invoiceCurrent.package_group ? invoiceCurrent.package_group : []);
  const [packageGroupForEdit, setPackageGroupForEdit] = useState(invoiceCurrent.package_group ? invoiceCurrent.package_group.map(pkg => {
    return {
      [KEY_NAMES[0]]: pkg[0] ? pkg[0].toString() : '',
      [KEY_NAMES[1]]: pkg[1] ? pkg[1].toString() : '',
      [KEY_NAMES[2]]: pkg[2] ? pkg[2].toString() : '',
      [KEY_NAMES[3]]: pkg[3] ? pkg[3].toString() : '',
    }
  }) : []);
  const [isEmptyInput, setIsEmptyInput] = useState(false);

  const handleClickOpen = () => {
    setPackageGroupForEdit(invoiceCurrent.package_group ? invoiceCurrent.package_group.map(pkg => {
      return {
        [KEY_NAMES[0]]: pkg[0] ? pkg[0].toString() : '',
        [KEY_NAMES[1]]: pkg[1] ? pkg[1].toString() : '',
        [KEY_NAMES[2]]: pkg[2] ? pkg[2].toString() : '',
        [KEY_NAMES[3]]: pkg[3] ? pkg[3].toString() : '',
      }
    }) : []);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddDimensions = () => {
    setPackageGroupForEdit([ ...packageGroupForEdit, {
      [KEY_NAMES[0]]: '',
      [KEY_NAMES[1]]: '',
      [KEY_NAMES[2]]: '',
      [KEY_NAMES[3]]: '',
    }]);
  };

  const handleRemove = ind => () => {
    const removeFromArr = packageGroupForEdit.filter((flt, i) => (i !== ind));
    setPackageGroupForEdit(removeFromArr);
  };
  
  const handleSubmit = () => {
    let anyEmptyInput = false;
    // Here warning, but checks is_any_empty_input
    const checkEmptyInput = packageGroupForEdit.map(obj => {
      return Object.values(obj).map((val, i) => {
        if(!val.length) {
          anyEmptyInput = true;
        }
      });
    });

    const body = {
      packages: packageGroupForEdit,
    };
    if(anyEmptyInput) {
      setIsEmptyInput(true);
    } else {
      setIsEmptyInput(false);
      editInvoicePackage(token, invoiceCurrent.shipment_id, body);
      handleClose();
    }
  };

  const handleEdit = (index, editedObj) => {
    const editedInvoicePkgs = packageGroupForEdit.map((e, i) => {
      if(i === index) return editedObj;
      return e;
    });

    setPackageGroupForEdit(editedInvoicePkgs);
  };

  return (
    <div>
      <Button
        disabled={invoiceOverchargingLoading}
        size="small"
        className={`${classes.buttonLowerCase} ${classes.openDialog}`}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        {invoiceOverchargingLoading ? <CircularProgress size="2em" /> : 'Saisir'}        
      </Button>
      <DialogCustomized
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Corrections du tarifs
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <Grid container spacing={2} className={classes.gridSHeader}>
            <Grid item xs={5}>
              <Typography>Dimensions :</Typography>
            </Grid>
            <Grid item xs={7}>
              {packageGroup.map((item, i) => <Typography key={i}>{item[0]} x {item[1]} x {item[2]} - {item[3]} kg</Typography>)}
              {packageGroup.length ? null : <Typography>(Empty)</Typography>}
            </Grid>

            <Grid item xs={5}>
              <Typography>Prix de vente original :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{numberTwoDecimal(invoiceCurrent.shipment_amount)}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography>Prix d'achat original :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{numberTwoDecimal(invoiceCurrent.transporter_amount)}</Typography>
            </Grid>
          </Grid>
          {packageGroupForEdit.map((pkg, i) => <PriceCorrectionForm
            pkg={pkg}
            index={i}
            key={i}
            handleEdit={handleEdit}
            isEmptyInput={isEmptyInput}
            handleRemove={handleRemove}
          />)}
          <div className={classes.addItem}>
            <IconButton onClick={handleAddDimensions} size="large">
              <AddCircleIcon />
            </IconButton>
            <p>Ajouter un colis</p>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.saveAction}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Valider
        </Button>
        </DialogActions>
      </DialogCustomized>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    invoiceCurrent: state.invoiceCurrent,
    invoiceOverchargingLoading: state.invoiceOverchargingLoading,
  };
};

export default connect(mapStateToProps, { editInvoicePackage })(PriceCorrection);
