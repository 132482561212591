import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TaskDisplay from './TaskDisplay';
import { convertDateToFrench, convertHoursAndMinutes } from '../common/functions';
import { URL_All } from '../common/Constants';
import { setRefresh } from '../actions';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  openOnClick: {
    '&:hover': {
      cursor: 'pointer',
    },

    // width: 100,

    '& .MuiTableCell-body': {
      // whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  task: {
    display: 'inline-block',

    '& p': {
      display: 'inline-block',
      margin: 0,
    },
  },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

function TaskOpenSelected(props) {
  const { classes } = useStyles();
  const { item,
    isClickedFuture,
    isClickedPast,
    users,
    refresh,
    setRefresh
  } = props;
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);

  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  useEffect(() => {
    setPersonName(users.filter(t => item.to_users.split(',').includes(t.id.toString())).map(item => item.username));
  }, [users]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePersonName = args => {
    setPersonName(args);
  };

  function editCommentTask(id, taskStatus, newCommentTask, personName, dueTo, pinned) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT', // GET, POST, PUT, DELETE
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({due_date: dueTo, comment: newCommentTask, to_users: personName, task_status: taskStatus, updated_by: currentUser, pinned: pinned})
    })
    .then(() => {
      setRefresh(refresh + 1);
    })
    .catch(error => console.error(error))
  };
  
  function deleteComment(id) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({is_actif: 0})
    })
    .then(() => {
      setRefresh(refresh + 1);
    })
    .catch(error => console.error(error))
  };

  const displayTask = (<TaskDisplay
    id={item.id}
    comment={item.comment}
    files={item.files}
    toUsers={item.to_users !== null ? item.to_users : ''}
    isTask={item.is_task}
    taskStatus={item.task_status}
    createdBy={item.created_by}
    updatedBy={item.updated_by}
    createdDate={item.created_at}
    updatedDate={item.updated_at}
    dueDate={item.due_date}
    personName={personName}
    handlePersonName={handlePersonName}
    editCommentTask={editCommentTask}
    deleteComment={deleteComment}
    pinned={false}
    taskCommentTabValue={item.with}
    item={item}
    key={item.id}
  />);

  function showTimeOrDate(dateTime) {
    if(isClickedFuture || isClickedPast) {
      return convertDateToFrench(dateTime * 1000);
    } else {
      return convertHoursAndMinutes(dateTime * 1000);
    }
  };  
    
  const cleancombinedText = DOMPurify.sanitize(item.comment, { USE_PROFILES: { html: true } });

  const htmll = cleancombinedText.replace(/(<([^>]+)>)/gi, "");

  return (
    <React.Fragment>
      <TableRow onClick={handleClickOpen} className={classes.openOnClick}>
        <TableCell>{htmll.length > 16 ? `${htmll.substring(0, 16)}...` : htmll}</TableCell>
        <TableCell>{personName.join(', ').length > 13 ? `${personName.join(', ').slice(0, 13)}...` : personName.join(', ')}</TableCell>
        <TableCell>{showTimeOrDate(item.due_date)}</TableCell>
      </TableRow>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Selected Task
        </DialogTitle>
        <DialogContent dividers>
          {displayTask}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return { users: state.users, refresh: state.refresh };
};

export default connect(mapStateToProps, { setRefresh })(TaskOpenSelected);
