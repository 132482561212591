import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Personnel from './Personnel';
import Tasks from './Tasks';
import Calendar from './Calendar';
import Shipments from './Shipments';
import ActivityFlow from './ActivityFlow';


const heightBoxes = 400;
const gapBoxes = 1;

const useStyles = makeStyles()((theme) => { return {
    root: {
        marginTop: theme.spacing(2),
        
        '& .personnel': {
            height: `calc(${heightBoxes}*2px + ${gapBoxes}*8px)`,
            background: '#F7F7F7',
        },
        
        '& .dashboard-box': {
            height: heightBoxes,
            background: '#F7F7F7',
            textAlign: 'center',
            padding: theme.spacing(2),

            '& .box-title': {
                color: '#433d3d',

            },

            '& .box-body': {
                width: '90%',
                minHeight: `calc(${heightBoxes}px - 100px)`,
                overflowY: 'scroll',
            },
        },
    },
}});

export default function Dashboard() {
    const { classes } = useStyles();

    return(<Grid container spacing={gapBoxes} className={classes.root}>
        <Grid item xs={2}>
            <Personnel />
        </Grid>
        <Grid item xs={10} container spacing={1}>
            <Grid item xs={6} md={6}>
                <Tasks />
            </Grid>
            <Grid item xs={6} md={6}>
                <Calendar />
            </Grid>
            <Grid item xs={6} md={6}>
                <Shipments />
            </Grid>
            <Grid item xs={6} md={6}>
                <ActivityFlow />
            </Grid>
        </Grid>
    </Grid>);
}
