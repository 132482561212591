import expedismart, { URL_EXPEDISMART } from '../apis/expedismart';
import newadmindb, { URL_NEWADMINDB } from '../apis/newadmindb';
import { newadmindb as newadmindbCore } from '../common/Constants';
import { DRAWER_OPEN,
  REFRESH_GENERAL,
  GET_TRACKINGS,
  TRACKINGS_FILTER,
  TRACKINGS_LOADING,
  TRACKINGS_COUNT,
  GET_CHAT_SHIPS,
  CHAT_LOADING,
  CHAT_ERROR,
  CHAT_SEARCH,
  CHAT_UNREAD,
  GET_STATUS_SHIPMENT,
  SELECTED_STATUS_ID,
  GET_STATUS_LIST,
  DELETED_SHIPMENT,
  PICKUP_MODIFY,
  GET_TASK_COMMENT,
  GET_TASK_COUNT,
  TASK_FORM_INPUT,
  GET_TASKS_DASHBOARD,
  SHIPMENT_TASKS_COMMENTS_COUNT,
  CR_TASKS_COMMENTS_COUNT,
  CA_SELECTED_SHIPMENT,
  CA_GET_CUSTOMER_BY_ID,
  CA_GO_TO,
  CA_LOAD_ALL,
  CA_BUTTONS,
  CA_INVOICING,
  GET_USERS,
  ISSUES_LOADING,
  GET_ISSUES,
  ISSUES_SEARCH_INPUT,
  MAKE_SHIPMENT_AN_ISSUE,
  FROM_ISSUES_TO_TRACKINGS,
  POST_ISSUE,
  EDIT_ISSUE,
  REFRESH_ISSUES_PAGE,
  REFRESH_CARD,
  TRANSPORT_FILTERS,
  COUNT_ISSUES,
  // GOOGLE_EVENT_DELETE,
  GOOGLE_EVENT_EMAIL_ERROR,
  EVENT_ERROR_MESSAGE,
  GET_SHIPMENTS_DASHBOARD,
  SHIPMENTS_DASHBOARD_LOADING,
  GET_SHIPMENT_TRANSPORT_DASHBOARD,
  SHIPMENT_TRANSPORT_DASH_LOAD,
  DASHBOARD_CALENDAR_LOADING,
  API_KEY_AND_CALENDAR_ID,
  OPEN_ALERT_TASKS,
  GET_TASK_REMINDER,
  C_R_FILTER_BUTTONS,
  CONTACT_REQUEST_SEARCH,
  CONTACT_REQUEST,
  CONTACT_REQUEST_COUNT,
  CONTACT_REQUEST_LOADING,
  CONTACT_REQUEST_DETAILS,
  CONTACT_REQUEST_TYPES,
  CONTACT_REQUEST_REFRESH,
  CONTACT_REQUEST_DATE,
  STATISTICS_GENERAL_FILTER_BUTTONS,
  STATISTICS_GENERAL_CALL_API,
  STATISTICS_CUSTOMER_FILTER_BUTTONS,
  STATISTICS_GET,
  STATISTICS_GET_PARETO,
  STATISTICS_N1_GET,
  STATISTICS_N2_GET,
  STATISTICS_N3_GET,
  STATISTICS_LOADING,
  STATISTICS_LOADING_COUNT,
  STATISTICS_LOADING_PARETO,
  STATISTICS_LOADING_PARETO_COUNT,
  DOWNLOAD_FILES_LOAD,
  INVOICES_MAIN,
  INVOICES_BILLING,
  INVOICES_LOADING,
  INVOICES_SORT,
  INVOICES_DATE_ERROR,
  INVOICE_EDIT_RESPONSE,
  INVOICE_CURRENT,
  INVOICE_PACKAGE_RESPONSE,
  INVOICE_OVERCHARGING_LOADING,
  INVOICE_BY_SHIP_ID,
  INVOICE_SHIP_ID_LOADING,
  INVOICE_CREATE_LOADING,
  INVOICE_CREATE_SUCCESS,
  SNACKBAR,
} from './types';
import { convertDateToTimestamp } from '../common/functions';
import { logOut } from '../common/Frame';


//////// General ////////
export const setDrawerOpen = value => {
  return {
    type: DRAWER_OPEN,
    payload: value
  };
};

export const setRefresh = value => {
  return {
    type: REFRESH_GENERAL,
    payload: value
  };
};


//////// Trackings ////////
export const getTrackings = token => async dispatch => {
  if(!token) return logOut();

  await expedismart.get(URL_EXPEDISMART.TrackingList, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: GET_TRACKINGS,
      payload: response.data
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(loadingTrackings(false)));
};

export const setTrackingsFilter = value => {
  return {
    type: TRACKINGS_FILTER,
    payload: value
  };
};

export const loadingTrackings = value => {
  return {
    type: TRACKINGS_LOADING,
    payload: value
  };
};

export const setCountTracking = value => {
  return {
    type: TRACKINGS_COUNT,
    payload: value
  };
};


//////// Messaging (Chat) ////////
export const getChatShips = (token, chatShipIds) => async dispatch => {
  if(!token) return logOut();
  dispatch(errorChatShips(false))

  const url = URL_EXPEDISMART.ChatShipment + chatShipIds;
  await expedismart.get(url, {
    headers: { 'authorization': `JWT ${token}` }
  })
    .then(response => {
      if(response.status === 403) return logOut();

      return dispatch(getChatShipsStorage(response.data.results));
    })
    .catch(error => {
      console.error(error);
      dispatch(errorChatShips(true));
    })
    .finally(() => dispatch(loadingChatShips(false)));
};

export const getChatShipsStorage = value => {
  return {
    type: GET_CHAT_SHIPS,
    payload: value
  };
};

export const loadingChatShips = value => {
  return {
    type: CHAT_LOADING,
    payload: value
  };
};

export const errorChatShips = value => {
  return {
    type: CHAT_ERROR,
    payload: value
  };
};

export const setChatSearch = value => {
  return {
    type: CHAT_SEARCH,
    payload: value
  };
};

export const setChatUnreadShips = value => {
  return {
    type: CHAT_UNREAD,
    payload: value
  };
};


//////// Status ////////
export const getStatusshipments = statusId => async dispatch => {
  const response = await newadmindb.get(`${URL_NEWADMINDB.getStatusshipments}?status_id=${statusId}`)

  dispatch({
    type: GET_STATUS_SHIPMENT,
    payload: response.data
  });
};

export const setStatusId = statusId => {
  return {
    type: SELECTED_STATUS_ID,
    payload: statusId
  };
};

export const getStatusList = () => async dispatch => {
  const response = await newadmindb.get(URL_NEWADMINDB.Statuslists);

  dispatch({
    type: GET_STATUS_LIST,
    payload: response.data
  });
}


//////// Shipments ////////
export const deletedShipment = (token, id, currentUser) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${URL_EXPEDISMART.apiShipment}/${id}/removeshipment/`, { headers: { 'authorization' : `JWT ${token}` } }).then(response => {
    if (response.status === 403) return logOut();
  });

  const body = {
    shipment_id: id,
    is_removed: 1,
    created_by: currentUser,
    updated_by: currentUser
  };
  dispatch(modifyStatusShips(body));

  dispatch({
    type: DELETED_SHIPMENT,
    payload: id
  });
};

export const modifyStatusShips = body => async () => {
  await newadmindb.post(URL_NEWADMINDB.postEditStatusshipments, body)
  .catch(error => console.error(error));
};

// Modify Shipment Pickup
export const postModifyPickup = (token, id, body) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.post(`${URL_EXPEDISMART.apiShipment}/${id}/modify_pickup/`,
    body,
    { headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'authorization' : `JWT ${token}` 
    }})
    .then(response => {
      if (response.status === 403) return logOut();

      if(response.data.status === 201) {
        const resp = {
          shipId: response.data.results.id,
          confirmNumber: response.data.results.confirm_number,
          pickup_date: response.data.results.pickup_date,
          ready_time: response.data.results.ready_time,
          close_time: response.data.results.close_time,
        };

        dispatch(setPickupModify(resp));

        dispatch(setSnackbar({
          open: true,
          severity: 1,
        }));
      } else {
        dispatch(setSnackbar({
          open: true,
          severity: 0, // If Error
        }));
      }
    })
    .catch(error => {
      dispatch(setSnackbar({
        open: true,
        severity: 0, // If Error
      }));
      console.error(error);
    });
  };

export const setPickupModify = value => {
  return {
    type: PICKUP_MODIFY,
    payload: value
  };
};


//////// Comment & Task ////////
export const getTaskComment = (id, isRequest) => async dispatch => {
  // Cleans previous array when open new pop-up
  dispatch({
    type: GET_TASK_COMMENT,
    payload: []
  });

  const response = await newadmindb.get(`${URL_NEWADMINDB.CommentTask}/${isRequest ? 'get_shipment_request?request_id' : 'get_shipment?shipment_id'}=${id}&sort=-id`);

  dispatch({
    type: GET_TASK_COMMENT,
    payload: response.data
  });
};

// New Task Input
export const setTaskFormInput = value => {
  return {
    type: TASK_FORM_INPUT,
    payload: value
  };
};

// Post comment-task
export const postCommentTaskActions = props => async () => {
  const { comment,
    file,
    toUsers,
    dueDate,
    isTask,
    customer_id,
    parentId,
    currentUser,
    tracking_nbr,
    tabsValue,
    isShipment,
    isChat,
    email,
  } = props;

  const optionsAxios = {
    headers: { 'enctype': 'multipart/form-data' },
    onUploadProgress: ProgressEvent => {
        console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
    }
  };

  const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);

  const fd = new FormData();
  if(file.length){
    for (let fN=0; fN<file.length; fN++) {
        fd.append(`file-${fN+1}`, file[fN]);
    }
  }
  fd.append('shipment_id', isShipment ? parentId : 0);
  fd.append('customer_id', customer_id);
  fd.append('request_id', parentId);
  fd.append('comment', comment);
  fd.append('created_by', currentUser);
  fd.append('to_users', toUsers);
  fd.append('due_date', dueDateConvert);
  fd.append('tracking_nbr', tracking_nbr);
  fd.append('is_task', isTask);
  fd.append('with', tabsValue);
  fd.append('is_chat', isChat);
  if(isChat) {
    fd.append('is_new', 1);
    fd.append('contact_email', email);
  }

  await newadmindb.post(URL_NEWADMINDB.CommentTask, fd, optionsAxios);
};

// Edit comment-task 
export const editCommentTaskAction = (id, body) => async () => {
  await newadmindb.put(`${URL_NEWADMINDB.CommentTask}/${id}`, body)
    .catch(error => console.error(error));
};

export const getTaskCommentCount = () => async dispatch => {
  const response = await newadmindb.get(URL_NEWADMINDB.ShipmentTaskCommentCount);

  dispatch({
    type: SHIPMENT_TASKS_COMMENTS_COUNT,
    payload: response.data
  });
  
  const responseCR = await newadmindb.get(URL_NEWADMINDB.CRTaskCommentCount);

  dispatch({
    type: CR_TASKS_COMMENTS_COUNT,
    payload: responseCR.data
  });
};

export const getTaskCount = (users, fromChat, token) => async dispatch => {
  if(fromChat) {
    dispatch(getChatShipsStorage([]));
    dispatch(loadingChatShips(true));
    dispatch(errorChatShips(false));
  }

  await newadmindb.get(URL_NEWADMINDB.TaskManageUserCount)
    .then(response => users.map(item => {
      const username = localStorage.getItem('username');
      if(!username) logOut();
      if(item.username.toLowerCase() === username.toLowerCase() & response.data[item.id] !== undefined) {
        if(fromChat) {
          dispatch(getChatShips(token, response.data[item.id].id_shipment_chat_new));
        }
        return dispatch({
          type: GET_TASK_COUNT,
          payload: response.data[item.id]
        });
      } else {
        return {};
      }
    }))
    .catch(error => {
      console.error(error);
      if(fromChat) {
        dispatch(errorChatShips(true));
      }
    });
};

// Get Tasks for Dashboard 
export const getTasksForDashboard = url => async dispatch => {
  const response = await newadmindb.get(`${URL_NEWADMINDB.CommentTask}/${url}`);

  dispatch({
    type: GET_TASKS_DASHBOARD,
    payload: response.data
  });
};


//////// Customer-Account ////////
export const setCASelectedShipment = value => {
  const newArr = {
    tracking_numbers: value.tracking[0].track,
    pickup_contact: value.pickup_contact,
    // pickup_address: value.pickup_address,
    pickup_phone: value.pickup_address.contact_phone,
    pickup_email: value.pickup_address.contact_email,
    delivery_contact: value.delivery_contact,
    // delivery_address: value.delivery_address,
    delivery_phone: value.delivery_address.contact_phone,
    delivery_email: value.delivery_address.contact_email,
  };

  return {
    type: CA_SELECTED_SHIPMENT,
    payload: newArr
  };
};

export const getCustomerByIDAction = (token, id) => async dispatch => {
  if(!token) return logOut();
  
  const response = await expedismart.get(`${URL_EXPEDISMART.ContactList}/${id}/`, { headers: { 'authorization' : `JWT ${token}` } });
  
  if (response.status === 403) return logOut();

  dispatch({
    type: CA_GET_CUSTOMER_BY_ID,
    payload: response.data
  });
};

export const setCAGetCustomerById = value => {
  return {
    type: CA_GET_CUSTOMER_BY_ID,
    payload: value
  };
};

// setGoToCustomerAccount
export const setGoToCA = value => {
  return {
    type: CA_GO_TO,
    payload: value
  };
};


// Clicked Load All Button in Customer Account Page
export const setCALoadAll = value => {
  return {
    type: CA_LOAD_ALL,
    payload: value
  };
};

// Buttons in Customer Account Page
export const setCAButtons = value => {
  return {
    type: CA_BUTTONS,
    payload: value
  };
};

// Invoicing - Customer Account Page
export const setCAInvoicing = value => {
  return {
    type: CA_INVOICING,
    payload: value
  };
};


//////// Users ////////
export const getUsers = () => async dispatch => {
  const response = await newadmindb.get(URL_NEWADMINDB.Users);

  dispatch({
    type: GET_USERS,
    payload: response.data
  });
};


//////// Issues ////////
export const setIssuesLoading = value => {
  return {
    type: ISSUES_LOADING,
    payload: value
  };
};

export const getIssues = () => async dispatch => {
  await newadmindb.get(URL_NEWADMINDB.Issues)
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: GET_ISSUES,
      payload: response.data
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(setIssuesLoading(false)));
};

export const setIssuesSearchInput = input => {
  return {
    type: ISSUES_SEARCH_INPUT,
    payload: input
  };
};

export const makeShipmentAnIssue = (shipmentId, token) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${URL_EXPEDISMART.apiShipment}/${shipmentId}/has_issue/`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: MAKE_SHIPMENT_AN_ISSUE,
      payload: response.data
    })})
    .catch(error => console.error(error))
};

export const fromIssuesToTrackings = (shipmentId, token) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${URL_EXPEDISMART.apiShipment}/${shipmentId}/has_no_issue/`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: FROM_ISSUES_TO_TRACKINGS,
      payload: response.data
    })})
    .catch(error => console.error(error))
};

export const postIssue = (shipmentId, dateClient, dateAssurance, dateTransporteur, typeIssue, currentUser) => async dispatch => {
  await newadmindb.post(URL_NEWADMINDB.postEditStatusshipments, {
    shipment_id: shipmentId,
    date_carrier: dateTransporteur,
    date_insurance: dateAssurance,
    date_customer: dateClient,
    type_issue: typeIssue,
    created_by: currentUser,
    updated_by: currentUser
  })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: POST_ISSUE,
      payload: response
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(getIssues()))
};

export const editIssue = (shipmentId, typeIssue, currentUser, tableInputs, dateClient, dateAssurance, dateTransporteur) => async dispatch => {
  // Even if edit, here should be post
  // Database check itself if exist, if exist then automaticly edit
  await newadmindb.post(URL_NEWADMINDB.postEditStatusshipments, {
    shipment_id: shipmentId,
    type_issue: typeIssue,
    updated_by: currentUser,
    purchase: tableInputs.purchase,
    purchase_to_refund: tableInputs.purchase_to_refund,
    purchase_refunded: tableInputs.purchase_refunded,
    sale: tableInputs.sale,
    sale_to_refund: tableInputs.sale_to_refund,
    sale_refunded: tableInputs.sale_refunded,
    customsvalue: tableInputs.customsvalue,
    customsvalue_to_refund: tableInputs.customsvalue_to_refund,
    customsvalue_refunded: tableInputs.customsvalue_refunded,
    customer_to_refund: tableInputs.customer_to_refund,
    customer_refunded: tableInputs.customer_refunded,
    date_insurance: dateAssurance,
    date_customer: dateClient,
    date_carrier: dateTransporteur
  })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: EDIT_ISSUE,
      payload: response
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(getIssues()))
};

export const setRefreshIssuesPage = value => {
  return {
    type: REFRESH_ISSUES_PAGE,
    payload: value
  };
};

export const setRefreshCard = value => {
  return {
    type: REFRESH_CARD,
    payload: value
  };
};

export const setTransportFilters = value => {
  return {
    type: TRANSPORT_FILTERS,
    payload: value
  };
};

export const setIssuesCount = value => {
  return {
    type: COUNT_ISSUES,
    payload: value
  };
};


//////// Dashboard ////////
export const setGoogleEventError = value => {
  return {
    type: GOOGLE_EVENT_EMAIL_ERROR,
    payload: value
  };
};

export const setDashboardEventErrorMessage = value => {
  return {
    type: EVENT_ERROR_MESSAGE,
    payload: value
  };
};

// Envois - Shipments (get)
export const getShipmentForDashboard = (date, token) => async dispatch => {
  if(!token) return logOut();
  
  let getShipmentUrl = `${URL_EXPEDISMART.ShipmentList}all&from=${date}&is_ddp=${'false'}&to=${date}&type=${'all'}`;

  await expedismart.get(getShipmentUrl, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: GET_SHIPMENTS_DASHBOARD,
      payload: response.data
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(setShipmentsDashboardLoading(false)));
};

export const setShipmentsDashboardLoading = value => {
  return {
    type: SHIPMENTS_DASHBOARD_LOADING,
    payload: value
  };
};

export const getShipmentTransportDashboard = (id, token) => async dispatch => {
  if(!token) return logOut();
  
  const url = `${URL_EXPEDISMART.apiShipment}/${id}/quotes/`;

  await expedismart.get(url, {
    headers: {
      'authorization': `JWT ${token}`
    }
  })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
      type: GET_SHIPMENT_TRANSPORT_DASHBOARD,
      payload: response.data
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(setShipmentTransportDashLoad(false)));
};

export const setShipmentTransportDashLoad = value => {
  return {
    type: SHIPMENT_TRANSPORT_DASH_LOAD,
    payload: value
  };
};

export const setCalendarLoading = value => {
  return {
    type: DASHBOARD_CALENDAR_LOADING,
    payload: value
  };
};

export const setApiKeyAndClientId = value => {
  return {
    type: API_KEY_AND_CALENDAR_ID,
    payload: value
  };
};


//////// Alert-Tasks (Reminder) ////////
export const setOpenAlertTasks = value => {
  return {
    type: OPEN_ALERT_TASKS,
    payload: value
  };
};


export const getTaskReminder = (user_id) => async (dispatch, getState) => {
  const response = await newadmindb.get(`${URL_NEWADMINDB.CommentTask}/get_tasks_snooze?user_id=${user_id}`)

  // do not show new chat as pop-up, 2024-07-18
  const responseData = response.data.filter(item => !item.is_chat);

  dispatch({
    type: GET_TASK_REMINDER,
    payload: responseData
  });
  
  const state  = getState();
  if(state.alertTasks.length && !state.alertTasksOpen) {
    dispatch(setOpenAlertTasks(true));
  }
};


//////// Contact Request ////////
export const setCRFilterButtons = value => {
  return {
    type: C_R_FILTER_BUTTONS,
    payload: value
  };
};

export const setCRSearch = value => {
  return {
    type: CONTACT_REQUEST_SEARCH,
    payload: value
  };
};

export const setContactRequestCount = () => async (dispatch, getState) => {
  const newDate = new Date();
  const setHrs = newDate.setUTCHours(23, 59, 59);
  const toDate = convertDateToTimestamp(setHrs);
  const contactRequestCount = getState().contactRequestCount;

  await newadmindb.get(`${URL_NEWADMINDB.contactRequest}?types_webrequest=1;2;3;4;5&status=1&from=0&to=${toDate}&search=`)
    .then(response => {
      if (response.status === 403) return logOut();

      const data = { ...contactRequestCount,
        total: response.data.length,
        
        general: response.data.filter(fl => fl.type_webrequest === 1).length,
        technicalProblem: response.data.filter(fl => fl.type_webrequest === 2).length,
        discount: response.data.filter(fl => fl.type_webrequest === 3).length,
        pallet: response.data.filter(fl => fl.type_webrequest === 4).length,
        estimate: response.data.filter(fl => fl.type_webrequest === 5).length,
      };
      
      return dispatch({
        type: CONTACT_REQUEST_COUNT,
        payload: data
    })})
    .catch(error => console.error(error))
};

export const setContactRequest = () => async (dispatch, getState) => {
  dispatch(setCRDetails());

  const state = getState();
  const crFilterButtons = state.crFilterButtons;

  const types_webrequestObj = {
    filter1: crFilterButtons.general ? '1' : null, 
    filter2: crFilterButtons.technicalProblem ? '2' : null, 
    filter3: crFilterButtons.discount ? '3' : null, 
    filter4: crFilterButtons.pallet ? '4' : null, 
    filter5: crFilterButtons.estimate ? '5' : null, 
  };
  const types_webrequest = Object.values(types_webrequestObj).filter(fl => fl).join(';');

  const statusObj = {
    filter1: crFilterButtons.untreated ? '1' : null, 
    filter2: crFilterButtons.inProgress ? '2' : null, 
    filter3: crFilterButtons.ended ? '3' : null, 
  };
  const status = Object.values(statusObj).filter(fl => fl).join(';');

  const search = state.crSearch;
  
  const crDate = state.crDate;
  
  const newDate = new Date(crDate.to);
  const setHrs = newDate.setUTCHours(23, 59, 59);
  const fromDate = convertDateToTimestamp(crDate.from);
  const toDate = convertDateToTimestamp(setHrs);

  await newadmindb.get(`${URL_NEWADMINDB.contactRequest}?types_webrequest=${types_webrequest}&status=${status}&from=${fromDate}&to=${toDate}&search=${search}`)
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch({
        type: CONTACT_REQUEST,
        payload: response.data
    })})
    .catch(error => console.error(error))
    .finally(() => dispatch(setCRLoading(false)));
};

export const setCRLoading = value => {
  return {
    type: CONTACT_REQUEST_LOADING,
    payload: value
  };
};

export const setCRDetails = () => async dispatch => {
  const response = await newadmindb.get(`${URL_NEWADMINDB.contactRequest}_ext`);

  dispatch({
    type: CONTACT_REQUEST_DETAILS,
    payload: response.data
  });
};

export const setCRTypes = () => async dispatch => {
  const response = await newadmindb.get(URL_NEWADMINDB.contactRequestTypes);

  dispatch({
    type: CONTACT_REQUEST_TYPES,
    payload: response.data
  });
};

export const setRefreshCR = value => {
  return {
    type: CONTACT_REQUEST_REFRESH,
    payload: value
  };
};

export const editCR = (id, body) => async dispatch => {
  await newadmindb.put(`${URL_NEWADMINDB.CommentTask}/${id}`, body)
    .then(() => dispatch(setCRDetails()))
    .catch(error => console.error(error))
    .finally(() => dispatch(setContactRequestCount()));
};

export const setCRDate = value => {
  return {
    type: CONTACT_REQUEST_DATE,
    payload: value
  };
};


//////// Statistics ////////
export const setSGeneralFilterButtons = value => {
  return {
    type: STATISTICS_GENERAL_FILTER_BUTTONS,
    payload: value
  };
};

export const setSCustomerFilterButtons = value => {
  return {
    type: STATISTICS_CUSTOMER_FILTER_BUTTONS,
    payload: value
  };
};

export const setStatisticsGenCallApi = value => {
  return {
    type: STATISTICS_GENERAL_CALL_API,
    payload: value
  };
};

export const setGetStatistics = value => {
  return {
    type: STATISTICS_GET,
    payload: value
  };
};

export const setGetStatisticsN1 = value => {
  return {
    type: STATISTICS_N1_GET,
    payload: value
  };
};

export const setGetStatisticsN2 = value => {
  return {
    type: STATISTICS_N2_GET,
    payload: value
  };
};

export const setGetStatisticsN3 = value => {
  return {
    type: STATISTICS_N3_GET,
    payload: value
  };
};

export const setGetStatisticsPareto = value => {
  return {
    type: STATISTICS_GET_PARETO,
    payload: value
  };
};

// loadingStatistics
export const setStatisticsLoading = value => {
  return {
    type: STATISTICS_LOADING,
    payload: value
  };
};

export const setStatisLoadCount = value => {
  return {
    type: STATISTICS_LOADING_COUNT,
    payload: value
  };
};

export const setStatisticsLoadingPareto = value => {
  return {
    type: STATISTICS_LOADING_PARETO,
    payload: value
  };
};

export const setStatisLoadParetoCount = value => {
  return {
    type: STATISTICS_LOADING_PARETO_COUNT,
    payload: value
  };
};

export const getStatisticsGeneral = token => async (dispatch, getState) => {
  const state = getState().statisticsGeneralFilterButtons;
  const statisticsLoading = getState().statisticsLoading;

  const { y2022,
    twelveMonth,
    quarterFirst,
    quarterSecond,
    quarterThird,
    quarterFouth,
    n1,
    n2,
    n3,
    pareto,
    paretoOfPareto,
  } = state;

  let parameter = '';
  if(y2022) {
    const currentYear = new Date().getFullYear();
    parameter = `&uptoyear=${currentYear}`;
  } else if(twelveMonth) {
    parameter = `&mthlim=12`;
  } else if(quarterFirst) {
    parameter = '&quarter=1';
  } else if(quarterSecond) {
    parameter = '&quarter=2';
  } else if(quarterThird) {
    parameter = '&quarter=3';
  } else if(quarterFouth) {
    parameter = '&quarter=4';
  }

  const url = `${URL_EXPEDISMART.getStatistics}?customer_type=0${parameter}`;

  if(n1 && statisticsLoading.n1) {
    dispatch(getStatisticsN1(token, url, statisticsLoading));
  };

  if(n2 && statisticsLoading.n2) {
    dispatch(getStatisticsN2(token, url, statisticsLoading));
  };

  if(n3 && statisticsLoading.n3) {
    dispatch(getStatisticsN3(token, url, statisticsLoading));
  };

  if(statisticsLoading.groupOne && (y2022 | twelveMonth | quarterFirst | quarterSecond | quarterThird | quarterFouth)) {
    dispatch(getStatistics(token, url, statisticsLoading));
  }

  if(pareto | paretoOfPareto) {
    dispatch(getStatisticsPareto(token, url));
  }
};

export const getStatisticsPareto = (token, url) => async (dispatch, getState) => {
  if(!token) return logOut();
  
  const state = getState().statisticsGeneralFilterButtons;
  const statisticsGetPareto = getState().statisticsGetPareto;
  const statisticsLoadingPareto = getState().statisticsLoadingPareto;
  
  const { y2022,
    twelveMonth,
    quarterFirst,
    quarterSecond,
    quarterThird,
    quarterFouth,
    n1,
    n2,
    n3,
    pareto,
    paretoOfPareto,
  } = state;

  if(pareto) { url = `${url}&pareto=1`; }
  if(paretoOfPareto) { url = `${url}&pareto=2`; }
  
  if(n1 && statisticsLoadingPareto.n1Pareto) {      
    await expedismart.get(`${url}&delta=1`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsPareto({ ...statisticsGetPareto, n1: response.data }))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoadingPareto({ ...statisticsLoadingPareto, n1Pareto: false }));
    });
  };

  if(n2 && statisticsLoadingPareto.n2Pareto) {      
    await expedismart.get(`${url}&delta=2`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsPareto({ ...statisticsGetPareto, n2: response.data }))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoadingPareto({ ...statisticsLoadingPareto, n2Pareto: false }));
    });
  };

  if(n3 && statisticsLoadingPareto.n3Pareto) {      
    await expedismart.get(`${url}&delta=3`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsPareto({ ...statisticsGetPareto, n3: response.data }))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoadingPareto({ ...statisticsLoadingPareto, n3Pareto: false }));
    });
  };
  
  if(statisticsLoadingPareto.groupOnePareto && (y2022 | twelveMonth | quarterFirst | quarterSecond | quarterThird | quarterFouth)) {
    await expedismart.get(url, { headers: { 'authorization' : `JWT ${token}` } })
      .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsPareto({ ...statisticsGetPareto, mainStatisticsP: response.data }))})
      .catch(error => console.error(error))
      .finally(() => {
        dispatch(setStatisticsLoadingPareto({ ...statisticsLoadingPareto, groupOnePareto: false }));
      });
  }
};

export const getStatistics = (token, url, statisticsLoading) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(url, { headers: { 'authorization' : `JWT ${token}` } })
      .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatistics(response.data))})
      .catch(error => console.error(error))
      .finally(() => {
        dispatch(setStatisticsLoading({ ...statisticsLoading, groupOne: false }));
      });
};

export const getStatisticsN1 = (token, url, statisticsLoading) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${url}&delta=1`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsN1(response.data))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoading({ ...statisticsLoading, n1: false }));
    });
};

export const getStatisticsN2 = (token, url, statisticsLoading) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${url}&delta=2`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsN2(response.data))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoading({ ...statisticsLoading, n2: false }));
    });
};

export const getStatisticsN3 = (token, url, statisticsLoading) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.get(`${url}&delta=3`, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setGetStatisticsN3(response.data))})
    .catch(error => console.error(error))
    .finally(() => {
      dispatch(setStatisticsLoading({ ...statisticsLoading, n3: false }));
    });
};

export const downloadFiles = (shipmentId, tracking_nbr) => async dispatch => {
  dispatch(setDownloadFilesLoad(true));

  await newadmindb.get(`${URL_NEWADMINDB.CommentTask}/get_zip_files?shipment_id=${shipmentId}&sort=-id`)
    .then(() => dispatch(downloadFiles2(shipmentId, tracking_nbr)))
    .catch(err => {
      console.log(err);
      setDownloadFilesLoad(false);
    });
}

export const downloadFiles2 = (shipmentId, tracking_nbr) => async dispatch => {
  let fileName = shipmentId;
  if(tracking_nbr && tracking_nbr.length) {
    const tracking_nbrToArr = tracking_nbr.split('<br/>')[0];
    fileName = tracking_nbrToArr;
  } 

  await fetch(`${newadmindbCore}/basic/web/uploads/${shipmentId}.zip`)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style = "display: none";

      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(blobURL);
      a.remove();
    })
    .catch(error => console.error(error))
    .finally(() => dispatch(setDownloadFilesLoad(false)));
}

export const setDownloadFilesLoad = value => {
  return {
    type: DOWNLOAD_FILES_LOAD,
    payload: value
  };
};


//////// Invoices Expedismart ////////
export const setInvoicesMain = value => {
  return {
    type: INVOICES_MAIN,
    payload: value
  };
};

export const getInvoices = (token, newPage) => async (dispatch, getState) => {
  if(!token) return logOut();
  
  const invoicesMain  = getState().invoicesMain;

  if(!invoicesMain.dateFrom || !invoicesMain.dateTo) {
    dispatch(setInvoicesDateError(true));
    return;
  } else {
    dispatch(setInvoicesDateError(false));
  }
  
  dispatch(setInvoicesLoading(true));
  
  let pageQuery = '';
  if(newPage && newPage !== 1) {
    pageQuery = `&page=${newPage}`;
  }
  
  let url = `${URL_EXPEDISMART.invoices}/?open=true&from=${invoicesMain.dateFrom}&to=${invoicesMain.dateTo}&controled=${invoicesMain.controled}${pageQuery}`;
  
  if(invoicesMain.searchTerm) {
    url = `${URL_EXPEDISMART.invoices}/?&q=${invoicesMain.searchTerm}${pageQuery}`;
  }

  dispatch(setInvoicesMain({ ...invoicesMain, 
    invoices: null,
    count: 0,
  }));

  await expedismart.get(url, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setInvoicesMain({ ...invoicesMain, 
      invoices: response.data.results,
      count: response.data.count,
    }))})
    .catch(error => console.error(error))
    .finally(() => dispatch(setInvoicesLoading(false)));
};

export const setInvoicesBilling = value => {
  return {
    type: INVOICES_BILLING,
    payload: value
  };
};

export const setInvoicesLoading = value => {
  return {
    type: INVOICES_LOADING,
    payload: value
  };
};

export const editInvoice = (token, id, body) => async dispatch => {
  if(!token) return logOut();
  
  await expedismart.put(`${URL_EXPEDISMART.invoices}/${id}/`,
    body, 
    { headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'authorization' : `JWT ${token}` 
    }})
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(editInvoceHelper(id, response.data))})
    .catch(error => console.error(error));
};

const editInvoceHelper = (id, item) => (dispatch, getState) => {
  const invoicesMain  = getState().invoicesMain;
  const invoicesArr  = invoicesMain.invoices;
  const editedInvoicesArr = invoicesArr.map(obj => (obj.id === id) ? item : obj);

  dispatch(setInvoicesMain({ ...invoicesMain, invoices: editedInvoicesArr }));
  dispatch(setInvoiceEditResponse(item));
};

export const setInvoicesSort = value => {
  return {
    type: INVOICES_SORT,
    payload: value
  };
};

export const setInvoicesDateError = value => {
  return {
    type: INVOICES_DATE_ERROR,
    payload: value
  };
};

export const setInvoiceEditResponse = value => {
  return {
    type: INVOICE_EDIT_RESPONSE,
    payload: value
  };
};

export const setInvoiceCurrent = value => {
  return {
    type: INVOICE_CURRENT,
    payload: value
  };
};

export const editInvoicePackage = (token, id, body) => async dispatch => {
  if(!token) return logOut();
  
  dispatch(setInvoiceOvercharginLoading(true));
  dispatch(setInvoicePackageResponse([]));

  await expedismart.post(`${URL_EXPEDISMART.apiShipment}/${id}/quotes_search/`,
    body, 
    { headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'authorization' : `JWT ${token}` 
    }})
    .then(response => {
      if (response.status === 403) return logOut();      
      if(response.data.length) dispatch(setInvoicePackageResponse(response.data));
    })
    .catch(error => console.error(error))
    .finally(() => dispatch(setInvoiceOvercharginLoading(false)))
};

export const setInvoicePackageResponse = value => {
  return {
    type: INVOICE_PACKAGE_RESPONSE,
    payload: value
  };
};

export const setInvoiceOvercharginLoading = value => {
  return {
    type: INVOICE_OVERCHARGING_LOADING,
    payload: value
  };
};

export const getInvoiceByShipId = (token, shipId, is_CA) => async dispatch => {
  if(!token) return logOut();
  
  dispatch(setInvoiceByShipId({}));
  dispatch(setInvoiceShipIdLoading(true));

  let url = `${URL_EXPEDISMART.apiShipment}/${shipId}/get_data_overbilling/`;

  // Customer Account Invoicing api is different
  if(is_CA) {
    url = `${URL_EXPEDISMART.apiUsers}/${shipId}/get_data_invoice/`;
  }

  await expedismart.get(url, { headers: { 'authorization' : `JWT ${token}` } })
    .then(response => {
      if (response.status === 403) return logOut();
      
      return dispatch(setInvoiceByShipId(response.data))})
    .catch(error => {
      dispatch(setSnackbar({
        open: true,
        severity: 0, // If Error
      }));
      console.error(error);
    })
    .finally(() => dispatch(setInvoiceShipIdLoading(false)));
};

export const setInvoiceByShipId = value => {
  return {
    type: INVOICE_BY_SHIP_ID,
    payload: value
  };
};

export const setInvoiceShipIdLoading = value => {
  return {
    type: INVOICE_SHIP_ID_LOADING,
    payload: value
  };
};

export const postCreateInvoice = (token, shipId, body, is_CA) => async dispatch => {
  if(!token) return logOut();
  
  dispatch(setCreateIncoiceLoading(true));
  dispatch(setInvoiceCreateSuccess(false));

  let url = `${URL_EXPEDISMART.apiShipment}/${shipId}/create_overbilling_invoice/`;

  // Customer Account Invoicing api is different
  if(is_CA) {
    url = `${URL_EXPEDISMART.apiUsers}/${shipId}/create_customer_invoice/`;
  }

  await expedismart.post(url,
    body, 
    { headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'authorization' : `JWT ${token}` 
    }})
    .then(response => {
      if (response.status === 403) return logOut();

      dispatch(setSnackbar({
        open: true,
        severity: 1,
      }));
      dispatch(setInvoiceCreateSuccess(true));
      dispatch(getInvoices(token));
    })
    .catch(error => {
      dispatch(setSnackbar({
        open: true,
        severity: 0,
      }));
      console.error(error);
    })
    .finally(() => dispatch(setCreateIncoiceLoading(false)));
};

export const setCreateIncoiceLoading = value => {
  return {
    type: INVOICE_CREATE_LOADING,
    payload: value
  };
};

export const setInvoiceCreateSuccess = value => {
  return {
    type: INVOICE_CREATE_SUCCESS,
    payload: value
  };
};


// Snackbar 
export const setSnackbar = value => {
  return {
    type: SNACKBAR,
    payload: value
  };
};
