import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { setSGeneralFilterButtons,
  setStatisticsGenCallApi,
  setGetStatistics,
  setGetStatisticsN1,
  setGetStatisticsN2,
  setGetStatisticsN3,
  setStatisticsLoading,
  setStatisticsLoadingPareto,
  setGetStatisticsPareto,
} from '../../actions';


const borderColor = '#010101';

const useStyles = makeStyles()((theme) => { return {
  secondLine: {
    marginBottom: theme.spacing(.5),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  marginLeft16: {
    marginLeft: theme.spacing(2),
  },
  table: {
    '& tr, th': {
      padding: 0,
    },
  },
  tableHead:{
    background: '#d6dce4',

    '& th': {
      textAlign: 'center',
      fontWeight: '700',
      borderTop: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },

    '& th:first-of-type': {
      borderLeft: `1px solid ${borderColor}`,
    },
  },
}});

const theme = createTheme({
  breakpoints: {
    values: {
      md: 1550,
      lg: 1700,
    },
  },
  palette: {
    v4default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
  },
});

const theme2 = createTheme({
  breakpoints: {
    values: {
      md: 1700,
      lg: 1920,
    },
  },
  palette: {
    v4default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
  },
});

function ContactRequestHeader(props) {
  const { classes } = useStyles();
  const { statisticsGeneralFilterButtons,
    statisticsGenCallApi,
    setStatisticsGenCallApi,
    setSGeneralFilterButtons,
    setGetStatistics,
    setGetStatisticsN1,
    setGetStatisticsN2,
    setGetStatisticsN3,
    statisticsLoading,
    setStatisticsLoading,
    statisticsLoadingCount,
    statisticsLoadingPareto,
    statisticsLoadingParetoCount,
    setStatisticsLoadingPareto,
    statisticsGetPareto,
    setGetStatisticsPareto,
    drawerOpen,
  } = props;

  const handleFilters = props => () => {
    const selectOnly = ['y2022', 'twelveMonth', 'quarterFirst', 'quarterSecond', 'quarterThird', 'quarterFouth'];
    const onlyOnePareto = ['pareto', 'paretoOfPareto'];
    const nButtons = ['n1', 'n2', 'n3'];
    
    if(selectOnly.indexOf(props) > -1) {
      setGetStatistics({});
      setGetStatisticsN1({});
      setGetStatisticsN2({});
      setGetStatisticsN3({});
      setGetStatisticsPareto({ ...statisticsGetPareto,
        mainStatisticsP: {},
        n1: {},
        n2: {},
        n3: {}
      });

      if(statisticsGeneralFilterButtons[props]) {
        return setSGeneralFilterButtons({ ...statisticsGeneralFilterButtons,
          [props]: false,
          n1: false,
          n2: false,
          n3: false,
          pareto: false,
          paretoOfPareto: false,
        });
      }

      setStatisticsLoading({ ...statisticsLoading, groupOne: true });
    
      const allFalse = selectOnly.filter(ft => ft !== props);
      setSGeneralFilterButtons({ ...statisticsGeneralFilterButtons,
        [allFalse[0]]: false,
        [allFalse[1]]: false,
        [allFalse[2]]: false,
        [allFalse[3]]: false,
        [allFalse[4]]: false,
        [props]: true,
        n1: false,
        n2: false,
        n3: false,
        pareto: false,
        paretoOfPareto: false,
      });
      setStatisticsGenCallApi(statisticsGenCallApi + 1);
      return;
    }

    if(nButtons.indexOf(props) > -1) {
      setSGeneralFilterButtons({ ...statisticsGeneralFilterButtons, [props]: !statisticsGeneralFilterButtons[props] });

      if(statisticsGeneralFilterButtons[props]) {
        if(props === 'n1') { setGetStatisticsN1({}); }
        if(props === 'n2') { setGetStatisticsN2({}); }
        if(props === 'n3') { setGetStatisticsN3({}); }

        if(statisticsGeneralFilterButtons.pareto | statisticsGeneralFilterButtons.paretoOfPareto) {
          if(props === 'n1') { setGetStatisticsPareto({ ...statisticsGetPareto, n1: {} }); }
          if(props === 'n2') { setGetStatisticsPareto({ ...statisticsGetPareto, n2: {} }); }
          if(props === 'n3') { setGetStatisticsPareto({ ...statisticsGetPareto, n3: {} }); }
        }

        return;
      }
      
      setStatisticsLoading({ ...statisticsLoading, [props]: true });
      
      if(statisticsGeneralFilterButtons.pareto | statisticsGeneralFilterButtons.paretoOfPareto) {
        setStatisticsLoadingPareto({ ...statisticsLoadingPareto, [`${props}Pareto`]: true });
      }

      setStatisticsGenCallApi(statisticsGenCallApi + 1);
      return;
    }

    const otherPareto = onlyOnePareto.filter(flt => flt !== props);
    if(onlyOnePareto.indexOf(props) > -1) {
      setGetStatisticsPareto({ ...statisticsGetPareto, mainStatisticsP: {}, n1: {}, n2: {}, n3: {} });

      if(statisticsGeneralFilterButtons[props]) {
        return setSGeneralFilterButtons({ ...statisticsGeneralFilterButtons, [props]: false });
      }

      setStatisticsLoadingPareto({ ...statisticsLoadingPareto, groupOnePareto: true });
      setSGeneralFilterButtons({
        ...statisticsGeneralFilterButtons,
        [otherPareto]: false,
        [props]: true,
        n1: false,
        n2: false,
        n3: false,
      });
      setGetStatisticsN1({});
      setGetStatisticsN2({});
      setGetStatisticsN3({});
      setStatisticsGenCallApi(statisticsGenCallApi + 1);
      return;
    }

  };

  const currentYear = new Date().getFullYear();

  // 1650
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={drawerOpen ? theme2 : theme}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={2} md={2} lg={3} />
            <Grid item xs={8} md={7} lg={6}
              container
              justifyContent="space-between"
            >
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.y2022 ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.y2022 ? "secondary" : "v4default"}
                onClick={handleFilters('y2022')}
              >
                {currentYear}
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.twelveMonth ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.twelveMonth ? "secondary" : "v4default"}
                onClick={handleFilters('twelveMonth')}
              >
                12 mois
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.quarterFirst ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.quarterFirst ? "secondary" : "v4default"}
                onClick={handleFilters('quarterFirst')}
              >
                1ère trimestre
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.quarterSecond ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.quarterSecond ? "secondary" : "v4default"}
                onClick={handleFilters('quarterSecond')}
              >
                2ème trimestre
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.quarterThird ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.quarterThird ? "secondary" : "v4default"}
                onClick={handleFilters('quarterThird')}
              >
                3ème trimestre
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.quarterFouth ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.quarterFouth ? "secondary" : "v4default"}
                onClick={handleFilters('quarterFouth')}
              >
                4ème trimestre
              </Button>
              <Button
                disabled
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.daysSeven ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.daysSeven ? "secondary" : "v4default"}
                onClick={handleFilters('daysSeven')}
              >
                7 jours
              </Button>
              <Button
                disabled
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.daysOne ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.daysOne ? "secondary" : "v4default"}
                onClick={handleFilters('daysOne')}
              >
                1 jours
              </Button>
            </Grid>
            <Grid item xs={2} md={3} lg={3} />
            <Grid item xs={2} md={2} lg={3} />
            <Grid item xs={4} md={4} lg={3}
              className={classes.secondLine}
              container
              justifyContent="flex-start"
            >
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.n1 ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.n1 ? "secondary" : "v4default"}
                onClick={handleFilters('n1')}
              >
                N-1
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsGeneralFilterButtons.n2 ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.n2 ? "secondary" : "v4default"}
                onClick={handleFilters('n2')}
              >
                N-2
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsGeneralFilterButtons.n3 ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.n3 ? "secondary" : "v4default"}
                onClick={handleFilters('n3')}
              >
                N-3
              </Button>
            </Grid>
            <Grid item xs={4} md={3} lg={3}
              className={classes.secondLine}
              container
              justifyContent="flex-end"
            >
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.pareto ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.pareto ? "secondary" : "v4default"}
                onClick={handleFilters('pareto')}
              >
                Pareto
              </Button>
              <Button
                disabled={statisticsLoadingCount > 0 || statisticsLoadingParetoCount > 0}
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsGeneralFilterButtons.paretoOfPareto ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.paretoOfPareto ? "secondary" : "v4default"}
                onClick={handleFilters('paretoOfPareto')}
              >
                Pareto de Pareto
              </Button>
            </Grid>
            <Grid item xs={2} md={3} lg={3} />          
            <Grid item xs={2} md={2} lg={3} />
            <Grid item xs={8} md={7} lg={6}
              className={classes.secondLine}
              container
              justifyContent="flex-end"
            >
              <Button
                disabled
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsGeneralFilterButtons.Normal ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.Normal ? "secondary" : "v4default"}
                onClick={handleFilters('Normal')}
              >
                Normal
              </Button>
              <Button
                disabled
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsGeneralFilterButtons.Premium ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.Premium ? "secondary" : "v4default"}
                onClick={handleFilters('Premium')}
              >
                Premium
              </Button>
              <Button
                disabled
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsGeneralFilterButtons.Gold ? "contained" : "outlined"}
                color={statisticsGeneralFilterButtons.Gold ? "secondary" : "v4default"}
                onClick={handleFilters('Gold')}
              >
                Gold
              </Button>
            </Grid>        
            <Grid item xs={2} md={3} lg={3} />
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    statisticsGeneralFilterButtons: state.statisticsGeneralFilterButtons,
    statisticsGenCallApi: state.statisticsGenCallApi,
    statisticsLoading: state.statisticsLoading,
    statisticsLoadingCount: state.statisticsLoadingCount,
    statisticsLoadingPareto: state.statisticsLoadingPareto,
    statisticsLoadingParetoCount: state.statisticsLoadingParetoCount,
    statisticsGetPareto: state.statisticsGetPareto,
    drawerOpen: state.drawerOpen,
  };
};

export default connect(mapStateToProps, {
  setSGeneralFilterButtons,
  setStatisticsGenCallApi,
  setGetStatistics,
  setGetStatisticsN1,
  setGetStatisticsN2,
  setGetStatisticsN3,
  setStatisticsLoading,
  setStatisticsLoadingPareto,
  setGetStatisticsPareto,
})(ContactRequestHeader);
