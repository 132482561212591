import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TaskDisplay from './TaskDisplay';
import { URL_All } from '../common/Constants';
import { setRefresh, setOpenAlertTasks } from '../actions';
import SnoozeAll from './SnoozeAll';
import { logOut } from '../common/Frame';



const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, showSnooze, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <div className={classes.wrapper}>
          <Typography variant="h6">{children}</Typography>
          {showSnooze ? <SnoozeAll /> : null}
        </div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

function AlertTaskPopUp(props) {
  const { alertTasks,
    users,
    alertTasksOpen,
    setOpenAlertTasks,
    refresh,
    setRefresh,
  } = props;

  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  const handleClose = () => {
    setOpenAlertTasks(false);
  };

  function editCommentTask(id, taskStatus, newCommentTask, personName, dueTo, snooze, is_new) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ due_date: dueTo, comment: newCommentTask, to_users: personName, snooze: snooze, task_status: taskStatus, is_new: is_new, updated_by: currentUser })
    })
    .then(() => {
      setRefresh(refresh + 1);
    })
    .catch(error => console.error(error))
  };
  
  function deleteComment(id) {
    fetch(`${URL_All.CommentTask}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({is_actif: 0})
    })
    .then(() => {
      setRefresh(refresh + 1);
    })
    .catch(error => console.error(error))
  };

  const displayTask = (<React.Fragment>
    {alertTasks.map(item => (<TaskDisplay
      id={item.id}
      comment={item.comment}
      files={item.files}
      toUsers={item.to_users !== null ? item.to_users : ''}
      isTask={item.is_task}
      taskStatus={item.task_status}
      createdBy={item.created_by}
      updatedBy={item.updated_by}
      createdDate={item.created_at}
      updatedDate={item.updated_at}
      dueDate={item.due_date}
      editCommentTask={editCommentTask}
      deleteComment={deleteComment}
      item={item}
      key={item.id}
    />))}
  </React.Fragment>);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={alertTasks.length ? alertTasksOpen : false}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          showSnooze={alertTasks.length > 1}
        >
          Reminder
        </DialogTitle>
        <DialogContent dividers>
          {displayTask}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    alertTasksOpen: state.alertTasksOpen,
    alertTasks: state.alertTasks,
    users: state.users,
    refresh: state.refresh
  };
};

export default connect(mapStateToProps, {
  setRefresh,
  setOpenAlertTasks,
})(AlertTaskPopUp);
