import './css/index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import reducers from './reducers';


const store = createStore(reducers, applyMiddleware(thunk));

function AppWithCallbackAfterRender() {
  return (<Provider store={store}>
    <App />
  </Provider>);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
