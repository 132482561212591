import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';


const useStyles1 = makeStyles()((theme) => { return {
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}});

function Row(props) {
  const { item } = props;

  return (<React.Fragment>
    <TableRow>
      <TableCell style={{ width: "5%" }}>
        {item.is_company ? <BusinessIcon /> : <AccountCircleIcon />}
      </TableCell>
      <TableCell style={{ width: "8%" }}>
        {item.id}
      </TableCell>
      <TableCell style={{ width: "47%" }}>
        {item.is_company ? item.company_name : item.users[0].first_name}
      </TableCell>
      <TableCell style={{ width: "37%" }}>
        {item.users[0].user.username}
      </TableCell>
    </TableRow>
  </React.Fragment>);
}

function TablePaginationActions(props) {
  const { classes } = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles()((theme) => { return {
  root: {
    // minWidth: 500,
    background: '#F7F7F7',
    marginTop: theme.spacing(1),
  },
}});

export default function ContactsBody(props) {
  const { classes } = useStyles2();
  const { contacts, isLoading, searchInput } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, contacts.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const searchSomething = (<TableRow>
  //   <TableCell>
  //     Write something to "Rechercher" field, and click "Enter"
  //   </TableCell>
  // </TableRow>);
  
  // const searchNotFound = (<TableRow>
  //   <TableCell>
  //     No contact by: "{searchInput}"
  //   </TableCell>
  // </TableRow>);

  const loading = (<TableRow>
    <TableCell>
      loading...
    </TableCell>
  </TableRow>);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table aria-label="custom pagination table">
          <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name / Company Name</TableCell>
                <TableCell>Username</TableCell>
              </TableRow>
          </TableHead>
        <TableBody>
          {isLoading ? loading : null}
          {contacts.filter(m => (Object.values(m).includes(searchInput))).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x => (
            <Row item={x} key={x.id} />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 0 * emptyRows }} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 30, 60]}
              colSpan={3}
              count={contacts.filter(m => (Object.values(m).includes(searchInput))).length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
