import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { newadmindb } from '../common/Constants';
import Tooltip from '@mui/material/Tooltip';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
    root: {
      margin: 'auto',
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    formGroup: {
        margin: 'auto',
        padding: theme.spacing(2),
        width: 'fit-content',
    },
    binIconBtn: {
      padding: theme.spacing(0),
    },
    binBtn: {
      color: 'gray',
      padding: theme.spacing(0.25),
      paddingBottom: theme.spacing(0),
      '&:hover': {
          color: 'red',
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    dialogActions: {
      placeContent: 'center',
      marginBottom: theme.spacing(2),
    },
    dialogContent: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      '& p': {
        margin: 0,
        color: '#000',
        fontSize: '1.3em',
      },
      fontStyle: 'italic',
    },
    percentage: {
      fontStyle: 'normal',
      fontWeight: '700',
    },
    displayNone: {
      display: 'none',
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    overflowWrapAny: {
      overflowWrap: 'anywhere',
    },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
}));

export default function CommentPopUpDelete(props) {
  const { classes } = useStyles();
  const { id,
    comment,
    deleteComment,
    byCustomerID,
    setByCustomerID,
    files,
    isTask
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    setByCustomerID(byCustomerID);
  };

  function handleClose() {
    setOpen(false);
  };
  
  const cleancombinedText = DOMPurify.sanitize(comment, { USE_PROFILES: { html: true } });

  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (
    <div>
      <Tooltip title="Delete" onClick={handleClickOpen} placement="top">
        <IconButton size="large">
          <DeleteOutlineIcon className="hoverShow" fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
          <div className={classes.root}>
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={() => handleClose()}>
              Delete this <u>{isTask === 1 ? 'task' : 'comment'}</u>, {username}?
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.dialogContent}>
              <DialogContentText>
                <div
                  className={classes.task}
                  dangerouslySetInnerHTML={{ __html: cleancombinedText}}
                />
                <span className={!files ? classes.displayNone : classes.overflowWrapAny}><br />
                  {files !== null && files.slice(0, -1).split(';').map((file, i) => (<a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank" key={i}>{file}</a>))}
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  deleteComment(id);
                  handleClose();
                }}
              >
                  Valider
              </Button>
            </DialogActions>
          </div>
      </Dialog>
    </div>
  );
}
