import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Editor,
  EditorState,
getDefaultKeyBinding,
RichUtils,
convertToRaw,
ContentState,
convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { newadmindb } from '../common/Constants';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { convertHoursAndMinutes, convertDateToWithDots } from '../common/functions';


const useStyles = makeStyles()((theme) => { return {
    display: {
        fontSize: "0.9rem",
        margin: 0,
        marginBottom: theme.spacing(3),
        padding: 0,

        '& > *': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1),
        },
        
        '& .body': {
            display: 'block',
            padding: theme.spacing(1),
        },

        '& .dividerTop': {
            padding: 0,
            backgroundColor: '#ccc',
            marginBottom: theme.spacing(2),
        },
        
        '& .dividerBottom': {
            padding: 0,
            backgroundColor: '#ccc',
            marginTop: theme.spacing(2),
        },

        '& .greenLight': {
            backgroundColor: "#c8e6c9",
        },

        '& .blueLight': {
            backgroundColor: "#cae3f0",
        },

        '& .orangeLight': {
            backgroundColor: "#ffe0b2",

            '& .RichEditor-root': {
                backgroundColor: '#ffe0b2',
                borderBottom: '1px solid #000',
                marginBottom: theme.spacing(.5),
            },
        },

        '&:first-of-type': {
            backgroundColor: '#f4f4f4',
        },

        '&:last-child': {
            backgroundColor: '#f4f4f4',
        },

        '& .notificationsActiveIcon': {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            fontSize: '1.2rem',
            
            '& .MuiSvgIcon-root': {
              marginTop: theme.spacing(.25),
              color: theme.palette.grey[800],
            },
        },

        '& .actionButtons': {
            display: 'flex',
            justifyContent: 'space-between',

            '& > *': {
                display: 'inline-flex',
                gap: theme.spacing(1.5),
            },
        },

        '& .top-left': {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),

            '& p': {
                fontSize: '1.1rem',
            },
        },        

        '& .created-modified-who': {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        }, 
        
        '& .files': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(1.5),
        },      
    },
    editing: {
        backgroundColor: '#f4f4f4',

        '& .editCommentTask': {
            margin: '16px 12px',
            paddingTop: theme.spacing(2),
        },
    },
    greyLight: {
        backgroundColor: "#f5f5f5",
        fontSize: "0.9rem",
        margin: 0,
        padding: theme.spacing(1),
    },
    toRightSide: {
        float: "right",
    },
    linkFile: {
        paddingTop: theme.spacing(0.75),
        float: "right",
    },
    taskStatus0: {
        textTransform: "none",
        background: "#ff9a9a",
    },
    taskStatus1: {
        textTransform: "none",
        background: "#ffff59",
    },
    taskStatus2: {
        textTransform: "none",
        background: "#94eb94",
    },
    noneTextTransform: {
        textTransform: "none",
    },
    toWho: {
        width: "80%",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 210,
    },
    formControl: {
        padding: theme.spacing(2),
    },
    editTaskLabel: {
        
    },
    spacingBetween: {
        marginBottom: theme.spacing(1),
    },
    inputButton: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    actionButtons: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: 'flex',
    },
    editCancelSave: {
        display: 'inherit',
    },
    paragraphNewLine: {
        whiteSpace: 'pre-wrap',
        paddingTop: theme.spacing(1),
    },
    pinIcon: {
        fontSize: '1.2em',
    },
    displayNone: {
        display: 'none',
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    showMoreLessButton: {
        textDecoration: 'underline',
        color: 'red',
    },
    inlineFlex: {
        display: 'inline-flex',
    },
    mr16: {
        marginRight: theme.spacing(2),
    },
    nSuivi: {
        fontSize: '1.2rem'
    },
    biu: {
        marginLeft: theme.spacing(3),

      '& .RichEditor-controls': {
        '&>*': {
          fontSize: '1.4rem',
          width: 50,
        },
        
        '& .MuiButtonBase-root:not(last-child)': {
          marginRight: theme.spacing(1),
        },
      },
    },
    task: {
        display: 'inline-block',
        minHeight: 80,
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        padding: '0 8px',
        fontSize: '1.3rem',

        '& p': {
            minHeight: '1em',
            margin: 0,
        },
    },
    backgroundColorInherit: {        
        '&.orangeLight': {
            '& .RichEditor-root': {
                backgroundColor: "#ffe0b2",
                padding: theme.spacing(2),
                paddingBottom: theme.spacing(4),
            },
        },

        '&.greenLight': {
            '& .RichEditor-root': {
                backgroundColor: "#c8e6c9",
                padding: theme.spacing(2),
                paddingBottom: theme.spacing(4),
            },
        },

        '&.blueLight': {
            '& .RichEditor-root': {
                backgroundColor: "#cae3f0",
                padding: theme.spacing(2),
                paddingBottom: theme.spacing(4),
            },      
        },      
    },
    textUnderlined: {
      textDecoration: 'underline',
    },
}});

function RichTextEditor(props) {
    const { editorState,
        onChange,
        editor
    } = props;
  
    useEffect(() => {
      editor.current.focus();
    }, []);
  
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root">
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a task..."
            ref={editor}
          />
        </div>
      </div>
    );
}
  
// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
};
  
function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}
  
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {  
      return (
          <IconButton
              className={this.props.style}
              color={this.props.active ? 'secondary' : 'default'}
              onMouseDown={this.onToggle}
              size="large">
            {this.props.label}
          </IconButton>
      );
    }
}
  
  
var INLINE_STYLES = [
    {label: 'G', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'S', style: 'UNDERLINE'},
];
  
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TaskDisplay(props) {
    const focusEditInput = useRef(null);
    const { classes } = useStyles();
    const [isEditing, setEditing] = useState(false);
    const { id,
        comment,
        files,
        toUsers,
        taskStatus,
        createdBy,
        updatedBy,
        createdDate,
        updatedDate,
        dueDate,
        personName,
        handlePersonName,
        editCommentTask,
        deleteComment,
        users,
        item
    } = props;
    const [newComment, setNewComment] = useState(comment);
    const [personIDs, setPersonIDs] = useState([]);
    const [dueTo, setDueTo] = useState(dueDate !== null ? dueDate : Math.trunc(new Date().getTime()/1000));
    const [show, setShow] = useState(false);
           
    const blocksFromHTML = convertFromHTML(comment);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );
    const [editorState, setEditorState] = useState(EditorState.createWithContent(state));

    
    const handleEditorState = (editorState) => {
        setEditorState(editorState);

        var contentState = editorState.getCurrentContent();
        setNewComment(draftToHtml(convertToRaw(contentState)));
    };

    const toggleInlineStyle = (inlineStyle) => {
        handleEditorState(
        RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
        )
        );
    }

    const handleChange2 = (event) => {
        handlePersonName(event.target.value);
        setPersonIDs(users.filter(r => event.target.value.includes(r.username)).map(item => item.id));
    };
    
    function handleChange3(e) {
        setDueTo(Math.trunc(new Date(e.target.value).getTime()/1000));
    }

    const cleancombinedText = DOMPurify.sanitize(comment, { USE_PROFILES: { html: true } });
    
    const countFiles = files !== null ? files.slice(0, -1).split(';').length : 0;

    const showMoreFiles = (files !== null && files.slice(0, -1).split(';').slice(5).map((file, i) => (<span key={i} className={classes.inlineFlex}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file}</a></Tooltip> </span>)));

    const fiveFiles = (files !== null && files.slice(0, -1).split(';').slice(0, 5).map((file, i) => (<span key={i} className={classes.inlineFlex}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file.length > 9 ? `${file.slice(0, 8)}...` : file}</a></Tooltip> </span>)));

    const ifTaskEditing = (<Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            className={classes.formControl}
        >
            <Grid item xs={1}>
                <p className={classes.editTaskLabel}><b>(Tâche):</b></p>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
                <FormControl variant="standard" className={classes.toWho}>
                    <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                    <Select
                        variant="standard"
                        labelId="users-mutiple-checkbox-label"
                        id="users-mutiple-checkbox2"
                        multiple
                        value={personName}
                        onChange={handleChange2}
                        input={<Input />}
                        renderValue={(selected) => selected.toString() !== '' ? selected.join(', ') : ''}
                        MenuProps={MenuProps}>
                        {users.sort(function(a, b) {
                            let x = a.username.toLowerCase();
                            let y = b.username.toLowerCase();
                            if(x < y) { return -1; }
                            if(x > y) { return 1; }
                            return 0;
                        }).map((item) => (
                            <MenuItem key={item.id} value={item.username}>
                                <Checkbox checked={personName.indexOf(item.username) > -1} />
                                <ListItemText primary={item.username} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5}>
                <form className={classes.dateTimeContainer} noValidate>
                    <TextField
                        variant="standard"
                        id="datetime-task"
                        label="Due to:"
                        type="datetime-local"
                        className={classes.dateTime}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        defaultValue={((new Date(dueTo*1000)).getFullYear().toString()+'-'+(new Date(dueTo*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo*1000)).getDate().toString().padStart(2, '0')+'T'+(new Date(dueTo*1000)).getHours().toString().padStart(2, '0')+':'+(new Date(dueTo*1000)).getMinutes().toString().padStart(2, '0'))}
                        onChange={handleChange3} />
                </form>
            </Grid><br /><br />
        </Grid>
    );
    
    const editing = (
        <div className={classes.editing}>
            <div className={`${classes.backgroundColorInherit} ${'orangeLight'}`}>
                {ifTaskEditing}
                <RichTextEditor
                    editorState={editorState}
                    onChange={handleEditorState}
                    editor={focusEditInput}
                />
            </div>
            <span className={classes.actionButtons}>
                <span className={classes.editCancelSave}>
                    <Button
                        className={classes.noneTextTransform}
                        size="small"
                        onClick={() => setEditing(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.noneTextTransform}
                        size="small"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            editCommentTask(id, taskStatus, newComment, personIDs.toString() !== '' ? personIDs.toString() : toUsers, dueTo);
                            setNewComment(newComment);
                            setEditing(false);
                        }}
                    >
                        Save
                    </Button>
                </span>
                <span className={classes.biu}>
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </span>
            </span>
        </div>
    );
    
    const ifUpdate = <p>Dernière modification à: <span className={classes.textUnderlined}>{users.filter(filt => (filt.id === updatedBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === updatedBy))[0].username}</span>&nbsp;
    ({convertDateToWithDots(updatedDate*1000)} {convertHoursAndMinutes(updatedDate*1000)})</p>;
    
    const display = (
        <div className={classes.display}>
            <div className="top-left">
                <div>
                    <p>
                        Attribué à:&nbsp;
                        <span className={classes.textUnderlined}>
                            {personName.join(', ')}
                        </span>
                    </p>
                </div>                
                <p>{item.tracking_nbr.length > 0 ? <span className={classes.nSuivi}>N° suivi: <strong>{item.tracking_nbr}</strong></span> : <span className={classes.nSuivi}>N° suivi: <strong><em>(aucun)</em></strong></span>}</p>
            </div>
            <div className="dividerTop">
                <Divider />
            </div>
            <div className="body orangeLight">
                <div className="notificationsActiveIcon">
                    <NotificationsActiveIcon />&nbsp;{convertDateToWithDots(dueDate*1000)}&nbsp;
                    ({convertHoursAndMinutes(dueDate*1000)})
                </div>
                
                <div
                    className={classes.task}
                    dangerouslySetInnerHTML={{ __html: cleancombinedText}}
                />
                <div className="files">
                    <div className={files !== null ? classes.linkFile : null}>
                        {fiveFiles}
                        <Link
                            className={countFiles > 5 ? classes.showMoreLessButton : classes.displayNone}
                            component="button"
                            variant="body2"
                            onClick={() => {
                                setShow(!show);
                            }}
                            underline="hover">
                            {show ? 'Show Less' : 'Show More'}
                        </Link>
                    </div>
                    {show ? 
                        <div className="showMoreFiles">
                            {showMoreFiles}
                        </div>
                    :null}
                </div>
                
                <div className="actionButtons">
                    <div>
                        <Button
                            className={(taskStatus === 1 | taskStatus === 2) ? classes.taskStatus0 : classes.noneTextTransform}
                            size="small"
                            onClick={() => editCommentTask(id, 1, comment)}
                        >
                            à faire
                        </Button>
                        <Button
                            className={taskStatus === 3 ? classes.taskStatus2 : classes.noneTextTransform}
                            size="small"
                            onClick={() => editCommentTask(id, 3, comment)}
                        >
                            terminé
                        </Button>
                    </div>
                    <div>
                        <Button
                            className={classes.noneTextTransform}
                            size="small"
                            onClick={() => setEditing(true)}
                        >
                            Edit
                        </Button>
                        <Button
                            className={classes.noneTextTransform}
                            size="small"
                            onClick={() => deleteComment(id)}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
            <div className="dividerBottom">
                <Divider />
            </div>
            <div className="created-modified-who">
                <div>
                    {createdDate !== updatedDate ? ifUpdate : ''}
                </div>
                <div>
                    <p>
                        Ajouté par:&nbsp;
                        <span className={classes.textUnderlined}>
                            {users.filter(filt => (filt.id === createdBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === createdBy))[0].username}
                        </span>&nbsp;
                        ({convertDateToWithDots(updatedDate*1000)} {convertHoursAndMinutes(createdDate*1000)})
                    </p>
                </div>
            </div>
        </div>
    );

    return <div className="newsFeed">
        {isEditing ? editing
        : display}
        <Divider light className={classes.spacingBetween} />
    </div>;
}

const mapStateToProps = state => {
    return { users: state.users };
};

export default connect(mapStateToProps)(TaskDisplay);
