import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import { ShipmentsContext } from '../contexts/ShipmentsContext';
import { getDateToday, convertDateToMS, numberThousands } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
    secondLine: {
        marginBottom: theme.spacing(.5),
    },
    buttonLowerCase: {
      textTransform: "none",
    },
    search: {
      width: '90%',
      "& .MuiButtonBase-root": {
          padding: 0,
          color: "gray",
      },
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    gridDatePicker: {
        display: 'flex',
        placeContent: 'center',
        marginLeft: theme.spacing(4),
    },
    formDatePicker: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textFieldDatePicker: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    caProfitTurnover: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        // justifyContent: 'space-around',
        alignItems: 'flex-end',
        '& span': {
            marginRight: theme.spacing(1),
        },
    },
    marginLeft16: {
        marginLeft: theme.spacing(2),
    },
    filterByDates: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
    },
}});

function ShipmentsHead({ shipmentProfit, deleteShipment }) {
    const { classes } = useStyles();
    const { searchShipment,
        dateFrom,
        dateTo,
        noOffer,
        errorPickup,
        okBtn,
        fetchDone,
        shipmentsCountHeader,
        setNoOffer,
        selectedOffer,
        setErrorPickup,
        setOkBtn,
        setSelectedOffer,
        setShipments,
        setShipmentsCountHeader,
        setShipmentProfit,
        handleCountShipments,
        setDateNotSelected,
        setFromDateBigger,
        setDateFrom,
        setDateTo,
        setSearchShipment,
        setShipmentPage
    } = useContext(ShipmentsContext);
    const [filterDate, setFilterDate] = useState(null);
    const [preDateFrom, setPreDateFrom] = useState(dateFrom);
    const [preDateTo, setPreDateTo] = useState(dateTo);

    useEffect(() => {
        if(dateFrom === getDateToday() && dateTo === getDateToday()) {
            setFilterDate('today');
        }
    }, [dateFrom, dateTo]);

    function forDateInterval(fromD, toD) {
        if(fromD === getDateToday() && toD === getDateToday()) {
            setFilterDate('today');
        } else if(fromD === toD && (new Date(getDateToday()).getTime() - new Date(fromD).getTime()) === (24 * 60 * 60 * 1000)) {
            setFilterDate('yesterday');
        } else if(fromD === toD && (new Date(getDateToday()).getTime() - new Date(fromD).getTime()) === (2 * 24 * 60 * 60 * 1000)) {
            setFilterDate('theDayBeforeYesterday');
        } else if(fromD.length < 1 && toD.length < 1) {
            setFilterDate(null);
            setDateNotSelected(true);
        } else {
            setFilterDate(null);
        }
    }

    const dateFilterRadio = (value, days) => {
        const currentDate = new Date() - days * 24 * 60 * 60 * 1000;
        const yyyy = (new Date(currentDate)).getFullYear();
        const mm = ((new Date(currentDate)).getMonth() + 1).toString().padStart(2, '0');
        const dd = (new Date(currentDate)).getDate().toString().padStart(2, '0');
        const dateOnly = `${yyyy}-${mm}-${dd}`;

        setFilterDate(value);
        setDateFrom(dateOnly);
        setDateTo(dateOnly);

        setPreDateFrom(dateOnly);
        setPreDateTo(dateOnly);
    }

    function cleanShipmentList() {
        setShipmentPage(0);
        setShipments([]);
        setShipmentsCountHeader(0);
        setShipmentProfit({});
        handleCountShipments(0);
        setDateFrom('');
        setDateTo('');
        setPreDateFrom('');
        setPreDateTo('');
        setFilterDate(null);
        setDateNotSelected(true);
    }
    

    return (
        <div>
            <Grid container space={2}>
                <Grid item xs={12} md={12} lg={12} container>
                    <Grid item xs={5}>
                        <Button
                            disabled={!fetchDone}
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={noOffer ? "contained" : "outlined"}
                            color={noOffer ? "secondary" : "v4default"}
                            onClick={() => {
                                if (noOffer) {
                                    setNoOffer(false);
                                } else {
                                    setSelectedOffer(false);
                                    setErrorPickup(false);
                                    setOkBtn(false);
                                    setNoOffer(true);
                                }
                            }}
                        >
                            Pas d’offre
                        </Button>
                        <Button
                            disabled={!fetchDone}
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant={selectedOffer ? "contained" : "outlined"}
                            color={selectedOffer ? "secondary" : "v4default"}
                            onClick={() => {
                                if (selectedOffer) {
                                    setSelectedOffer(false);
                                } else {
                                    setNoOffer(false);
                                    setErrorPickup(false);
                                    setOkBtn(false);
                                    setSelectedOffer(true);
                                }
                            }}
                        >
                            Offre sélectionnée
                        </Button>
                        <Button
                            disabled={!fetchDone}
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant={errorPickup ? "contained" : "outlined"}
                            color={errorPickup ? "secondary" : "v4default"}
                            onClick={() => {
                                if (errorPickup) {
                                    setErrorPickup(false);
                                } else {
                                    setNoOffer(false);
                                    setSelectedOffer(false);
                                    setErrorPickup(true);
                                    setOkBtn(false);
                                }
                            }}
                        >
                            Erreur pickup
                        </Button>
                        <Button
                            disabled={!fetchDone}
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant={okBtn ? "contained" : "outlined"}
                            color={okBtn ? "secondary" : "v4default"}
                            onClick={() => {
                                if (okBtn) {
                                    setOkBtn(false);
                                } else {
                                    setNoOffer(false);
                                    setSelectedOffer(false);
                                    setErrorPickup(false);
                                    setOkBtn(true);
                                }
                            }}
                        >
                            Ok
                        </Button>
                    </Grid>
                    <Grid item xs={5} />
                    <Grid item xs={2}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Rechercher…" 
                                variant="outlined" 
                                className={classes.search}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        {searchShipment.length > 0 ? (<IconButton
                                            onClick={() => {
                                                setSearchShipment('');
                                            }}
                                            size="large">
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                        </InputAdornment>
                                    ),
                                    }}
                                value={searchShipment}
                                onChange={e => {
                                    setSearchShipment(e.target.value);
                                }}
                            />
                        </form>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}
                    className={classes.secondLine}
                    container
                    justifyContent="space-between"
                >
                    {/* <Grid item xs={8} className={classes.filterByDates}> */}
                    <div className={classes.filterByDates}>
                        <Button
                            disabled={!fetchDone}
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={filterDate === "today" ? "contained" : "outlined"}
                            color={filterDate === "today" ? "secondary" : "v4default"}
                            onClick={() => {
                                if(filterDate !== "today") {
                                    dateFilterRadio("today", 0);
                                } else {
                                    cleanShipmentList();
                                }
                            }}
                        >
                            Aujourd’hui
                        </Button>
                        <Button
                            disabled={!fetchDone}
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant={filterDate === "yesterday" ? "contained" : "outlined"}
                            color={filterDate === "yesterday" ? "secondary" : "v4default"}
                            onClick={() => {
                                if(filterDate !== "yesterday") {
                                    dateFilterRadio("yesterday", 1);
                                } else {
                                    cleanShipmentList();
                                }
                            }}
                        >
                            Hier
                        </Button>
                        <Button
                            disabled={!fetchDone}
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant={filterDate === "theDayBeforeYesterday" ? "contained" : "outlined"}
                            color={filterDate === "theDayBeforeYesterday" ? "secondary" : "v4default"}
                            onClick={() => {
                                if(filterDate !== "theDayBeforeYesterday") {
                                    dateFilterRadio("theDayBeforeYesterday", 2);
                                } else {
                                    cleanShipmentList();
                                }
                            }}
                        >
                            Avant-hier
                        </Button>
                        <div className={classes.gridDatePicker}>
                            <form className={classes.formDatePicker} noValidate>
                                <TextField
                                    variant="standard"
                                    id="dateFrom"
                                    className={classes.textFieldDatePicker}
                                    label="Entre"
                                    type="date"
                                    value={preDateFrom}
                                    inputProps={{ max: preDateTo }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setPreDateFrom(e.target.value)} />
                            </form>
                            <form className={classes.formDatePicker} noValidate>
                                <TextField
                                    variant="standard"
                                    id="dateTo"
                                    className={classes.textFieldDatePicker}
                                    label="et"
                                    type="date"
                                    value={preDateTo}
                                    inputProps={{ min: preDateFrom }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setPreDateTo(e.target.value)} />
                            </form>
                        </div>
                        <Button
                            className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                            size="small"
                            variant="outlined"
                            color="primary"
                            disabled={!preDateFrom || !preDateTo || !fetchDone}
                            onClick={() => {
                                setDateFrom(preDateFrom);
                                setDateTo(preDateTo);
                                forDateInterval(preDateFrom, preDateTo);
                                if(convertDateToMS(preDateTo) < convertDateToMS(preDateFrom)) {
                                    setShipmentPage(0);
                                    setShipments([]);
                                    setShipmentsCountHeader(0);
                                    setShipmentProfit({});
                                    handleCountShipments(0);
                                    setFromDateBigger(true);
                                }
                            }}
                        >
                            Valider
                        </Button>
                    </div>
                    {/* <Grid item xs={4} className={classes.caProfitTurnover}> */}
                    <div className={classes.caProfitTurnover}>
                        <span>Count Test: <b><i>{shipmentsCountHeader - deleteShipment.length}</i></b></span>&nbsp;
                        <span>|</span>&nbsp;
                        <span>CA: <b><i>{numberThousands(shipmentProfit.total_turnover)}</i></b> CHF</span>&nbsp;
                        <span>Profit: <b><i>{numberThousands(Math.trunc((shipmentProfit.total_profit ? shipmentProfit.total_profit : 0) * 100) / 100)}</i></b> CHF</span>&nbsp;
                        <span>Profit/Turnover: <b><i>{shipmentProfit.percent_profit}</i></b> %</span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return { deleteShipment: state.deleteShipment };
};

export default connect(mapStateToProps)(ShipmentsHead);
