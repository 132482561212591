import React from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SortIcon from '@mui/icons-material/Sort';
import SvgIcon from '@mui/material/SvgIcon';


const useStyles = makeStyles()((theme) => { return {
  sortBtn: {
    width: 212,
    textTransform: "none",
    float: "right",
    padding: theme.spacing(0.65),
    // flexBasis: '100%',
  },
  sortIcon: {
    paddingRight: theme.spacing(1),
  },
}});

const StyledMenu = withStyles((props) => (
    <Menu
      elevation={0}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ), {
  paper: {
    border: '1px solid #d3d4d5',
  },
});

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '&:focus': {
    //   backgroundColor: theme.palette.action.disabledBackground,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000', // color: theme.palette.common.white,
      },
    },
    minWidth: 200,
  },
}));

function DlvDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function DlvAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function DueToDescIcon(props) {
    return (
      <SvgIcon {...props}>
         <path fill="currentColor" d="M21 17H24L20 21L16 17H19V3H21V17M8 16H11V13H8V16M13 5H12V3H10V5H6V3H4V5H3C1.89 5 1 5.89 1 7V18C1 19.11 1.89 20 3 20H13C14.11 20 15 19.11 15 18V7C15 5.89 14.11 5 13 5M3 18L3 11H13L13 18L3 18Z" />
      </SvgIcon>
    );
}

function DueToAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M19 7H16L20 3L24 7H21V21H19V7M8 16H11V13H8V16M13 5H12V3H10V5H6V3H4V5H3C1.89 5 1 5.89 1 7V18C1 19.11 1.89 20 3 20H13C14.11 20 15 19.11 15 18V7C15 5.89 14.11 5 13 5M3 18L3 11H13L13 18L3 18Z" />
      </SvgIcon>
    );
}

function TrNbrDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M19 17H22L18 21L14 17H17V3H19V17M9 13H7C5.9 13 5 13.9 5 15V16C5 17.11 5.9 18 7 18H9V19H5V21H9C10.11 21 11 20.11 11 19V15C11 13.9 10.11 13 9 13M9 16H7V15H9V16M9 3H7C5.9 3 5 3.9 5 5V9C5 10.11 5.9 11 7 11H9C10.11 11 11 10.11 11 9V5C11 3.9 10.11 3 9 3M9 9H7V5H9V9Z" />
      </SvgIcon>
    );
}

function TrNbrAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M19 7H22L18 3L14 7H17V21H19M9 21H5V19H9V18H7C5.9 18 5 17.11 5 16V15C5 13.9 5.9 13 7 13H9C10.11 13 11 13.9 11 15V19C11 20.11 10.11 21 9 21M9 15H7V16H9M7 3H9C10.11 3 11 3.9 11 5V9C11 10.11 10.11 11 9 11H7C5.9 11 5 10.11 5 9V5C5 3.9 5.9 3 7 3M7 9H9V5H7Z" />
      </SvgIcon>
    );
}

function AplhabetAscIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3M9 7H7V5H9Z" />
    </SvgIcon>
  );
}

export default function TaskManagementSort(props) {
  const { setSortByDate, titleName, setTitleName, setEmployee } = props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.sortBtn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}>
        <SortIcon
          className={classes.sortIcon}
          fontSize="medium"
        />
          {titleName}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Date Desc');
                setSortByDate('DateNew');
                setEmployee([]);
            }}
            selected={titleName === 'Date Desc' ? true : false}
        >
          <ListItemIcon>
            <DlvAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Date Asc');
                setSortByDate('DateOld');
                setEmployee([]);
            }}
            selected={titleName === 'Date Asc' ? true : false}
        >
          <ListItemIcon>
            <DlvDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Date Asc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Username');
                setSortByDate('ByUsername');
            }}
            selected={titleName === 'Username' ? true : false}
        >
          <ListItemIcon>
            <AplhabetAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Username" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Tracking No Desc');
                setSortByDate('TrNbrNew');
                setEmployee([]);
            }}
            selected={titleName === 'Tracking No Desc' ? true : false}
        >
          <ListItemIcon>
            <TrNbrDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Tracking No Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Tracking No Asc');
                setSortByDate('TrNbrOld');
                setEmployee([]);
            }}
            selected={titleName === 'Tracking No Asc' ? true : false}
        >
          <ListItemIcon>
            <TrNbrAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Tracking No Asc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Due date Desc');
                setSortByDate('DueDateNew');
                setEmployee([]);
            }}
            selected={titleName === 'Due date Desc' ? true : false}
        >
          <ListItemIcon>
            <DueToDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Due date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Due Date Asc');
                setSortByDate('DueDateOld');
                setEmployee([]);
            }}
            selected={titleName === 'Due Date Asc' ? true : false}
        >
          <ListItemIcon>
            <DueToAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Due Date Asc" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
