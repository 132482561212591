import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import { setChatSearch } from '../actions';


const useStyles = makeStyles()((theme) => { return {
  search: {
    width: '90%',
    "& .MuiButtonBase-root": {
        padding: 0,
        color: "gray",
    },
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headText: {
    '& .MuiTypography-root': {
        color: 'gray',
        fontSize: theme.spacing(6),
    },
  },
  searchGrid: {
  },
  gridHighlighted: {
    transform: 'translateY(40%)',
    textAlign: 'center',
  },
}});

function ChatHeader(props) {
    const { classes } = useStyles();
    let { chatSearch,
        setChatSearch,
        setPageZero,
    } = props;

    return (
        <div>
            <Grid 
                container
                spacing={1}
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
            >
                <Grid item xs={8} className={classes.headText}>
                    <Typography>
                        Messaging (Chat)
                    </Typography>
                </Grid>

                <Grid item xs={2} className={classes.gridHighlighted}>
                </Grid>

                <Grid item xs={2} className={classes.searchGrid}>
                    <Grid 
                        container 
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <form noValidate autoComplete="off">
                                <TextField 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Rechercher…" 
                                    variant="outlined" 
                                    className={classes.search}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                          ev.preventDefault();
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {chatSearch.length > 0 ? (<IconButton
                                                onClick={() => {
                                                    setChatSearch('');
                                                }}
                                                size="large">
                                                <HighlightOffIcon size="small" />
                                            </IconButton>) : ''}
                                          </InputAdornment>
                                        ),
                                      }}
                                    value={chatSearch}
                                    onChange={e => {
                                        setChatSearch(e.target.value ? e.target.value : '');
                                        setPageZero(oldKey => (oldKey + 1));
                                    }}
                                />
                            </form>
                        </Grid>
                    
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
  return { chatSearch: state.chatSearch };
};

export default connect(mapStateToProps, { setChatSearch })(ChatHeader);
