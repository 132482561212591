import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { numberThousands } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
    root: {
        padding: '1em 1.5em 1.5em',
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
    },
    messageContent: {
        display: 'flex',
        gap: '1em',
    },
    fileContent: {
        margin: 0,
        padding: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 49%)',
        justifyContent: 'space-between',
        gridAutoFlow: 'dense',
        maxHeight: 130,
        overflowY: 'scroll',
    },
    dialogActions: {
        display: 'inline-flex',
        flexDirection: 'column',
        gap: '1em',
    },
    submit: {
      minWidth: '16.2em',
    },
    inputButton: {
        width: "100%",
        height: '100%',
        border: "2px dashed #ccc",
        color: '#555',
        paddingLeft: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: theme.spacing(.5),
        marginBottom: theme.spacing(1),
        background: '#f6f6f6',
    },
    highlightOffIcon: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        color: '#808080',
    },
    dialogContent: {
        display: 'inline-flex',
        width: '100%',

        '& .RichEditor-root': {
          height: 140,
          width: '100%',
          border: '1px solid #ccc',
          padding: '8px 15px',
          overflowY: 'scroll',
        },
    },
}});

function TextEditorNew(props) {
    const { editorState, onChange, focusInput } = props;
  
    useEffect(() => {
        focusInput.current.focus();
    }, []);
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root">
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a message..."
            ref={focusInput}
          />
        </div>
      </div>
    );
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}

function MessageForm(props) {
  const { customerAccount,
    byCustomerID,
    setByCustomerID,
    postCommentTask,
    taskComment,
  } = props;
  const { classes } = useStyles();
  const [comment, setComment] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const scrollTo = useRef(null);
  const focusInput = useRef(null);
  
  const cleancombinedText = DOMPurify.sanitize(comment, { USE_PROFILES: { html: true } });

  const noHhtml = cleancombinedText.replace(/(<([^>]+)>)/gi, "");
  const noHtmlTrim = noHhtml.trim();

  useEffect(() => {
    setComment('');
    setSelectedFiles([]);
    // Customer-Account has two kind of add comment
    if(customerAccount) {
        setByCustomerID(byCustomerID);
    }
  }, []);

  const handleClear = () => {
    setComment('');
    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);
  };
    

  const customerChat = taskComment.filter(fl => fl.is_chat === 1);
  const customerChatEmails = customerChat.map(fl => fl.contact_email).filter(fl => fl.length);
  const chatEmail = customerChatEmails.length ? customerChatEmails[0] : false;
  
  const handleSubmit = () => {
    postCommentTask(comment.trim(), selectedFiles, undefined, 0, 0, 2, chatEmail);
    handleClear();
  };

  const scrollToBottom = () => {
      scrollTo.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onChangeFile = (files) => {
      setSelectedFiles(selectedFiles.concat(files));
      scrollToBottom();
  }; 

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop: onChangeFile,
      onChange: onChangeFile
  });

  const deleteItem = (filt) => {
      setSelectedFiles(selectedFiles.filter(item => item !== filt));
  };

  const handleEditorState = (editorState) => {
      setEditorState(editorState);

      var contentState = editorState.getCurrentContent();
      setComment(draftToHtml(convertToRaw(contentState))); 
  };

  const renderSelectedFiles = selectedFiles.length ? (selectedFiles.map((file, index) => {
      return (
          <Paper className={classes.paper} key={index}>
              <span>{file.name} <span style={{ color: 'grey' }}>({numberThousands(Math.trunc(file.size / 1024))}KB)</span></span>
              <span className={classes.highlightOffIcon}>
                  <HighlightOffIcon onClick={() => deleteItem(file)} />
              </span>
          </Paper>
      );
  })) : null;

  return(
    <div className={classes.root}>
      <div className={classes.fileContent}>
          {renderSelectedFiles}
          <div ref={scrollTo} />
      </div>
      <div className={classes.messageContent}>
          <div className={classes.dialogContent}>
              <TextEditorNew
                editorState={editorState}
                onChange={handleEditorState}
                focusInput={focusInput}
              />
          </div>
          <div className={classes.dialogActions}>
              <div className={classes.inputButton} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
            <Button
                className={classes.submit}
                color="primary"
                variant="outlined"
                onClick={handleSubmit}
                disabled={noHtmlTrim < 1}
            >
                Enregistrer
            </Button>
          </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return { taskComment: state.taskComment };
};

export default connect(mapStateToProps)(MessageForm);
