import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


const borderColor = '#010101';

const useStyles = makeStyles()((theme) => { return {
  table: {
    '& th, td': {
      padding: 0,
    },
  },
  tableHead:{
    // position: 'sticky',
    // top: theme.spacing(10),
    // zIndex: 1,

    background: '#d6dce4',

    '& th': {
      textAlign: 'center',
      fontWeight: '700',
      borderTop: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },

    '& th:first-of-type': {
      borderLeft: `1px solid ${borderColor}`,
    },
  },
}});

export default function Draft() {
  const { classes } = useStyles();

  return (
    <React.Fragment>
      <TableHead>
        <TableRow className={classes.tableHead}>
          <TableCell>Mois</TableCell>
          <TableCell>total envois</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Doc.</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Colis</TableCell>
          <TableCell>&Delta;%</TableCell>

          <TableCell>Pallets</TableCell>
          {/* <TableCell>Avg Ship. / BDay</TableCell> */}
          
          <TableCell>Moy. D'envois</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>CA</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>MB</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Nouv. Clients</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Clients totaux</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Ent. actives</TableCell>
          <TableCell>&Delta;%</TableCell>
          <TableCell>Priv. actifs</TableCell>
          <TableCell>Nouv. Ent. actives</TableCell>
          <TableCell>Nouv. Priv. actifs</TableCell>
        </TableRow>
      </TableHead>
    </React.Fragment>
  );
}
