import React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles()((theme) => { return {
    root: {
        width: '100%',
        height: 100,
        background: '#F7F7F7',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(13),
    },
    headRightSide: {
        textAlign: 'right',
    },
    search: {
        width: 200,
        "& .MuiButtonBase-root": {
            padding: 0,
            color: "gray",
        },
    },
    selectPerson: {
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    selectPerson2: {
        width: '100%',
    },
    headPhone: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}});

const data = [
    {
        id: 1,
        name: 'Abouchar SA (Admin)',
        phone: '022 795 25 78',
        email: 'pietro@abouchar.com'
    }
];

const data2 = [
    {
        id: 1,
        name: 'Reda Bennai',
    }
];

export default function CustomerAccountHeaderDemo(props) {
    const { classes } = useStyles();
    let { searchInput,
        setSearchInput,
        personValueID,
        setPersonValueID,
        personValueID2,
        setPersonValueID2
    } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={9} container spacing={1}>
                    <Grid item xs={12}>Création: 23 janviers 2019</Grid>
                    <Grid item xs={12} className={classes.selectPerson}>
                        <TextField
                            select
                            size="small"
                            value={personValueID}
                            onChange={(e) => setPersonValueID(e.target.value)}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {data.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                        <Typography className={classes.headPhone}>{data.filter(f => (f.id.toString() === personValueID))[0] !== undefined ? data.filter(f => (f.id.toString() === personValueID))[0].phone : null}</Typography>
                        <Typography>{data.filter(f => (f.id.toString() === personValueID))[0] !== undefined ? data.filter(f => (f.id.toString() === personValueID))[0].email : null}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} container className={classes.headRightSide} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.selectPerson2}
                            select
                            size="small"
                            value={personValueID2}
                            onChange={(e) => setPersonValueID2(e.target.value)}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {data2.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Rechercher…" 
                                variant="outlined" 
                                className={classes.search}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        {searchInput.length > 0 ? (<IconButton onClick={() => setSearchInput('')} size="large">
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchInput}
                                onChange={e => setSearchInput(e.target.value)}
                            />
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
