import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import createInvoicePng from '../static/images/create_invoice.png';
import createOfferPng from '../static/images/create_offer.png';
import { setCAButtons, setCAInvoicing } from '../actions';


const useStyles = makeStyles()((theme) => { return {
  actionButtons: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paragraphs: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  disabledIcon: {
    '& img': {
      filter: 'opacity(0.5)',
    },
  },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));


function PopUpCreate(props) {
  const { classes } = useStyles();
  const { createInvoice,
    title,
    altText,
    setCAButtons,
    caButtons,
    currentUser,
    setCAInvoicing,
    caInvoicing,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReason = reason => () => {
    const keyVal = createInvoice ? 'invoiceCreate' : 'invoiceOffer';
    setCAButtons({ ...caButtons, [reason]: true, [keyVal]: true });

    handleClose();
  };

  useEffect(() => {
    setCAInvoicing({ ...caInvoicing, currentUser });
  }, [currentUser]);

  return (
    <div> 
      <Tooltip
        title={title}
        placement="top"
      >
        {createInvoice ? <IconButton
            size="small"
            onClick={handleClickOpen}
          >
            <Avatar
            size="small"
              variant="square"
              alt={altText}
              src={createInvoicePng}
            />
          </IconButton>
        : <IconButton
            className={classes.disabledIcon}
            size="small"
          ><Avatar
            size="small"
              variant="square"
              alt={altText}
              src={createOfferPng}
          />
        </IconButton>}
      </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Création de facture
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.actionButtons}>
            <Button
              onClick={handleReason('transport')}
              color="primary"
              variant="outlined"
            >
              Transport
            </Button>
            <Button
              onClick={handleReason('other')}
              color="primary"
              variant="outlined"
            >
              Autre
            </Button>
          </div>
          <div className={classes.paragraphs}>
            <Typography>Offres en cour</Typography>
            <Typography>N° offre Titre – date de validité</Typography>
            <Typography>N° offre Titre – date de validité</Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      caButtons: state.caButtons,
      caInvoicing: state.caInvoicing,
  };
};

export default connect(mapStateToProps, { setCAButtons, setCAInvoicing })(PopUpCreate);
