import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { editIssue } from '../actions';
import { currentUserId,
  convertDateToWithDash,
  convertDateToTimestamp
} from '../common/functions';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  root: {
    display: 'inline-block',
  },
  openButton: {
    padding: '0 8px !important',

    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(.5),
    },
  },
  radioGroup: {
    margin: '0 48px',
  },
  gridDates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  gridRadio: {
    display: 'flex',
    justifyContent: 'center'
  },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

function IssueTypeEditPopUp(props) {
  const { classes } = useStyles();
  const { title,
    issue,
    editIssue,
    users
  } = props;
  const [open, setOpen] = useState(false);
  const [editType, setEditType] = useState(issue.type_issue.toString());
  const [dateTransporteur, setDateTransporteur] = useState(issue.date_carrier === 0 ? '' : convertDateToWithDash(issue.date_carrier * 1000));
  const [dateClient, setDateClient] = useState(issue.date_customer === 0 ? '' : convertDateToWithDash(issue.date_customer * 1000));
  const [dateAssurance, setDateAssurance] = useState(issue.date_insurance === 0 ? '' : convertDateToWithDash(issue.date_insurance * 1000));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setEditType(event.target.value)
  };

  const onSubmit = () => {
    editIssue(issue.shipment_id, parseInt(editType), currentUserId(users), {},  convertDateToTimestamp(dateClient), convertDateToTimestamp(dateAssurance), convertDateToTimestamp(dateTransporteur));
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Tooltip
        placement="top"
        title={'Edit Issue Dates & Type'}
      >
        
        <Button
          variant="contained"
          color="secondary"
          className={classes.openButton}
          onClick={handleClickOpen}
        >
          {title}
          <EditIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Dialog
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Issue Dates {'&'} Type
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Grid container className={classes.issueForm}>
            <Grid item xs={6} className={classes.gridDates}>
              <TextField
                variant="standard"
                id="dateTo"
                className={classes.datePicker}
                label="Client:"
                type="date"
                value={dateClient}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateClient(e.target.value)} />
              <TextField
                variant="standard"
                id="dateTo"
                className={classes.datePicker}
                label="Assurance:"
                type="date"
                value={dateAssurance}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateAssurance(e.target.value)} />
              <TextField
                variant="standard"
                id="dateTo"
                className={classes.datePicker}
                label="Transporteur:"
                type="date"
                value={dateTransporteur}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setDateTransporteur(e.target.value)} />
            </Grid>
            <Grid item xs={6} className={classes.gridRadio}>
              <RadioGroup
                className={classes.radioGroup}
                value={editType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="secondary" />}
                  label="Endommagé"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="secondary" />}
                  label="Perdu"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="secondary" />}
                  label="Surfacturation"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="secondary" />}
                  label="Autre"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, { editIssue })(IssueTypeEditPopUp);
