import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import ContactsHeader from './ContactsHeader';
import ContactsBody from './ContactsBody';


const useStyles = makeStyles()((theme) => { return {
    stickyHead: {
      position: 'sticky',
      top: theme.spacing(-1),
      zIndex: 2,
    },
}});

export default function ContactsController() {
    const { classes } = useStyles();
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    return (<Grid container space={2}>
        <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
            <ContactsHeader
                setContacts={setContacts}
                setIsLoading={setIsLoading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <ContactsBody
                contacts={contacts}
                isLoading={isLoading}
                searchInput={searchInput}
            />
        </Grid>
    </Grid>);
}
