import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { Editor,
  EditorState,
getDefaultKeyBinding,
RichUtils,
convertToRaw,
ContentState,
convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { Link as LinkHref } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FrameAlertContext } from '../contexts/FrameAlertContext';
import useToken from '../login/useToken';
import { newadmindb } from '../common/Constants';
import {
  convertHoursAndMinutes,
  convertDateToTimestamp,
  convertDateToWithDots,
  convertDateToWithDash,
} from '../common/functions';
import { setChatSearch,
  setGoToCA,
  getCustomerByIDAction,
  editCommentTaskAction,
  setOpenAlertTasks,
} from '../actions';


const useStyles = makeStyles()((theme) => { return {
  greyLight: {
    backgroundColor: "#f5f5f5",
    fontSize: "0.9rem",
    margin: 0,
    padding: theme.spacing(1),
  },
  orangeLight: {
    // backgroundColor: "#ffe0b2",
    fontSize: "0.9rem",
    margin: 0,
    padding: theme.spacing(1),

    '& .RichEditor-root': {
      backgroundColor: "#ffe0b2",
      borderBottom: '1px solid #000',
      marginBottom: theme.spacing(.5),
    },
  },
  orangeLight2: {
    backgroundColor: "#ffe0b2",
    padding: '4px 16px 16px 16px',
    marginTop: theme.spacing(2),
  },
  greenLight: {
    backgroundColor: "#c8e6c9",
    fontSize: "0.9rem",
    margin: 0,
    padding: theme.spacing(1),
  },
  blueLight: {
    backgroundColor: "#cae3f0",
    fontSize: "0.9rem",
    margin: 0,
    padding: theme.spacing(1),
  },
  msgCustomer: {
    fontSize: "0.9rem",
    margin: 0,
    padding: theme.spacing(1),

    '& .RichEditor-root': {
      backgroundColor: "#595959",
      borderBottom: '1px solid #000',
      marginBottom: theme.spacing(.5),
    },
  },
  msgCustomer2: {
    backgroundColor: "#595959",
    color: '#fafafa',
    padding: '4px 16px 16px 16px',
    marginTop: theme.spacing(2),

    '& *': {
      color: '#fafafa',
    },

    '& .taskStatus2': {
      textTransform: "none",
      background: "#94eb94",

      '& span': {
        color: '#333',
      },

      '&:hover': {
        background: '#0000000a',
      },

      '&:hover span': {
        color: '#fafafa',
      },
    },

    '& input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },

    '& .MuiInput-root:before': {
      borderColor: '#fafafa',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid',
    },

    '& .MuiInput-underline:after': {
      borderColor: '#fafafa',
    },
  },
  crLight2: { // contact request
    backgroundColor: "#fde",
    padding: '4px 16px 16px 16px',
    marginTop: theme.spacing(2),

    '& button:hover': {
      backgroundColor: '#fff',
    },
  },
  toRightSide: {
    float: "right",
    display: 'inline-flex',
  },
  whoModified: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
  },
  linkFile: {
    // paddingTop: theme.spacing(0.75),
    // float: "right",
  },
  taskStatus0: {
    textTransform: "none",
    background: "#ff9a9a",
  },
  taskStatus1: {
    textTransform: "none",
    background: "#fefa71",
  },
  taskStatus2: {
    textTransform: "none",
    background: "#94eb94",
  },
  taskStatus4: {
    textTransform: "none",
    background: "#4a5dd6",
    color: "#fff",

    '&:hover': {
      color: '#4a5dd6',
    },
  },
  noneTextTransform: {
    textTransform: "none",
  },
  toWho: {
    '& .MuiInputBase-root': {
      width: 250,
    },
  },
  dateTimeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dateTime: {
    width: 250,
  },
  formControl: {
    height: theme.spacing(10),
  },
  spacingBetween: {
    marginBottom: theme.spacing(1),
    height: 3,
  },
  inputButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  actionButtons: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
  },
  editCancelSave: {
    display: 'inherit',
    marginRight: theme.spacing(4),
  },
  assignedTo: {
    whiteSpace: 'pre-wrap',
    fontSize: '1.1rem',
  },
  dueToAndIcon: {    
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',

    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(.25),
      color: theme.palette.grey[800],
    },
  },
  displayNone: {
    display: 'none',
  },
  marginRight2: {
    marginRight: theme.spacing(2),
  },
  showMoreLessButton: {
    textDecoration: 'underline',
    color: 'red',
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  tabTitle: {
    display: 'inline-block',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: 0,

    '& .MuiSvgIcon-root, .insurance': {
      verticalAlign: 'middle',
    },
  },
  mr16: {
    marginRight: theme.spacing(2),
  },
  nSuivi: {
    fontSize: '1.3rem',
  },
  doneDeleteSnooze: {
    display: 'inline-flex',
    gap: theme.spacing(1),
    
    '& .MuiFormControl-root': {
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
    },

    '& .PrivateSwitchBase-root-128': {
      padding: '0 9px',
    },

    '& .MuiTypography-body1': {
      fontSize: '1rem',
    },
  },
  alertTime: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  pickTimeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    },
  pickTimeTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 256,
  },
  pickTimeMessage: {
    '& input': {
      color: theme.palette.common.white,
    },
    '& > div:hover': {
      color: theme.palette.common.white,
    },
  },
  textFieldEditing: {
    '&.emp': {
      fontStyle: 'italic',
    },

    '&.bold': {
      '& .MuiInputBase-root': {
        fontWeight: '700',
      },
    },
    
    '&.underline': {
      textDecoration: 'underline',
    },
  },
  textUnderlined: {
    textDecoration: 'underline',
  },
  task: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    fontSize: '1.3rem',

    '& .underline': {
      textDecoration: 'underline',
    },

    '& .increase': {
      fontSize: '.9rem',
    },

    '& .decrease': {
      fontSize: '1.7rem',
    },

    '& p': {
        minHeight: '1em',
    },
  },
  biu: {
    '& .RichEditor-controls': {
      '&>*': {
        fontSize: '1.4rem',
        width: 50,
      },
      
      '& .MuiButtonBase-root:not(last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  },
  files: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  goToCAButton: {
    textTransform: 'none',
  },
}});

function RichTextEditor(props) {
  const { editorState,
      onChange,
      editor
  } = props;

  useEffect(() => {
    editor.current.focus();
  }, [editor]);


  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  }

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }
  
  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className="RichEditor-root">
      <div className={className}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={onChange}
          placeholder="Write a task..."
          ref={editor}
        />
      </div>
    </div>
  );
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {  
    return (
      <IconButton
        className={this.props.style}
        color={this.props.active ? 'secondary' : 'default'}
        onMouseDown={this.onToggle}
        size="large">
        {this.props.label}
      </IconButton>
    );
  }
}


var INLINE_STYLES = [
  {label: 'G', style: 'BOLD'},
  {label: 'I', style: 'ITALIC'},
  {label: 'S', style: 'UNDERLINE'},
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TaskDisplay(props) {
  const focusEditInput = useRef(null);
  const { classes } = useStyles();
  const [isEditing, setEditing] = useState(false);
  const { id,
    comment,
    files,
    toUsers,
    taskStatus,
    createdBy,
    updatedBy,
    createdDate,
    updatedDate,
    dueDate,
    editCommentTask,
    deleteComment,
    users,
    item,
    setChatSearch,
    setGoToCA,
    getCustomerByIDAction,
    caGoTo,
    alertTasks,
    editCommentTaskAction,
    setOpenAlertTasks,
  } = props;
  const [newComment, setNewComment] = useState(comment);
  const [personIDs, setPersonIDs] = useState([]);
  const [dueTo, setDueTo] = useState(dueDate !== null ? dueDate : Math.trunc(new Date().getTime()/1000));
  const [show, setShow] = useState(false);
  const [personName, setPersonName] = useState(!item.is_webrequest ? users.filter(t => toUsers.split(',').includes(t.id.toString())).map(item => item.username) : []);
  const [tooltipTitle, setTooltipTitle] = useState(false);
    
  const nowDateAndTime = new Date();
  const nowGetTime = nowDateAndTime.getTime();
  const addThirtyMins = nowGetTime + 1800000;
  const defaultSnoozeTime = new Date(addThirtyMins);
  const [snoozeTime, setSnoozeTime] = useState(`${convertDateToWithDash(defaultSnoozeTime)}T${convertHoursAndMinutes(defaultSnoozeTime)}:00`);
      
  const blocksFromHTML = convertFromHTML(comment);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  const [editorState, setEditorState] = useState(EditorState.createWithContent(state));
  const { token } = useToken();
  const { setStopSearchCustomers } = useContext(FrameAlertContext);
  
  const handleEditorState = (editorState) => {
      setEditorState(editorState);

      var contentState = editorState.getCurrentContent();
      setNewComment(draftToHtml(convertToRaw(contentState)));
  };

  const toggleInlineStyle = (inlineStyle) => {
      handleEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  const handleChange2 = (event) => {
    setPersonName(event.target.value);
    setPersonIDs(users.filter(r => event.target.value.includes(r.username)).map(item => item.id));
  };
  
  function handleChange3(e) {
    setDueTo(Math.trunc(new Date(e.target.value).getTime()/1000));
  }

  const handleSnoozeTime = event => {
    setSnoozeTime(event.target.value);
  };

  const handleSnooze = () => {
    editCommentTask(id, taskStatus, comment, toUsers, dueDate, convertDateToTimestamp(snoozeTime));
  };
 
  const handleSnoozeAll = () => {
    const alertIds = alertTasks.map(item => item.id);
    alertIds.map(id => editCommentTaskAction(id, { snooze: convertDateToTimestamp(snoozeTime) }));
    setOpenAlertTasks(false);
  }

  const handleChatTermine = () => {
    editCommentTask(id, taskStatus, comment, toUsers, dueDate, item.snooze, 0);
  };

  const handleGoTo = () => {
    handleSnooze();
    setChatSearch(item.tracking_nbr.split('<')[0]);
  };
  
  const handleEdit = event => {
    event.preventDefault();

    editCommentTask(id, taskStatus, newComment, personIDs.toString() !== '' ? personIDs.toString() : toUsers, dueTo);
    setNewComment(newComment);
    setEditing(false);
  }

  const handleTooltipClose = () => {
    setTooltipTitle(false);
  };
  
  const cleancombinedText = DOMPurify.sanitize(comment, { USE_PROFILES: { html: true } });

  const countFiles = files !== null ? files.slice(0, -1).split(';').length : 0;

  const showMoreFiles = (files !== null && files.slice(0, -1).split(';').slice(5).map((file, i) => (<span key={i} className={classes.inlineFlex}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file}</a></Tooltip> </span>)));

  const fiveFiles = (files !== null && files.slice(0, -1).split(';').slice(0, 5).map((file, i) => (<span key={i} className={classes.inlineFlex}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file.length > 9 ? `${file.slice(0, 8)}...` : file}</a></Tooltip> </span>)));

  const ifTaskEditing = (<Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      className={classes.formControl}
    >
      <Grid item xs={1}>
        <p className={classes.editTaskLabel}><b>(Tâche):</b></p>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <div className={classes.toWho}>
          <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
          <Select
            variant="standard"
            labelId="users-mutiple-checkbox-label"
            id="users-mutiple-checkbox2"
            multiple
            value={personName}
            onChange={handleChange2}
            input={<Input />}
            renderValue={(selected) => selected.toString() !== '' ? selected.join(', ') : ''}
            MenuProps={MenuProps}>
            {users.sort(function(a, b) {
              let x = a.username.toLowerCase();
              let y = b.username.toLowerCase();
              if(x < y) { return -1; }
              if(x > y) { return 1; }
              return 0;
            }).map((item) => (
              <MenuItem key={item.id} value={item.username}>
                <Checkbox checked={personName.indexOf(item.username) > -1} />
                <ListItemText primary={item.username} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className={classes.dateTimeContainer}>
          <TextField
            variant="standard"
            id="datetime-task"
            label="Due to:"
            type="datetime-local"
            className={classes.dateTime}
            InputLabelProps={{
            shrink: true,
            }}
            defaultValue={((new Date(dueTo*1000)).getFullYear().toString()+'-'+(new Date(dueTo*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo*1000)).getDate().toString().padStart(2, '0')+'T'+(new Date(dueTo*1000)).getHours().toString().padStart(2, '0')+':'+(new Date(dueTo*1000)).getMinutes().toString().padStart(2, '0'))}
            onChange={handleChange3} />
        </div>
        </Grid><br /><br />
    </Grid>
  );
  
  const editing = (
    <div className={classes.orangeLight}>
      <form id="formEditCommentTask" onSubmit={handleEdit}>
        <span>
          {ifTaskEditing}
          <RichTextEditor
            editorState={editorState}
            onChange={handleEditorState}
            editor={focusEditInput}
          />
        </span>
      </form>
      <span className={classes.actionButtons}>
        <span className={classes.editCancelSave}>
          <Button
            className={classes.noneTextTransform}
            size="small"
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
          <Button
            className={classes.noneTextTransform}
            size="small"
            type="submit"
            form="formEditCommentTask"
          >
            Save
          </Button>
        </span>
        <span className={classes.biu}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
          />
        </span>
      </span>
    </div>
  );

  const ifTask = (<div className={classes.doneDeleteSnooze}>
    <Button
      className={classes.taskStatus2}
      size="small"
      onClick={() => editCommentTask(id, 3, comment)}
    >
      terminé
    </Button>
    <Button
      className={classes.taskStatus0}
      size="small"
      onClick={() => deleteComment(id)}
    >
      Delete
    </Button>
    <form className={classes.pickTimeContainer} noValidate>
      <TextField
        variant="standard"
        id="time"
        type="datetime-local"
        className={classes.pickTimeTextField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900, // 15 min
        }}
        value={snoozeTime}
        onChange={handleSnoozeTime} />
    </form>
    <Button
      className={classes.taskStatus4}
      size="small"
      onClick={handleSnooze}
    >
      Snooze
    </Button>
  </div>);

  const ifChat = (<div className={classes.doneDeleteSnooze}>
    <Button
      className="taskStatus2"
      size="small"
      onClick={handleChatTermine}
    >
      Mark as Read
    </Button>
    <form className={classes.pickTimeContainer} noValidate>
      <TextField
        variant="standard"
        id="time"
        type="datetime-local"
        className={`${classes.pickTimeTextField} ${classes.pickTimeMessage}`}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900, // 15 min
        }}
        value={snoozeTime}
        onChange={handleSnoozeTime} />
    </form>
    <Button
      className={classes.taskStatus4}
      size="small"
      onClick={handleSnooze}
    >
      Snooze
    </Button>
  </div>);

  // Contact Request Actions
  const ifCr = (<div className={classes.doneDeleteSnooze}>
    <Button
      className={classes.taskStatus1}
      size="small"
      variant="outlined"
      onClick={() => editCommentTask(id, 2, comment)}
    >
      En cours
    </Button>
    <Button
      className={classes.taskStatus2}
      size="small"
      variant="outlined"
      onClick={() => editCommentTask(id, 3, comment)}
    >
      terminé
    </Button>
    <form className={classes.pickTimeContainer} noValidate>
      <TextField
        variant="standard"
        id="time"
        type="datetime-local"
        className={classes.pickTimeTextField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900, // 15 min
        }}
        value={snoozeTime}
        onChange={handleSnoozeTime} />
    </form>
    <Button
      className={classes.taskStatus4}
      size="small"
      variant="outlined"
      onClick={handleSnooze}
    >
      Snooze
    </Button>
  </div>);

  const handleGoToCA = () => {
    if(item.customer_id) {
      handleSnoozeAll();
      setGoToCA(true);
      setStopSearchCustomers(false);
      getCustomerByIDAction(token, item.customer_id);
    }
  };

  const goToCustomerAccountButton = (
    <Button
      disabled={!item.customer_id || caGoTo}
      className={classes.goToCAButton}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleGoToCA}
    >
      {item.contact_email}
    </Button>
  );

  const ifUpdate = <span>Dernière modification à: <span className={classes.textUnderlined}>{users.filter(filt => (filt.id === updatedBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === updatedBy))[0].username}</span>&nbsp;({convertDateToWithDots(updatedDate*1000)} {convertHoursAndMinutes(updatedDate*1000)})</span>;

  const trackingNumber = item.tracking_nbr.split(/<br\/>|\r\n|\r|\n/g).length > 1 ? <span className={classes.nSuivi}>N° suivi: <strong>{item.tracking_nbr.split('<br/>')[0]}...</strong></span> : item.tracking_nbr.length ? <span className={classes.nSuivi}>N° suivi: <strong>{item.tracking_nbr.split('<br/>')[0]}</strong></span>
  : <span className={classes.nSuivi}>N° suivi: <strong><em>(aucun)</em></strong></span>;

  const display = (
    <div className={`${classes.orangeLight} ${classes.alertDisplay}`}>
      <span className={classes.toRightSide}>
        <p>{trackingNumber}</p>
      </span>
      <p className={classes.assignedTo}>Attribué à : <span className={classes.textUnderlined}>{personName.join(', ')}</span></p>
      <Divider />
      <div className={classes.orangeLight2}>
        <p className={classes.dueToAndIcon}><NotificationsActiveIcon />&nbsp;{convertDateToWithDots(dueDate*1000)} ({convertHoursAndMinutes(dueDate*1000)})</p>
        {/* <p className={classes.task}>
          {item.comment}
        </p> */}
        <div
          className={classes.task}
          dangerouslySetInnerHTML={{ __html: cleancombinedText}}
        />
        <div className={classes.files}>
          <div className={files !== null ? classes.linkFile : null}>
            {fiveFiles}
            <Link
              className={countFiles > 5 ? classes.showMoreLessButton : classes.displayNone}
              component="button"
              variant="body2"
              onClick={() => {
                setShow(!show);
              }}
              underline="hover">
              {show ? 'Show Less' : 'Show More'}
            </Link>
          </div>
          {show ?
            <div className="showMoreFiles">
              {showMoreFiles}
            </div>
          : null}
        </div>
        {ifTask}
        <span className={classes.toRightSide}>
          <Button
            className={classes.noneTextTransform}
            size="small"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        </span>
      </div>
      <div>
        <Divider style={{ marginTop: 16 }} />
      </div>
      <p className={classes.whoModified}>
        <span>
          {createdDate !== updatedDate ? ifUpdate : ''}
        </span>
        <span>
          Ajouté par:&nbsp;<span className={classes.textUnderlined}>{users.filter(filt => (filt.id === createdBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === createdBy))[0].username}</span>&nbsp;({convertDateToWithDots(createdDate*1000)} {convertHoursAndMinutes(createdDate*1000)})
        </span>
      </p>
    </div>
  );

  const displayChat = (
    <div className={`${classes.msgCustomer} ${classes.alertDisplay}`}>
      <div className={classes.toRightSide}>
      <Tooltip
        placement="top"
        title={item.tracking_nbr.split(/<br\/>/).map(trackNum => <p key={trackNum}>{trackNum}</p>)}
      >
        <Button
          className={`${classes.btnTrackingNumbers} ${item.tracking_nbr.length === 0 ? 'hide' : ''}`}
          component={LinkHref}
          to="/messaging"
          onClick={handleGoTo}
        >
          {item.tracking_nbr.split(/<br\/>|\r\n|\r|\n/g).length > 1 ? item.tracking_nbr.split(/<br\/>|\r\n|\r|\n/g).slice(0, 1).concat(['...']).join(' ') : item.tracking_nbr.replace(/<br\/>|\r\n|\r|\n/g, "\n")}
        </Button>
      </Tooltip>
      <ClickAwayListener                        
        onClickAway={handleTooltipClose}
      >
        <Tooltip
          className={`${item.tracking_nbr.length === 0 ? 'hide' : ''}`}
          title="Copié"
          PopperProps={{
              disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={tooltipTitle}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Button onClick={() => {
            navigator.clipboard.writeText(item.tracking_nbr.replace(/<br\/>|\r\n|\r|\n/g, " "));
            setTooltipTitle(true);
          }}>
            copie
          </Button>
        </Tooltip>
      </ClickAwayListener>
      </div>
      <p className={classes.assignedTo}>Message From:&nbsp;<span className={classes.textUnderlined}><strong>{item.contact_name}</strong></span>&nbsp;({convertDateToWithDots(createdDate*1000)} {convertHoursAndMinutes(createdDate*1000)})</p>
      <Divider />
      <div className={classes.msgCustomer2}>
        <p className={classes.dueToAndIcon}><NotificationsActiveIcon />&nbsp;{convertDateToWithDots(dueDate*1000)} ({convertHoursAndMinutes(dueDate*1000)})</p>
        <div
          className={classes.task}
          dangerouslySetInnerHTML={{ __html: cleancombinedText}}
        />
        <div className={classes.files}>
          <div className={files !== null ? classes.linkFile : null}>
            {fiveFiles}
            <Link
              className={countFiles > 5 ? classes.showMoreLessButton : classes.displayNone}
              component="button"
              variant="body2"
              onClick={() => {
                setShow(!show);
              }}
              underline="hover">
              {show ? 'Show Less' : 'Show More'}
            </Link>
          </div>
          {show ?
            <div className="showMoreFiles">
              {showMoreFiles}
            </div>
          : null}
        </div>
        {ifChat}
        <span className={classes.toRightSide}>
        </span>
      </div>
      <div>
        <Divider style={{ marginTop: 16 }} />
      </div>
      <p className={classes.whoModified}>
        <span>
          Shipment ID: {item.shipment_id}
        </span>
        <span></span>
      </p>
    </div>
  );

  const displayCR = (
    <div className={`${classes.orangeLight} ${classes.alertDisplay}`}>
      <span className={classes.toRightSide}>
        <p><span>Contact ID: <strong>{item.id}</strong></span></p>
      </span>
      <p className={classes.assignedTo}>Demande paiement par facture</p>
      <Divider />
      <div className={classes.crLight2}>
        <p className={classes.dueToAndIcon}><NotificationsActiveIcon />&nbsp;{convertDateToWithDots(dueDate*1000)} ({convertHoursAndMinutes(dueDate*1000)})</p>
        <div
          className={classes.task}
          dangerouslySetInnerHTML={{ __html: cleancombinedText}}
        />
        <div className={classes.files}>
          <div className={files !== null ? classes.linkFile : null}>
            {fiveFiles}
            <Link
              className={countFiles > 5 ? classes.showMoreLessButton : classes.displayNone}
              component="button"
              variant="body2"
              onClick={() => {
                setShow(!show);
              }}
              underline="hover">
              {show ? 'Show Less' : 'Show More'}
            </Link>
          </div>
          {show ?
            <div className="showMoreFiles">
              {showMoreFiles}
            </div>
          : null}
        </div>
        {ifCr}
        <span className={classes.toRightSide}>
        </span>
      </div>
      <div>
        <Divider style={{ marginTop: 16 }} />
      </div>
      <p className={classes.whoModified}>
        <span>
          {item.contact_phone} {(item.contact_phone && item.contact_email) ? '|' : ''} {goToCustomerAccountButton}
        </span>
        <span>{item.contact_email}</span>
      </p>
    </div>
  );

  return <div className={`newsFeed`}>
    {(item.is_webrequest) ? displayCR
    : item.is_chat ? displayChat
    : isEditing ? editing
    : display}
    <Divider light className={classes.spacingBetween} />
  </div>;
}

const mapStateToProps = state => {
  return { 
    users: state.users,
    caGoTo: state.caGoTo,
    alertTasks: state.alertTasks,
  };
};

export default connect(mapStateToProps, {
  setChatSearch,
  setGoToCA,
  getCustomerByIDAction,
  editCommentTaskAction,
  setOpenAlertTasks,
})(TaskDisplay);
