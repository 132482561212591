import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { logOut } from '../common/Frame';
import { convertDateToTimestamp } from '../common/functions';
import useToken from '../login/useToken';
import { postIssue,
  setRefresh,
  makeShipmentAnIssue,
  deletedShipment,
} from '../actions';


const useStyles = makeStyles()((theme) => { return {
    root: {
        margin: 'auto',
        '& form': {
            textAlign: 'center',
        },
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    menuIcon: {
      color: theme.palette.grey[700],
    },
    formGroup: {
      margin: 'auto',
      padding: theme.spacing(2),
      width: 'fit-content',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    issueForm: {
      backgroundColor: '#ddd',
      padding: theme.spacing(2),
    },
    gridDates: {
      '& .MuiFormControl-root:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiFormLabel-root': {
        fontWeight: '700',
      },
    },
    displayNone: {
      display: 'none',
    },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
}));

function ChatPopUpBurger(props) {
  const { classes } = useStyles();
  const { token } = useToken();
  const {  shipmentId,
    isIssue,
    currentUser,
    postIssue,
    setRefresh,
    makeShipmentAnIssue,
    deletedShipment,
    refresh,
    isArchive,
    recoveryShipment,
    recoveryStatusAndSendToTracking,
  } = props;
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [value, setValue] = useState('3');
  const [typeIssue, setTypeIssue] = useState('1');
  const [dateTransporteur, setDateTransporteur] = useState('');
  const [dateClient, setDateClient] = useState('');
  const [dateAssurance, setDateAssurance] = useState('');
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  
  const handleClickOpen = () => {
    setOpen(true);

    if(isIssue) {
      setValue('5');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleRadioChange2 = (event) => {
    setTypeIssue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === '3') {
      if(isArchive) {
        recoveryShipment('/recover');
        recoveryStatusAndSendToTracking('Recovered');
      }

      postIssue(shipmentId, convertDateToTimestamp(dateClient), convertDateToTimestamp(dateAssurance), convertDateToTimestamp(dateTransporteur), parseInt(typeIssue), currentUser);
      makeShipmentAnIssue(shipmentId, token);
      setRefresh(refresh + 1);
    } else if (value === '5') {
      deletedShipment(token, shipmentId, currentUser);
    }
  };

  const issueForm = (
    <Grid container className={classes.issueForm}>
      <Grid item xs={6} className={classes.gridDates}>
        <TextField
          variant="standard"
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Client:"
          type="date"
          value={dateClient}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateClient(e.target.value)} />
        <TextField
          variant="standard"
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Assurance:"
          type="date"
          value={dateAssurance}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateAssurance(e.target.value)} />
        <TextField
          variant="standard"
          error={!(dateTransporteur || dateClient || dateAssurance)}
          id="dateTo"
          className={classes.datePicker}
          label="Transporteur:"
          type="date"
          value={dateTransporteur}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDateTransporteur(e.target.value)} />
      </Grid>
      <Grid item xs={6}>
        <RadioGroup
          value={typeIssue}
          onChange={handleRadioChange2}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Endommagé"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Perdu"
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label="Surfacturation"
          />
          <FormControlLabel
            value="4"
            control={<Radio color="primary" />}
            label="Autre"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );

  return (
    <div>
        <MenuIcon
          fontSize="large"
          onClick={handleClickOpen}
          className={classes.menuIcon}
        />
        <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
              <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
                Que veux-tu faire {username} ?
              </DialogTitle>
              <Divider />
              <form onSubmit={handleSubmit}>
                <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                  <RadioGroup
                    aria-label="burgerbutton"
                    name="burgerbutton"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      disabled={isIssue}
                      value="3"
                      control={<Radio />}
                      label="Issue"
                    />
                    {(value === '3' ? issueForm : null)}
                    <FormControlLabel
                      disabled={isIssue}
                      value="5"
                      control={<Radio />}
                      label="Delete Shipment and Invoice"
                    />
                  </RadioGroup>
                  <FormHelperText
                    className={(value === '3' && !(dateTransporteur || dateClient || dateAssurance)) ? '' : classes.displayNone}
                    error
                  >
                    At least one of dates should be filled.
                  </FormHelperText>
                  <Button
                    disabled={(value === '3' && !(dateTransporteur || dateClient || dateAssurance)) || isIssue}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={handleClose}
                  >
                    Valider
                  </Button>
                </FormControl>
              </form>
            </div>
        </Dialog>
    </div>
  );
}

const mapStateToProps =  state => {
  return { refresh: state.refresh };
};

export default connect(mapStateToProps, {
  postIssue,
  setRefresh,
  makeShipmentAnIssue,
  deletedShipment,
})(ChatPopUpBurger);
