// export const API_KEY = 'AIzaSyCNpnuYPbzc9HEuyAfkgrAmt0hE-rZcb-g';
// export const CLIENT_ID = '94644807781-c9otskjev62ojggtm4n9s5pldecq4j6e.apps.googleusercontent.com';

// export const CALENDAR_ID = [
//     {
//         name: 'Main',
//         calendarId: 'expedicalendar@gmail.com'
//     },
//     {
//         name: 'Second',
//         calendarId: 'uo2psh1kpccak5v0phpoisjvis@group.calendar.google.com'
//     },
// ];


export const API_KEY = 'AIzaSyBFYZemGOxi4-h7qsMu-srBkOgyCTbq_hY';
export const CLIENT_ID = '935634979232-9qqkqqoeeqt8j601e2jscglqhpgrhiit.apps.googleusercontent.com';

export const CALENDAR_ID = [
    {
        name: 'Main',
        calendarId: 'q367ht717oife66vb9k2s7j51s@group.calendar.google.com'
    },
    {
        // name: 'Second',
        name: 'Super Secret Calendar',
        calendarId: 'agayev.browsers@gmail.com'
    },
];
