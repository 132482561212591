import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';


const useStyles = makeStyles()((theme) => { return {
  search: {
    width: '90%',
    "& .MuiButtonBase-root": {
        padding: 0,
        color: "gray",
    },
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headText: {
    '& .MuiTypography-root': {
        color: 'gray',
        fontSize: theme.spacing(6),
    },
  },
  searchGrid: {
    // transform: 'translateY(30%)',
    // height: '100%',
  },
  gridHighlighted: {
    transform: 'translateY(40%)',
    textAlign: 'center',
  },
}});

export default function ArchiveTrackingHead(props) {
    const { classes } = useStyles();
    let { input,
        setInput,
        setTracking,
        // isClickedHighlight,
        // setClickedHighlight,
        // highlightCount,
        // query,
        setPageZero
    } = props;
    const history = useHistory();

    function goTo(path) {
        return history.push(`/archive${path}`);
        // window.location.href = `/archive${path}`;
    }

    return (
        <div>
            <Grid 
                container
                spacing={1}
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
            >
                <Grid item xs={8} className={classes.headText}>
                    <Typography>
                        Archive Shipment Tracking
                    </Typography>
                </Grid>

                <Grid item xs={2} className={classes.gridHighlighted}>
                    {/* <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={isClickedHighlight ? "contained" : "outlined"}
                        color={isClickedHighlight ? "secondary" : "v4default"}
                        onClick={() => {
                            setClickedHighlight(!isClickedHighlight);
                        }}
                    >
                        Highlighted: {highlightCount}
                    </Button> */}
                </Grid>

                <Grid item xs={2} className={classes.searchGrid}>
                    <Grid 
                        container 
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <form noValidate autoComplete="off">
                                <TextField 
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Rechercher…" 
                                    variant="outlined" 
                                    className={classes.search}
                                    helperText='Please click "Enter" to search.'
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                          ev.preventDefault();
                                            if(input.length > 2) {
                                                goTo(`?search=${input}`);
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {input.length > 0 ? (<IconButton
                                                onClick={() => {
                                                    setInput('');
                                                    goTo('');
                                                    setTracking([]);
                                                }}
                                                size="large">
                                                <HighlightOffIcon size="small" />
                                            </IconButton>) : ''}
                                          </InputAdornment>
                                        ),
                                      }}
                                    value={input}
                                    onChange={e => {
                                        setInput(e.target.value);
                                        setTracking([]);
                                        setPageZero(oldKey => (oldKey + 1));
                                    }}
                                />
                            </form>
                        </Grid>
                    
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
