import React from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListIcon from '@mui/icons-material/List';
import SortIcon from '@mui/icons-material/Sort';
import SvgIcon from '@mui/material/SvgIcon';


const useStyles = makeStyles()((theme) => { return {
  sortBtn: {
    width: '90%',
    textTransform: "none",
  },
  sortIcon: {
    paddingRight: theme.spacing(1),
  },
}});

const StyledMenu = withStyles((props) => (
    <Menu
      elevation={0}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ),{
  paper: {
    border: '1px solid #d3d4d5',
  },
});

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '&:focus': {
    //   backgroundColor: theme.palette.action.disabledBackground,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000', // color: theme.palette.common.white,
      },
    },
  },
}));

function DlvDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function DlvAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function PickDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M8 7.15C10.67 7.15 12.85 9.32 12.85 12C12.85 14.68 10.68 16.85 8 16.85C5.32 16.85 3.15 14.68 3.15 12C3.15 9.32 5.32 7.15 8 7.15M7 9V12.69L10.19 14.53L10.94 13.23L8.5 11.82V9" />
      </SvgIcon>
    );
}

function PickAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M8 7.15C10.67 7.15 12.85 9.32 12.85 12C12.85 14.68 10.68 16.85 8 16.85C5.32 16.85 3.15 14.68 3.15 12C3.15 9.32 5.32 7.15 8 7.15M7 9V12.69L10.19 14.53L10.94 13.23L8.5 11.82V9" />
      </SvgIcon>
    );
}

export default function SortDate(props) {
  const { setSortByDate } = props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [titleName, setTitleName] = React.useState('Delivery date Asc');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.sortBtn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}>
        <SortIcon className={classes.sortIcon} fontSize="medium" />{titleName}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Sorted by: Default');
                setSortByDate(0);
            }}
            selected={false}
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Default" />
        </StyledMenuItem> */}
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Delivery date Desc');
                setSortByDate(1);
            }}
            selected={titleName === 'Delivery date Desc' ? true : false}
        >
          <ListItemIcon>
            <DlvAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delivery date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Delivery date Asc');
                setSortByDate(2);
            }}
            selected={titleName === 'Delivery date Asc' ? true : false}
        >
          <ListItemIcon>
            <DlvDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delivery date Asc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Pickup date Desc');
                setSortByDate(3);
            }}
            selected={titleName === 'Pickup date Desc' ? true : false}
        >
          <ListItemIcon>
            <PickAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Pickup date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Pickup date Asc');
                setSortByDate(4);
            }}
            selected={titleName === 'Pickup date Asc' ? true : false}
        >
          <ListItemIcon>
            <PickDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Pickup date Asc" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
