import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';
import { getDataWithAuth, logOut } from '../common/Frame';
import { URL_All } from '../common/Constants';
import useToken from '../login/useToken';


const useStyles = makeStyles()((theme) => { return {
    root: {
      margin: 'auto',
      textAlign: 'center',
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    formGroup: {
        margin: 'auto',
        padding: theme.spacing(2),
        width: 'fit-content',
    },
    transferIcon: {
      marginLeft: theme.spacing(1),
      color: '#555',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(2, 1, 2, 0),
    },
    dialogActions: {
      placeContent: 'center',
    },
    dialogContent: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      '& p': {
        marginBottom: theme.spacing(0),
        color: '#000',
        fontSize: '1.3em',
      },
    },
    percentage: {
      fontStyle: 'normal',
      fontWeight: '700',
    },
    displayNone: {
      display: 'none',
    },
    divButton: {
      display: 'inline-block',
    },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
}));

export default function ClientTransfer(props) {
  const { classes } = useStyles();
  const { user } = props;
  const { customerListAll } = useContext(CustomerAccountContext);
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [companyID, setCompanyID] = useState(-1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  };

  function transferClient() {
    getDataWithAuth(`${URL_All.customersviewset}/${companyID}/assign_to/?ikuser_id=${user.id}`, token)
      .then(() => window.location.reload())
      .catch(err => console.error(err))
  }

  const username = localStorage.getItem('username');
  if(!username) logOut();

  return (
    <div className={classes.divButton}>
      <Tooltip title="Transfer" onClick={handleClickOpen} placement="bottom">
        <IconButton className={classes.transferIcon} size="large">
          <TransferWithinAStationIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
          <div className={classes.root}>
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={() => handleClose()}>
              Where do you want to transfer <u><i>{user.user.email}</i></u>, {username}?
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.dialogContent}>
              <Autocomplete
                id="combo-box-demo"
                className={classes.search}
                size="small"
                autoHighlight
                options={customerListAll}
                getOptionLabel={options => (
                    (customerListAll.length) === 0 ? ''
                    : options.company_name === null ? options.users[0].user.username
                    : options.company_name.trim() === '' ? options.users[0].user.username
                    : `${options.company_name} ${options.users.length > 1 ? `(${options.users.length})` : ''}`
                )}
                onChange={(e, v, r) => {
                    if((r === 'selectOption') && (v !== null)) {
                      setCompanyID(v.id);
                    } else if (r === 'clear') {
                      setCompanyID(-1);
                    }
                }}
                renderInput={(params) => <TextField
                    {...params}
                    label='Choose Company'
                    variant="outlined"
                />}
              />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                disabled={companyID === -1 ? true : false}
                onClick={() => {
                  transferClient();
                  handleClose();
                }}
              >
                  Valider
              </Button>
            </DialogActions>
          </div>
      </Dialog>
    </div>
  );
}
