import React, { useContext, useState } from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogTitle from '@mui/material/DialogTitle';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useToken from '../login/useToken';
import { URL_All } from '../common/Constants';
import { getDataWithAuth } from '../common/Frame';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';


const useStyles = makeStyles()((theme) => { return {
    root: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    addButton: {
        '&:hover': {
            color: '#3bd73b',
        },
        padding: theme.spacing(0),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textItalic: {
        fontStyle: 'italic !important',
    },
    nativeSelect: {
        marginTop: theme.spacing(2),
        fontStyle: 'italic',
        '& option': {
            fontStyle: 'normal',
        },
    },
    grid: {
        alignSelf: 'flex-end',
    },
    gridAdjustment: {
        alignSelf: 'flex-end',
    },
    textRed: {
        color: 'red',
    },
}});

const DialogTitle = withStyles((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle component="div" className={classes.root} {...other}>
                <Typography className={classes.title} variant="h6">{children}</Typography>
                {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    }, (theme) => ({
        root: {
        margin: 0,
        padding: theme.spacing(0),
        },
        title: {
            marginLeft: '24px !important',
            marginTop: '24px !important',
        },
        closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
        },
}));

export default function AdjustmentFormAdd(props) {
    const { classes } = useStyles();
    const { token } = useToken();
    const [open, setOpen] = useState(false);
    const [adjustment, setAdjustment] = useState('');
    const [transporters, setTransporters] = useState([]);
    const [selectedTransporter, setSelectedTransporter] = useState(-1);
    const [ifNaN, setIfNaN] = useState(false);
    const { existCompanies } = useContext(CustomerAccountContext);

    function handleClickOpen() {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    function listTransporters() {
        getDataWithAuth(URL_All.transporters, token)
            .then(json => setTransporters(json))
            .catch(err => console.error(err))
    }

    const errorNaN = (<FormHelperText className={classes.textRed}>
        Numbers Only
    </FormHelperText>);

    const errorMax = (<FormHelperText className={classes.textRed}>
        Can NOT be more than 100.00
    </FormHelperText>);

    const errorMin = (<FormHelperText className={classes.textRed}>
        Can NOT be less than 0.01
    </FormHelperText>);
    
    const errorDecimal = (<FormHelperText className={classes.textRed}>
        Fractional part can NOT be more than 2 digits
    </FormHelperText>);

    return (
        <div>
            <Tooltip title="Add">
                <IconButton
                    aria-label="add"
                    className={classes.addButton}
                    onClick={() => {
                        handleClickOpen();
                        listTransporters();
                    }}
                    size="large">
                    <AddCircleOutlineRoundedIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                className={classes.root}
            >
                <DialogTitle id="form-dialog-title" onClose={handleClose} >
                    New Adjustment:
                </DialogTitle>
                <DialogContent>
                    <form id="formAdjustmentAdd" onSubmit={(e) => {
                        e.preventDefault();
                        (adjustment.trim() !== '' && selectedTransporter !== -1) ? props.addAdjustment(selectedTransporter, parseFloat(adjustment)/100) : handleClose();
                        setAdjustment('');
                        setSelectedTransporter(-1);
                    }}>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={7}>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <NativeSelect
                                        className={selectedTransporter === -1 ? classes.nativeSelect : classes.selectEmpty}
                                        value={selectedTransporter}
                                        onChange={(e) => setSelectedTransporter(parseInt(e.target.value))}
                                        margin="dense"
                                        inputProps={{ 'aria-label': 'age' }}
                                    >
                                        <option className={classes.textItalic} value="-1" disabled>
                                            Select Transporter
                                        </option>
                                        {transporters.filter(tr => (!existCompanies.includes(tr.id))).map(transporter => (
                                            <option value={transporter.id} key={transporter.id}>{transporter.name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} className={classes.gridAdjustment}>
                                {ifNaN ? errorNaN
                                    : (adjustment === '') ? null
                                    : (parseFloat(adjustment) > 100) ? errorMax
                                    : (parseFloat(adjustment) <= 0) ? errorMin
                                    : (((parseFloat(adjustment) * 100) % 1) !== 0) ? errorDecimal
                                : null}
                                <TextField
                                    variant="standard"
                                    className={classes.textField}
                                    autoFocus
                                    autoComplete="off"
                                    margin="dense"
                                    id="new-comment-input"
                                    type="text"
                                    fullWidth
                                    error={ifNaN
                                        || (adjustment.trim() === '')
                                        || (parseFloat(adjustment) > 100)
                                        || (parseFloat(adjustment) <= 0)
                                        || (((parseFloat(adjustment) * 100) % 1) !== 0)
                                    }
                                    value={adjustment}
                                    onChange={(e) => {
                                        setAdjustment(e.target.value);
                                        if(isNaN(parseFloat(e.target.value))) {
                                            setIfNaN(true);
                                        } else {
                                            setIfNaN(false);
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            %
                                        </InputAdornment>),
                                    }} />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        color="primary"
                        type="submit"
                        form="formAdjustmentAdd" 
                        onClick={handleClose}
                        disabled={ifNaN
                            || (adjustment.trim() === '')
                            || (selectedTransporter === -1)
                            || (parseFloat(adjustment) > 100)
                            || (parseFloat(adjustment) <= 0)
                            || (((parseFloat(adjustment) * 100) % 1) !== 0)
                        }
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
          </Dialog>
        </div>
    );
}
