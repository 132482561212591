import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import CustomerAccountHeader from './CustomerAccountHeader';
import CustomerAccountBody from './CustomerAccountBody';


const useStyles = makeStyles()((theme) => { return {
    stickyHead: {
      position: 'sticky',
      top: theme.spacing(-1),
      zIndex: 2,
    },
}});

export function getMonthFrench(date) {
    let month = [];
    month[0] = "janvier";
    month[1] = "février";
    month[2] = "mars";
    month[3] = "avril";
    month[4] = "mai";
    month[5] = "juin";
    month[6] = "juillet";
    month[7] = "août";
    month[8] = "septembre";
    month[9] = "octobre";
    month[10] = "novembre";
    month[11] = "décembre";
  
    const d = new Date(date);
    const n = month[d.getMonth()];
    return n;
}

export default function CustomerAccountController() {
    const { classes } = useStyles();

    return (<Grid container space={2}>
        <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
            <CustomerAccountHeader />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <CustomerAccountBody />
        </Grid>
    </Grid>);
}
