import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import CustomerAccountHeaderDemo from './CustomerAccountHeaderDemo';
import CustomerAccountBodyDemo from './CustomerAccountBodyDemo';


const useStyles = makeStyles()((theme) => { return {
    stickyHead: {
      position: 'sticky',
      top: theme.spacing(-1),
      zIndex: 2,
    },
}});

export default function CustomerAccountControllerDemo() {
    const { classes } = useStyles();
    const [searchInput, setSearchInput] = useState('');
    const [personValueID, setPersonValueID] = useState('1');
    const [personValueID2, setPersonValueID2] = useState('1');


    return (<Grid container space={2}>
        <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
            <CustomerAccountHeaderDemo
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                personValueID={personValueID}
                setPersonValueID={setPersonValueID}
                personValueID2={personValueID2}
                setPersonValueID2={setPersonValueID2}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <CustomerAccountBodyDemo />
        </Grid>
    </Grid>);
}
