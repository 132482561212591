import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Frame from './common/Frame';
import LoginPage from './login/LoginPage';
import useToken from './login/useToken';
import './css/app.css';


const theme = createTheme({
  typography: {
   fontFamily: `"Calibri", sans-serif`,
   fontSize: 16,
   fontWeightLight: 300,
   fontWeightRegular: 400,
   fontWeightMedium: 500,
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'v4default',
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'v4default' },
          style: {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          }
        }
      ]
    },
  },
  palette: {
    v4default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function App() {
  const { token, setToken } = useToken();
  const [stateSnackbar, setSnackbar] = useState({
		openSnackbar: false,
		vertical: 'bottom',
		horizontal: 'left',
	}); 

  const handleOpenSnackbar = () => {    
    setSnackbar({ ...stateSnackbar, openSnackbar: true });
  };
  
  const handleCloseSnackbar = () => {
		setSnackbar({ ...stateSnackbar, openSnackbar: false });
	};

  if(!token) {
    return <LoginPage
      setToken={setToken}
      handleOpenSnackbar={handleOpenSnackbar}
    />
  }

  return (
    <div id="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Frame
            stateSnackbar={stateSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
