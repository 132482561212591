import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import useToken from '../login/useToken';
import { postModifyPickup } from '../actions';
import { PickupIcon } from './TrackingList';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  root: {
    margin: 'auto',
  },
  pickupIcon: {
    color: grey[700],
    transform: 'scaleX(-1)',
  },
  dialogTitle: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  dialogContent: {
    margin: 'auto',
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  timeFrom: {
    marginRight: theme.spacing(3),
  },
  timeTo: {
    marginLeft: theme.spacing(3),
  },
  buttomDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1, 1, 0, 0),
    marginTop: theme.spacing(1),
    width: 150,
  },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

function PickUpPopUp(props) {
  const { classes } = useStyles();
  const { token } = useToken();
  const { item, postModifyPickup } = props;
  const [open, setOpen] = useState(false);
  const keys = {
    timeFrom: 'timeFrom',
    timeTo: 'timeTo',
    datePickUp: 'datePickUp',
  };
  const [values, setValues] = useState({
    [keys.datePickUp]: item.pickup_date || null,
    [keys.timeFrom]: item.pickup_request.ready_time || '10:00',
    [keys.timeTo]: item.pickup_request.close_time || '12:00',
  });
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if(values.datePickUp.length && values.timeFrom.length && values.timeTo.length) {
      const body = { date_pickup: values.datePickUp,
        time_from: values.timeFrom.slice(0,5),
        time_to: values.timeTo.slice(0,5),
      };
      postModifyPickup(token, item.id, body);
      
      handleClose();
    }
  };

  return (
    <div>
        <Tooltip title="Modifier l’enlèvement" placement="top">
          <IconButton onClick={handleClickOpen}>
            <PickupIcon className={classes.pickupIcon} />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
              <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
                Modifier l’enlèvement
                <br />
                Confirmation: {item.pickup_request.confirmation_number && item.pickup_request.confirmation_number}
              </DialogTitle>
              <Divider />
              <div className={classes.dialogContent}>
                <Divider />
                <form className={classes.formControl}>
                  <TextField
                    variant="standard"
                    className={classes.datePicker}
                    label="Date"
                    type="date"
                    name={keys.datePickUp}
                    value={values[keys.datePickUp]}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleValues}
                  />
                  <br /><br />
                  <TextField
                    variant="standard"
                    id="timeFrom"
                    label="From"
                    type="time"
                    className={classes.timeFrom}
                    name={keys.timeFrom}
                    value={values[keys.timeFrom]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900, // 15 min
                    }}
                    onChange={handleValues}
                  />
                  <TextField
                    variant="standard"
                    id="timeTo"
                    label="To"
                    type="time"
                    className={classes.timeTo}
                    name={keys.timeTo}
                    value={values[keys.timeTo]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900, // 15 min
                    }}
                    onChange={handleValues} 
                  />
                  <Divider className={classes.buttomDivider} />
                  <Button
                    disabled={(!values.datePickUp.length || !values.timeFrom.length || !values.timeTo.length)}
                    className={classes.submit}
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </form>
              </div>
            </div>
        </Dialog>
    </div>
  );
}

export default connect(null, { postModifyPickup })(PickUpPopUp);
