import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { URL_All } from '../common/Constants';
import { getDataWithAuth } from '../common/Frame';
import useToken from '../login/useToken';
import { deletedShipment, postModifyPickup } from '../actions';
import { logOut } from '../common/Frame';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
    '& p': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
  iconButton: {
    padding: theme.spacing(0.5),
  },
  dialogContent: {
    padding: theme.spacing(0),
  },
  contentDark: {
    margin: 'auto',
    paddingBottom: theme.spacing(1),
    background: '#ececec',
    width: '100%',
  },
  contentLight: {
    margin: 'auto',
    paddingBottom: theme.spacing(1),
  },
  contentTitle: {
    textAlign: 'center',
  },
  formGroup: {
    margin: 'auto',
    padding: theme.spacing(2),
    width: 'fit-content',
  },
  menuIconGrey: {
    color: grey[700],
  },
  formControl2: {
    margin: theme.spacing(3),
    width: '90%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  formControlThreeAndFour: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  timeFrom: {
    marginRight: theme.spacing(3),
  },
  timeTo: {
    marginLeft: theme.spacing(3),
  },
  buttomDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  servisInvoice: {
    '& input': {
      textAlign: 'center',
    },
  },
  dialogTitle: {
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  body: {
    textAlign: 'center',
  },
  marginBottomEightPx: {
    marginBottom: theme.spacing(1),
  },
}});

const GreyRadio = withStyles((props) => <Radio color="default" {...props} />, {
  root: {
    color: grey[400],
    '&.Mui-checked': {
      color: grey[600],
    },
  },
  checked: {},
});

const GreenRadio = withStyles((props) => <Radio color="default" {...props} />, {
  root: {
    color: green[400],
    '&.Mui-checked': {
      color: green[600],
    },
  },
  checked: {},
});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

function BurgerIcon(props) {
  const { classes } = useStyles();
  const { token } = useToken();
  const { shipment,
    setShipment,
    deletedShipment,
    postModifyPickup,
    users,
  } = props;
  const [open, setOpen] = useState(false);
  const [doubleCheck, setDoubleCheck] = useState(shipment.has_pickup);
  const [timeFrom, setTimeFrom] = useState(shipment.pickup_request.ready_time || '10:00');
  const [timeTo, setTimeTo] = useState(shipment.pickup_request.close_time || '12:00');
  const [datePickUp, setDatePickUp] = useState(shipment.pickup_date || null);
  const [servisInvoice, setServisInvoice] = useState(shipment.ikompar_invoice ? Math.trunc(shipment.ikompar_invoice.total_amount * 100)/100 : 0);
  const [checked, setChecked] = useState(false);

  const username = localStorage.getItem('username');
  if(!username) logOut();

  let currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? '' : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  const handleClickOpen = () => () => {
    setOpen(true);
    setDoubleCheck(shipment.has_pickup);
    setChecked(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Modify Pickup
  const modifyPickup = () => {
    if(datePickUp.length && timeFrom.length && timeTo.length) {
      const body = { date_pickup: datePickUp,
        time_from: timeFrom.slice(0,5),
        time_to: timeTo.slice(0,5),
      };
      postModifyPickup(token, shipment.id, body);
    }
    
    handleClose();
  };

  // Delete shipment and invoice 
  const handleDelete = () => {
    if(checked) {
      deletedShipment(token, shipment.id, currentUser);
    }

    handleClose();
  };
  
  // if click save (not validate / validate)
  function onValidateSave() {
    if (doubleCheck !== shipment.has_pickup) {
      getDataWithAuth(`${URL_All.apiShipment}/${shipment.id}/${doubleCheck ? 'pickup_done' : 'pickup_not_done'}/`, token)
        .then(json => setShipment(json))
        .catch(err => console.error(err));
    }

    handleClose();
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const ifShipmentValidate = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
        Is this shipment validate?
      </DialogTitle>
      <Divider />
      <form>
        <FormControl variant="standard" component="fieldset" className={classes.formControl2}>
          <RadioGroup
            aria-label="shipment_done"
            name="shipment_done"
            value={doubleCheck}
            onChange={() => setDoubleCheck(!doubleCheck)}
          >
            <FormControlLabel
              value={false}
              control={<GreyRadio />}
              label="Not validate"
            />
            <FormControlLabel
              value={true}
              control={<GreenRadio />}
              label="Validate"
            />
          </RadioGroup>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={onValidateSave}
          >
            Save
          </Button>
        </FormControl>
      </form>
    </div>
  );

  const ifServiceInvoice = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
        <b>Facture prestation</b><br />
        Entrer un montant à ajouter à la facture
      </DialogTitle>
      <Divider />
      <form className={classes.formControlThreeAndFour}>
        <TextField
          id="servis-invoice"
          className={classes.servisInvoice}
          variant="outlined"
          value={servisInvoice}
          onChange={(e) => setServisInvoice(e.target.value)}
        />
        <Divider className={classes.buttomDivider} />
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Save
        </Button>
      </form>
    </div>
  );

  const ifModifyDateTime = (
    <div className={classes.contentDark}>
      <DialogTitle id="customized-dialog-title" className={classes.contentTitle} onClose={handleClose}>
          <b>Modifier l’enlèvement</b><br />
          Confirmation: {shipment.pickup_request.confirmation_number && shipment.pickup_request.confirmation_number}
      </DialogTitle>
      <Divider />
      <form className={classes.formControlThreeAndFour}>
        <TextField
          variant="standard"
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="dateFrom"
          className={classes.datePicker}
          label="Date"
          type="date"
          value={datePickUp}
          InputLabelProps={{
              shrink: true,
          }}
          onChange={(e) => setDatePickUp(e.target.value)} />
        <br /><br />
        <TextField
          variant="standard"
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="timeFrom"
          label="From"
          type="time"
          className={classes.timeFrom}
          value={timeFrom}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 900, // 15 min
          }}
          onChange={(e) => setTimeFrom(e.target.value)} />
        <TextField
          variant="standard"
          disabled={!(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok')}
          id="timeTo"
          label="To"
          type="time"
          className={classes.timeTo}
          value={timeTo}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 900, // 15 min
          }}
          onChange={(e) => setTimeTo(e.target.value)} />
        <Divider className={classes.buttomDivider} />
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={modifyPickup}
        >
          Save
        </Button>
      </form>
    </div>
  );

  const deleteShipmentAndInvoice = (
    <div className={classes.contentLight}>
      <DialogTitle id="customized-dialog-title" className={`${classes.contentTitle} ${classes.marginBottomEightPx}`} onClose={handleClose}>
        Do you want to delete this shipment and invoice?
      </DialogTitle>
      <Divider />
      <form>
        <FormControl variant="standard" row className={classes.formControl2}>
          <FormControlLabel
            className={classes.marginBottomEightPx}
            control={<Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />}
            label="Delete"
          />
          <Button
            disabled={!checked}
            className={`${classes.button} ${classes.marginBottomEightPx}`}
            variant="outlined"
            color="secondary"
            onClick={handleDelete}
          >
            Save
          </Button>
        </FormControl>
      </form>
    </div>
  );

  return (
    <div>
      <IconButton className={classes.iconButton} onClick={handleClickOpen()} size="large">
        <MenuIcon
          fontSize="large"
          className={classes.menuIconGrey}
        />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitle}
          onClose={handleClose}
        >
          Que veux-tu faire, {username}?
        </DialogTitle>
        <Divider />
        <div className={classes.body}>
          {shipment.ikompar_invoice ? ifShipmentValidate : ifServiceInvoice}
          {(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok') && ifModifyDateTime}
          {(shipment.admin_status === 'pickup_error' || shipment.admin_status === 'ok') && deleteShipmentAndInvoice}
        </div>
      </Dialog>
    </div>
  );
}


const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps, { deletedShipment, postModifyPickup })(BurgerIcon);
