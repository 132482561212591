import '../css/TextEditor.css';
import '../../node_modules/draft-js/dist/Draft.css';
import React, { useEffect,
    useState,
    useContext,
    useRef
} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link as LinkHref, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editor,
  EditorState,
getDefaultKeyBinding,
RichUtils,
convertToRaw,
ContentState,
convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LinkButton from '@mui/material/Link';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { URL_All } from '../common/Constants';
import TaskManagementSort from './TaskManagementSort';
import useToken from '../login/useToken';
import { newadmindb } from '../common/Constants';
import { logOut } from '../common/Frame';
import { FrameTaskMngRow } from '../contexts/FrameTaskMngRow';
import TaskPopUpDelete from './TaskPopUpDelete';
import TaskForm from './TaskForm';
import { setRefresh } from '../actions';
import { convertHoursAndMinutes, convertDateToWithDots, } from '../common/functions';


const useStyles = makeStyles()((theme) => { return {
    root: {
        margin: theme.spacing(2),
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0 16px',
    },
    tableBodyRow: {
        borderBottom: '3px solid #e0e0e0',
        backgroundColor: '#e0e0e0',
        marginBottom: theme.spacing(2),

        "& .MuiTableCell-root": {
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            borderBottom: 'none',
        },

        '& .DraftEditor-editorContainer': {
            zIndex: '0',
        },
    },
    stickyHead: {
        position: 'sticky',
        top: theme.spacing(0),
        zIndex: 1,
        background: '#fafafa',
        padding: theme.spacing(1),
        borderBottom: 'solid 1px #e0e0e0',
    },
    display: {
        margin: 0,
        padding: theme.spacing(2),
    },
    orangeLight: {
        margin: 0,
        padding: theme.spacing(2),

        '& .RichEditor-root': {
          backgroundColor: "#ffe0b2",
          borderBottom: '1px solid #000',
          marginBottom: theme.spacing(.5),
          padding: theme.spacing(2),

          '& .RichEditor-editor': {
              marginBottom: theme.spacing(2),
          },
        },
    },
    orangeLight2: {
        backgroundColor: "#ffe0b2",
        padding: '4px 16px 16px 16px',
        margin: 0,
    },
    toRightSide: {
        display: 'flex',
        float: "right",
        alignItems: 'center',
    },
    trackingNumberAndButton: {
        float: "right",
        marginTop: theme.spacing(1),
        color: "#434343",

        '& .hide': {
            display: 'none',
        },
    },
    btnTrackingNumbers: {
        fontSize: "1.1em",
        fontWeight: "700",
        whiteSpace: 'pre-wrap',
    },
    moreInfo2: {
        paddingLeft: theme.spacing(3.9),
    },
    moreInfoButton: {
        marginLeft: theme.spacing(10),
    },
    noneTextTransform: {
        textTransform: "none",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 210,
    },
    toWho: {
        width: "80%",
    },
    taskStatus0: {
        textTransform: "none",
        background: "#ff9a9a",
    },
    taskStatus1: {
        textTransform: "none",
        background: "#ffff59",
    },
    taskStatus2: {
        textTransform: "none",
        background: "#94eb94",
    },
    headTask1Task2Info: {
        fontSize: "1.2em",
    },
    headFilterButtons: {
        "& .MuiButton-root": {
            textTransform: 'none',
        },
        borderBottom: 'none',
        paddingBottom: theme.spacing(0),
    },
    tableCellSearch: {
        borderBottom: 'none',
        paddingBottom: theme.spacing(0),
        textAlign: 'right',
    },
    marginRight3: {
        marginRight: theme.spacing(3),
    },
    formControl: {
        height: theme.spacing(10),
    },
    formControl2: {
        "& .MuiSelect-select[multiple]": {
            minHeight: 40,
            maxHeight: 100,
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: theme.spacing(0),
        },
        margin: 0,
        marginLeft: "5%",
    },
    formControl3: {
        width: "85%",
    },
    h3Head: {
        '& h3': {
            textAlign: 'center',
            marginBottom: 0,
            marginTop: theme.spacing(6),
            fontSize: '1.3em',
        },

        '& .MuiTableCell-root': {
            textAlign: 'center',
        },

        '& .MuiTableCell-body': {
            padding: theme.spacing(0),
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    btnUsernameClear: {
        color: 'red',
        textDecoration: 'underline',
    },
    inputButton: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
    },
    searchSort: {
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
    gridLG: {
        textAlign: 'right',
    },
    searchField: {
        width: 212,
    },
    paragraphNewLine: {
        whiteSpace: 'pre-wrap',
        paddingTop: theme.spacing(1),
    },
    nSuivi: {
        fontSize: '1.2rem'
    },
    companyName: {
        fontSize: '1.1em',
        fontWeight: '700',
        display: 'inline-block',
        marginTop: theme.spacing(1),
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    showMoreLessButton: {
      marginLeft: theme.spacing(1),
      textDecoration: 'underline',
      color: 'red',
    },
    displayNone: {
      display: 'none',
    },
    displayInlineBlock: {
      display: 'inline-block',
    },
    task: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        fontSize: '1.3rem',

        '& > p': {
            minHeight: '1em',
        },
    },
    biu: {
      marginLeft: theme.spacing(3),

      '& .RichEditor-controls': {
        '&>*': {
          fontSize: '1.4rem',
          width: 50,
        },
        
        '& .MuiButtonBase-root:not(last-child)': {
          marginRight: theme.spacing(1),
        },
      },
    },
    textUnderlined: {
      textDecoration: 'underline',
    },
    assignedTo: {
      whiteSpace: 'pre-wrap',
      fontSize: '1.1rem',
    },
    dueToAndIcon: {    
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.2rem',
  
      '& .MuiSvgIcon-root': {
        marginTop: theme.spacing(.25),
        color: theme.palette.grey[800],
      },
    },
    doneDelete: {
      display: 'inline-flex',
      gap: theme.spacing(1),
      marginRight: theme.spacing(4),
      
      '& .MuiFormControl-root': {
        justifyContent: 'center',
        marginLeft: theme.spacing(2),
      },
  
      '& .PrivateSwitchBase-root-128': {
        padding: '0 9px',
      },
  
      '& .MuiTypography-body1': {
        fontSize: '1rem',
      },
    },
    whoModified: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
    },
    tableBody: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
}});

const useStyles2 = makeStyles()((theme) => { return {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}});

function RichTextEditor(props) {
    const { editorState,
        onChange,
        editor
    } = props;
  
    useEffect(() => {
      editor.current.focus();
    }, []);
  
  
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        onChange(newState);
        return true;
      }
      return false;
    }
  
    const mapKeyToEditorCommand = (e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }
    
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  
    return (
      <div className="RichEditor-root">
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Write a task..."
            ref={editor}
          />
        </div>
      </div>
    );
}
  
// Custom overrides for "code" style.
const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };
  
  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
}
  
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {  
      return (
          <IconButton
              className={this.props.style}
              color={this.props.active ? 'secondary' : 'default'}
              onMouseDown={this.onToggle}
              size="large">
            {this.props.label}
          </IconButton>
      );
    }
}
  
  
var INLINE_STYLES = [
    {label: 'G', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'S', style: 'UNDERLINE'},
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RowTasks(props) {
    const focusEditInput = useRef(null);
    const { classes } = useStyles();
    const [isEditing, setEditing] = useState(false);
    const { id,
        trackingNbr,
        task,
        toUsers,
        taskStatus,
        createdBy,
        updatedBy,
        createdDate,
        updatedDate,
        dueDate,
        users,
        editTask,
        deleteTask,
        currentUser2,
        trackingInfo,
        files,
        sortByDate,
        isArchive,
        customerId
    } = props;
    const [newTask, setNewTask] = useState(task);
    const [personName, setPersonName] = useState(users.filter(t => toUsers.split(',').includes(t.id.toString())).map(item => item.username));
    const [dueTo, setDueTo] = useState(dueDate !== null ? dueDate : Math.trunc(new Date().getTime()/1000));
    const [isMoreInfo, setIsMoreInfo] = useState(false);
    const [personIDs, setPersonIDs] = useState([]);
    const [tooltipTitle, setTooltipTitle] = useState(false);
    const { setMySearch, customerListAll } = useContext(FrameTaskMngRow);
    const [showMoreFiles, setShowMoreFiles] = useState(false);

    useEffect(() => {
        setPersonName(users.filter(t => toUsers.split(',').includes(t.id.toString())).map(item => item.username));
    }, [users, toUsers]);
          
    const blocksFromHTML = convertFromHTML(task);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );
    const [editorState, setEditorState] = useState(EditorState.createWithContent(state));

    
    const handleEditorState = (editorState) => {
        setEditorState(editorState);

        var contentState = editorState.getCurrentContent();
        setNewTask(draftToHtml(convertToRaw(contentState)));
    };

    const toggleInlineStyle = (inlineStyle) => {
        handleEditorState(
        RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
        )
        );
    }

    const handleChange2 = (event) => {
        setPersonName(event.target.value);
        setPersonIDs(users.filter(r => event.target.value.includes(r.username)).map(item => item.id));
    };

    function handleChange3(e) {
        setDueTo(Math.trunc(new Date(e.target.value).getTime()/1000));
    }

    const handleTooltipClose = () => {
        setTooltipTitle(false);
    };

    function findCompanyName(params) {
        let company = customerListAll.filter(f => (f.id === params))[0];
        if(company === undefined) {
            return '';
        } else if(!company.is_company) {
            return company.users[0].user.username;
        } else if (company.company_name.trim() === '') {
            return company.users[0].user.username;
        } else {
            return company.company_name;
        }
    }

    const countFile = 7;
    const ifFilesExist = (files !== null) ? files.slice(0, -1).split(';') : [];

    const ifTaskEditing = (<Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className={classes.formControl}
    >
        <Grid item xs={1}>
            <p className={classes.editTaskLabel}><b>(Tâche):</b></p>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
            <FormControl variant="standard" className={classes.toWho}>
                <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                <Select
                    variant="standard"
                    labelId="users-mutiple-checkbox-label"
                    id="users-mutiple-checkbox2"
                    multiple
                    value={personName}
                    onChange={handleChange2}
                    input={<Input />}
                    renderValue={(selected) => selected.toString() !== '' ? selected.join(', ') : ''}
                    MenuProps={MenuProps}>
                    {users.sort(function(a, b) {
                            let x = a.username.toLowerCase();
                            let y = b.username.toLowerCase();
                            if(x < y) { return -1; }
                            if(x > y) { return 1; }
                            return 0;
                        }).map((item) => (
                            <MenuItem key={item.id} value={item.username}>
                                <Checkbox checked={personName.indexOf(item.username) > -1} />
                                <ListItemText primary={item.username} />
                            </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={5}>
            <form className={classes.dateTimeContainer} noValidate>
                <TextField
                    variant="standard"
                    id="datetime-task"
                    label="Due to:"
                    type="datetime-local"
                    className={classes.dateTime}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    defaultValue={((new Date(dueTo*1000)).getFullYear().toString()+'-'+(new Date(dueTo*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo*1000)).getDate().toString().padStart(2, '0')+'T'+(new Date(dueTo*1000)).getHours().toString().padStart(2, '0')+':'+(new Date(dueTo*1000)).getMinutes().toString().padStart(2, '0'))}
                    onChange={handleChange3} />
            </form>
        </Grid><br /><br />
    </Grid>);

    const editing = (<div className={classes.orangeLight}>
        <div>
            <span>{ifTaskEditing}
                <RichTextEditor
                    editorState={editorState}
                    onChange={handleEditorState}
                    editor={focusEditInput}
                />
            </span>
        </div>
        <span className={classes.actionButtons}>
            <span>
                <Button
                    className={classes.noneTextTransform}
                    size="medium"
                    onClick={() => setEditing(false)}
                >
                    Cancel
                </Button>
                <Button
                    className={classes.noneTextTransform}
                    size="medium"
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault();
                        editTask(id, taskStatus, newTask, personIDs.toString() !== '' ? personIDs.toString() : toUsers, dueTo);
                        setNewTask(newTask);
                        setEditing(false);
                        currentUser2();
                    }}
                >
                    Save
                </Button>
            </span>
            <span className={classes.biu}>
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
            </span>
        </span>
    </div>);

    const ifUpdate = <p>Dernière modification à: <span className={classes.textUnderlined}>{users.filter(filt => (filt.id === updatedBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === updatedBy))[0].username}</span>&nbsp;({convertDateToWithDots(updatedDate*1000)} {convertHoursAndMinutes(updatedDate*1000)})</p>;

    const ifSortByDate = convertHoursAndMinutes(createdDate*1000);

    const ifSortNotByDate = (convertDateToWithDots(createdDate*1000) + ' ' + convertHoursAndMinutes(createdDate*1000));

    const cleancombinedText = DOMPurify.sanitize(task, { USE_PROFILES: { html: true } });

    const display = (
        <div className={classes.display}>            
            <div className={classes.trackingNumberAndButton}>
                <div className={classes.nSuivi}>
                    {trackingNbr.length > 0 ? 'N° suivi: ' : ''}
                    <span className={classes.companyName}>{trackingNbr.length > 0 ? null
                    : findCompanyName(customerId)}</span>
                    <Tooltip
                        placement="top"
                        title={trackingNbr.split(/<br\/>/).map(trackingNumber => <p key={trackingNumber}>{trackingNumber}</p>)}
                    >
                        <Button
                            className={`${classes.btnTrackingNumbers} ${trackingNbr.length === 0 ? 'hide' : ''}`}
                            component={LinkHref}
                            to={`/archive?search=${trackingNbr.split('<')[0]}`}
                            onClick={() => setMySearch(trackingNbr.replace(/<br\/>/g, "\n"))}
                        >
                            {trackingNbr.split(/<br\/>|\r\n|\r|\n/g).length > 1 ? trackingNbr.split(/<br\/>|\r\n|\r|\n/g).slice(0, 1).concat(['...']).join(' ') : trackingNbr.replace(/<br\/>|\r\n|\r|\n/g, "\n")}
                        </Button>
                    </Tooltip>
                    <ClickAwayListener                        
                        onClickAway={handleTooltipClose}
                    >
                        <Tooltip
                            className={`${trackingNbr.length === 0 ? 'hide' : ''}`}
                            title="Copié"
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipTitle}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <Button onClick={() => {
                                    navigator.clipboard.writeText(trackingNbr.replace(/<br\/>|\r\n|\r|\n/g, " "));
                                    setTooltipTitle(true);
                            }}>
                                copie
                            </Button>
                        </Tooltip>
                    </ClickAwayListener>
                    <Button
                    disabled={isArchive === 1 ? false : true}
                    className={`${classes.moreInfoButton} ${trackingNbr.length === 0 ? 'hide' : ''}`}
                    onClick={() => setIsMoreInfo(!isMoreInfo)}
                    variant="contained"
                    color="secondary"
                    size="small"
                >{isMoreInfo ? 'Less Info' : 'More info'}</Button>
                </div>
            </div>
            <p className={classes.assignedTo}>Attribué à : <span className={classes.textUnderlined}>{personName.join(', ')}</span></p>
            <Divider />
            <div className={classes.orangeLight2}>
                <p className={classes.dueToAndIcon}><NotificationsActiveIcon />&nbsp;{convertDateToWithDots(dueDate*1000)} ({convertHoursAndMinutes(dueDate*1000)})</p>
                <div
                    className={classes.task}
                    dangerouslySetInnerHTML={{ __html: cleancombinedText}}
                />
                <div className={classes.doneDelete}>
                    <Button
                        className={(taskStatus === 1 | taskStatus === 2) ? classes.taskStatus0 : classes.noneTextTransform}
                        size="medium"
                        onClick={() => editTask(id, 1, task, toUsers)}
                    >
                        à faire
                    </Button>
                    <Button
                        className={taskStatus === 3 ? classes.taskStatus2 : classes.noneTextTransform}
                        size="medium"
                        onClick={() => editTask(id, 3, task, toUsers)}
                    >
                        terminé
                    </Button>
                </div>
                <span className={classes.toRightSide}>
                    <Tooltip
                        title="Edit"
                        placement="top"
                        onClick={() => setEditing(true)}
                    >
                        <IconButton size="large">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <TaskPopUpDelete
                        id={id}
                        task={task}
                        deleteTask={deleteTask}
                        files={files}
                    />
                </span>                
                {ifFilesExist.slice(0, countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file.length > 17 ? `${file.slice(0, 16)}...` : file}</a></Tooltip> </span>))}
                <LinkButton
                    className={ifFilesExist.length > countFile ? classes.showMoreLessButton : classes.displayNone}
                    component="button"
                    variant="body2"
                    onClick={() => {
                        setShowMoreFiles(!showMoreFiles);
                    }}
                    underline="hover">
                    {showMoreFiles ? 'Show Less' : 'Show More'}
                </LinkButton>
                <div colSpan={2} className={showMoreFiles ? classes.displayInlineBlock : classes.displayNone}>
                    {ifFilesExist.slice(countFile).map((file, i) => (<span key={i}><Tooltip title={file} placement="top"><a rel="noopener noreferrer" className={classes.marginRight2} href={`${newadmindb}/basic/web/uploads/${id}/${file}`} target="_blank">{file}</a></Tooltip> </span>))}                    
                </div>
            </div>
            <div>
                <Divider />
            </div>
            <div className={classes.whoModified}>
                <span>
                    {createdDate !== updatedDate ? ifUpdate : null}
                </span>
                <span>
                    <p>
                        Ajouté par: <span className={classes.textUnderlined}>{users.filter(filt => (filt.id === createdBy))[0] === undefined ? 'no username' : users.filter(filt => (filt.id === createdBy))[0].username} </span><span>({sortByDate === 'DateNew' || sortByDate === 'DateOld' ? ifSortByDate : ifSortNotByDate})</span>
                    </p>
                </span>
            </div>
        </div>
    );

    const moreInfo = (<TableCell>
        <Grid
            container
            className={trackingInfo.length > 0 ? classes.moreInfo2 : classes.moreInfo}
            spacing={1}
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <Grid item xs={6} className={classes.senderReceiver}>
            <span><b>{trackingInfo.length > 0 ? trackingInfo[0].pickup_contact : null}</b></span><br />
            <span><b>{trackingInfo.length > 0 ? trackingInfo[0].pickup_contact_name : null}</b></span><br />
            <span>{trackingInfo.length > 0 ? trackingInfo[0].pickup_address : null}</span><br />
            <span>{trackingInfo.length > 0 ? trackingInfo[0].pickup_phone : null}</span><br />
            <a href="mailto:">{trackingInfo.length > 0 ? trackingInfo[0].user : null}</a>
            </Grid>
            <Grid item xs={6} className={classes.senderReceiver}>
            <span><b>{trackingInfo.length > 0 ? trackingInfo[0].delivery_contact : null}</b></span><br />
            <span><b>{trackingInfo.length > 0 ? trackingInfo[0].delivery_contact_name : null}</b></span><br />
            <span>{trackingInfo.length > 0 ? trackingInfo[0].delivery_address : null}</span><br />
            <span>{trackingInfo.length > 0 ? trackingInfo[0].delivery_phone : null}</span><br />
            <a href="mailto:">{trackingInfo.length > 0 ? trackingInfo[0].user : null}</a>
            </Grid>
        </Grid>
    </TableCell>);

    return (<React.Fragment>
        <TableRow className={classes.tableBodyRow}>
            <TableCell colSpan={isMoreInfo ? 1 : 2}>{isEditing ? editing : display}</TableCell>
            {isMoreInfo ? moreInfo : null}
        </TableRow>
    </React.Fragment>);
}

function TablePaginationActions(props) {
    const { classes } = useStyles2();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
            size="large">
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
            size="large">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
            size="large">
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
            size="large">
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const SORTSELECT = {
    DateNew: URL_All.TaskManageDateNew,
    DateOld: URL_All.TaskManageDateOld,
    TrNbrNew: URL_All.TaskManageTrNbrNew,
    TrNbrOld: URL_All.TaskManageTrNbrOld,
    DueDateNew: URL_All.TaskManageDueDateNew,
    DueDateOld: URL_All.TaskManageDueDateOld,
    ByUsername: URL_All.TaskManageByUsername
};

// const wordKeyToSearch = ['tracking_nbr', 'to_users'];

// function customSearchTask(wordtosearch) {
//   let theWord = '';

//   for (const [key, value] of Object.entries(wordtosearch)) {
//     if(wordKeyToSearch.indexOf(key) !== -1){
//       theWord += value + ' ';
//     }
//   }

//   return theWord;
// }

const FILTER_TASKS = {
    All: fltr => fltr.is_task === 1,
    Task1Task2: fltr => (fltr.is_task === 1 & fltr.task_status !== 3)
};

function TaskManagement(props) {
    const { classes } = useStyles();
    const { users,
        refresh,
        setRefresh,
        statusValue
    } = props;
    const { token } = useToken();
    const location = useLocation();
    const [tasks,setTasks] = useState([]);
    const [tasksForUsername,setTasksForUsername] = useState([]);
    const [sortByDate, setSortByDate] = useState('DateNew');
    const [allButton, setAllButton] = useState('All');
    const [trackingInfo, setTrackingInfo] = useState([]);
    const [search, setSearch] = useState('');
    const [employee, setEmployee] = useState([]);
    const [titleName, setTitleName] = useState('Date Desc');
    let [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [countToDo, setCountToDo] = useState(0);
    const [countDone, setCountDone] = useState(0);

    useEffect(() => {
        let mounted = true;
        fetch(SORTSELECT[sortByDate])
            .then(res => res.json())
            .then(json => {
                if(mounted) {
                    sortByDate !== 'ByUsername' ? setTasks(json) : setTasksForUsername(json);
                }
            })
            .catch(err => {
                if(mounted) {
                    console.error(err);
                }
            })

        return () => mounted = false;
    }, [sortByDate, refresh]);

    useEffect(() => {
        setPage(0);
    }, [sortByDate, search, allButton]);

    useEffect(() => {
        if(!token) return logOut();
      
        let mounted = true;
        fetch(URL_All.TrackingList, {
            headers: {
                'authorization': `JWT ${token}`
            },
        })
            .then(response => {
                if (response.status === 403) {
                return logOut();
                } else if (response.status === 200) {
                return response.json();
                }
            })
            .then(json => {
                if(mounted) {
                    setTrackingInfo(json.results);
                }
            })
            .catch(err => {
                if(mounted) {
                    console.error(err);
                }
            })

        return () => mounted = false;
    }, [token, location]);

    useEffect(() => {        
        if(sortByDate === 'ByUsername') {
            setCountToDo(tasksForUsername.filter(w => (employee.length > 0 ? employee.includes(w.username) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(l => l.is_task === 1).filter(t => (t.task_status === 1 || t.task_status === 2)).length);

            setCountDone(tasksForUsername.filter(w => (employee.length > 0 ? employee.includes(w.username) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(t => (t.is_task === 1 & t.task_status === 3)).length);

        } else {
            setCountToDo(tasks.filter(w => (employee.length > 0 ? employee.includes(w.to_users) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(l => l.is_task === 1).filter(t => (t.task_status === 1 || t.task_status === 2)).length);

            setCountDone(tasks.filter(w => (employee.length > 0 ? employee.includes(w.to_users) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(t => (t.is_task === 1 & t.task_status === 3)).length);
        }
    }, [sortByDate, employee, search,tasksForUsername, tasks, refresh]);

    let currentUser = 0;
    const username = localStorage.getItem('username');
    if(!username) logOut();
    const currentUser2 = () => users.map(y => {
        if(y.username.toLowerCase() === username.toLowerCase()){
            return currentUser = y.id;
        } else {
            return null;
        }
    });
    currentUser2();

    function editTask(id, taskStatus, newTask, personName, dueTo) {
        fetch(`${URL_All.CommentTask}/${id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({due_date: dueTo, comment: newTask, to_users: personName, task_status: taskStatus, updated_by: currentUser})
        })
            .then(() => setRefresh(refresh + 1))
            .catch(error => console.error(error));
    };
    
    function editTask2(id, taskStatus, newTask, personName, dueTo, file) {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('task_status', taskStatus);
        fd.append('comment', newTask);
        fd.append('updated_by', currentUser);
        fd.append('to_users', personName);
        fd.append('due_date', dueTo);
        axios.put(`${URL_All.CommentTask}/${id}`, fd, {onUploadProgress: ProgressEvent => {
            console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
        }})
            .then(() => setRefresh(refresh + 1));
    }
      
    function deleteTask(id) {
        fetch(`${URL_All.CommentTask}/${id}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({is_actif: 0})
        })
            .then(() => setRefresh(refresh + 1))
            .catch(error => console.error(error));
    };

    const handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        setEmployee(value);
        setTitleName('Username');
        setSortByDate('ByUsername');
    };

    const optionsAxios = {
        headers: { 'enctype': 'multipart/form-data' },
        onUploadProgress: ProgressEvent => {
            console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
        }
    };

    const postTask = (task, file, toUsers, dueDate, isTask) => {
        const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);

        if(toUsers === null) {
            toUsers = 'undefined';
        }

        const fd = new FormData();
        if(file.length){
            for (let fN=0; fN<file.length; fN++) {
                fd.append(`file-${fN+1}`, file[fN]);
            }
        }
        fd.append('shipment_id',null);
        fd.append('customer_id', null);
        fd.append('comment', task);
        fd.append('created_by', currentUser);
        fd.append('to_users', toUsers);
        fd.append('due_date', dueDateConvert);
        fd.append('tracking_nbr', '');
        fd.append('is_task', isTask);
        axios.post(URL_All.CommentTask, fd, optionsAxios)
            .then(() => setRefresh(refresh + 1));
    }

    const today = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()).toString().padStart(2, '0');
  
    const yesterday = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()-1).toString().padStart(2, '0');

    const forNewsFeedByDate = tasks.filter(w => (employee.length > 0 ? employee.includes(w.to_users) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(FILTER_TASKS[allButton]).slice(rowsPerPage*page, (rowsPerPage*page+rowsPerPage)).reduce((myResult, item) => {
        let current_date = (new Date(item.created_at*1000).getFullYear()).toString()+'-'+(new Date(item.created_at*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(item.created_at*1000).getDate()).toString().padStart(2, '0');
        if (!myResult[current_date]) myResult[current_date] = [];
        myResult[current_date].push(item);
        return myResult;
    }, {});

    const newsFeedListByDate = Object.keys(forNewsFeedByDate).map(groupByDate1 => {
        return <React.Fragment key={groupByDate1}>
          <TableRow className={classes.h3Head}><TableCell colSpan={2}><h3>{groupByDate1 === today ? 'Aujourd’hui' : groupByDate1 === yesterday ? 'Hier' : convertDateToWithDots(groupByDate1)}</h3></TableCell></TableRow>
          {forNewsFeedByDate[groupByDate1].map(item1 => <RowTasks
                id={item1.id}
                customerId={item1.customer_id}
                trackingNbr={item1.tracking_nbr}
                task={item1.comment}
                toUsers={item1.to_users !== null & !Number.isInteger(item1.to_users) ? item1.to_users : Number.isInteger(item1.to_users) ? item1.to_users.toString() : ''}
                taskStatus={item1.task_status}
                createdBy={item1.created_by}
                updatedBy={item1.updated_by}
                createdDate={item1.created_at}
                updatedDate={item1.updated_at}
                dueDate={item1.due_date}
                editTask={editTask}
                editTask2={editTask2}
                deleteTask={deleteTask}
                currentUser2={currentUser2}
                trackingInfo={trackingInfo.filter(ft => (ft.id === item1.shipment_id))}
                users={users}
                files={item1.files}
                sortByDate={sortByDate}
                isArchive={statusValue[item1.shipment_id] !== undefined ? statusValue[item1.shipment_id].is_actif : 1}
                key={item1.id}
            /> )}
        </React.Fragment>;
    });
    
    const forNewsFeedDueDate = tasks.filter(w => (employee.length > 0 ? employee.includes(w.to_users) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(FILTER_TASKS[allButton]).slice(rowsPerPage*page, (rowsPerPage*page+rowsPerPage)).reduce((myResult, item) => {
        let current_date = (new Date(item.due_date*1000).getFullYear()).toString()+'-'+(new Date(item.due_date*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(item.due_date*1000).getDate()).toString().padStart(2, '0');
        if (!myResult[current_date]) myResult[current_date] = [];
        myResult[current_date].push(item);
        return myResult;
    }, {});

    const newsFeedListDueDate = Object.keys(forNewsFeedDueDate).map(groupByDate2 => {
        return <React.Fragment key={groupByDate2}>
          <TableRow className={classes.h3Head}><TableCell colSpan={2}><h3>{groupByDate2 === today ? 'Aujourd’hui' : groupByDate2 === yesterday ? 'Hier' : convertDateToWithDots(groupByDate2)}</h3></TableCell></TableRow>
          {forNewsFeedDueDate[groupByDate2].map(item2 => <RowTasks
              id={item2.id}
              customerId={item2.customer_id}
              trackingNbr={item2.tracking_nbr}
              task={item2.comment}
              toUsers={item2.to_users !== null & !Number.isInteger(item2.to_users) ? item2.to_users : Number.isInteger(item2.to_users) ? item2.to_users.toString() : ''}
              taskStatus={item2.task_status}
              createdBy={item2.created_by}
              updatedBy={item2.updated_by}
              createdDate={item2.created_at}
              updatedDate={item2.updated_at}
              dueDate={item2.due_date}
              editTask={editTask}
              editTask2={editTask2}
              deleteTask={deleteTask}
              currentUser2={currentUser2}
              trackingInfo={trackingInfo.filter(ft => (ft.id === item2.shipment_id))}
              users={users}
              files={item2.files}
              sortByDate={sortByDate}
              isArchive={statusValue[item2.shipment_id] !== undefined ? statusValue[item2.shipment_id].is_actif : 1}
              key={item2.id}
            />)}
        </React.Fragment>;
    });
    
    const forNewsFeedTrNbr = tasks.filter(w => (employee.length > 0 ? employee.includes(w.to_users) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(FILTER_TASKS[allButton]).slice(rowsPerPage*page, (rowsPerPage*page+rowsPerPage)).reduce((myResult, item) => {
        if (!myResult[item.tracking_nbr]) myResult[item.tracking_nbr] = [];
        myResult[item.tracking_nbr].push(item);
        return myResult;
    }, {});

    const newsFeedListTrNbr = Object.keys(forNewsFeedTrNbr).map(groupByDate3 => {
        return <React.Fragment key={groupByDate3}>
          <TableRow className={classes.h3Head}><TableCell colSpan={2}><h3>{groupByDate3 === '' ? 'No tracking number' : groupByDate3}</h3></TableCell></TableRow>
          {forNewsFeedTrNbr[groupByDate3].map(item3 => <RowTasks
              id={item3.id}
              customerId={item3.customer_id}
              trackingNbr={item3.tracking_nbr}
              task={item3.comment}
              toUsers={item3.to_users !== null & !Number.isInteger(item3.to_users) ? item3.to_users : Number.isInteger(item3.to_users) ? item3.to_users.toString() : ''}
              taskStatus={item3.task_status}
              createdBy={item3.created_by}
              updatedBy={item3.updated_by}
              createdDate={item3.created_at}
              updatedDate={item3.updated_at}
              dueDate={item3.due_date}
              editTask={editTask}
              editTask2={editTask2}
              deleteTask={deleteTask}
              currentUser2={currentUser2}
              trackingInfo={trackingInfo.filter(ft => (ft.id === item3.shipment_id))}
              users={users}
              files={item3.files}
              sortByDate={sortByDate}
              isArchive={statusValue[item3.shipment_id] !== undefined ? statusValue[item3.shipment_id].is_actif : 1}
              key={item3.id}
            />)}
        </React.Fragment>;
    });
    
    const forNewsFeedUsername = tasksForUsername.filter(w => (employee.length > 0 ? employee.includes(w.username) : true)).filter(u => Object.values(u).toString().toLowerCase().includes(search ? search.toLowerCase() : '')).filter(FILTER_TASKS[allButton]).slice(rowsPerPage*page, (rowsPerPage*page+rowsPerPage)).reduce((myResult, item) => {
        if (!myResult[item.username]) myResult[item.username] = [];
        myResult[item.username].push(item);
        return myResult;
    }, {});

    const newsFeedListUsername = Object.keys(forNewsFeedUsername).map(groupByDate4 => {
        return <React.Fragment key={groupByDate4}>
          <TableRow className={classes.h3Head}><TableCell colSpan={2}><h3>{groupByDate4 === '' ? 'No Username' : groupByDate4}</h3></TableCell></TableRow>
          {forNewsFeedUsername[groupByDate4].map(item4 => <RowTasks
              id={item4.id}
              customerId={item4.customer_id}
              trackingNbr={item4.tracking_nbr}
              task={item4.comment}
              toUsers={item4.to_users !== null & !Number.isInteger(item4.to_users) ? item4.to_users : Number.isInteger(item4.to_users) ? item4.to_users.toString() : ''}
              taskStatus={item4.task_status}
              createdBy={item4.created_by}
              updatedBy={item4.updated_by}
              createdDate={item4.created_at}
              updatedDate={item4.updated_at}
              dueDate={item4.due_date}
              editTask={editTask}
              editTask2={editTask2}
              deleteTask={deleteTask}
              currentUser2={currentUser2}
              trackingInfo={trackingInfo.filter(ft => (ft.id === item4.shipment_id))}
              users={users}
              files={item4.files}
              sortByDate={sortByDate}
              isArchive={statusValue[item4.shipment_id] !== undefined ? statusValue[item4.shipment_id].is_actif : 1}
              key={item4.id}
            />)}
        </React.Fragment>;
    });

    const countIfByUsername = ('Tâches à faire (' + countToDo + ') ' + (allButton === 'All' ? ('Tâches terminé (' + countDone + ')') : ''));

    const countIfNotByUsername = ('Tâches à faire (' + countToDo + ') ' + (allButton === 'All' ? ('Tâches terminé (' + countDone + ')') : ''));

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countToDo + countDone - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  return (
      <div className={classes.root}>
          <div className={classes.stickyHead}>
              <Grid container spacing={1}>
                  <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                          <TaskForm postCommentTask={postTask} />
                      </Grid>
                  </Grid>
                <Grid container item xs={4} spacing={1} className={classes.headerGrid}>
                    <Grid item xs={12} className={classes.headFilterButtons}>
                        <span>
                            <Button
                                className={classes.marginRight3}
                                size="medium"
                                variant={allButton === 'All' ? "contained" : "outlined"}
                                color={allButton === 'All' ? "secondary" : "v4default"}
                                onClick={() => setAllButton('All')}
                            >
                                Tout
                            </Button>
                            <Button
                                size="medium"
                                variant={allButton === 'Task1Task2' ? "contained" : "outlined"}
                                color={allButton === 'Task1Task2' ? "secondary" : "v4default"}
                                onClick={() => setAllButton('Task1Task2')}
                            >
                                à faire
                            </Button>
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        {sortByDate === 'ByUsername' ? countIfByUsername : countIfNotByUsername}
                    </Grid>
                    <Grid item xs={12} />
                </Grid>
                <Grid container item xs={3} spacing={1} className={classes.gridLG}>
                    <Grid item xs={12}>
                        <FormControl variant="standard" className={classes.formControl2}>
                            <InputLabel shrink htmlFor="select-multiple-native">
                                {employee.length > 0 ? `${employee.length} selected (Ctrl+Click)` : 'Select usernames:'}
                            </InputLabel>
                            <Select
                                variant="standard"
                                multiple
                                native
                                value={employee}
                                onChange={handleChangeMultiple}
                                inputProps={{
                                    id: 'select-multiple-native',
                                }}>
                                {users.sort(function(a, b) {
                                        let x = a.username.toLowerCase();
                                        let y = b.username.toLowerCase();
                                        if(x < y) {return -1;}
                                        if(x > y) {return 1;}
                                        return 0;
                                    }).map((name) => (
                                        <option key={name.id} value={name.username}>
                                            {name.username}
                                        </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={3} spacing={1} className={classes.searchSort}>
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                className={classes.searchField}
                                label="Rechercher…" 
                                variant="outlined"
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                      ev.preventDefault();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {search.length > 0 ? (<IconButton onClick={() => setSearch('')} size="large">
                                            <HighlightOffIcon size="small" />
                                        </IconButton>) : ''}
                                      </InputAdornment>
                                    ),
                                  }}
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <TaskManagementSort
                            sortByDate={sortByDate}
                            setSortByDate={setSortByDate}
                            titleName={titleName}
                            setTitleName={setTitleName}
                            setEmployee={setEmployee}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TablePagination
                            rowsPerPageOptions={[ 20 ]}
                            // rowsPerPageOptions={[15, 20, 30, 60]}
                            colSpan={3}
                            component="div"
                            count={(allButton === 'All') ? (countToDo + countDone) : countToDo}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Grid>
                </Grid>
                </Grid>
          </div>
          <TableContainer>
              <Table className={classes.table}>
                  <colgroup>
                    <col span="1" style={{width: "65%"}} />
                    <col span="1" style={{width: "35%"}} />
                  </colgroup>
                  <TableBody className={classes.tableBody}>
                    {sortByDate === 'DateNew' || sortByDate === 'DateOld' ? newsFeedListByDate
                    : sortByDate === 'TrNbrNew' || sortByDate === 'TrNbrOld' ? newsFeedListTrNbr
                    : sortByDate === 'DueDateNew' || sortByDate === 'DueDateOld' ? newsFeedListDueDate
                    : sortByDate === 'ByUsername' ? newsFeedListUsername
                    : null}
                    {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
                  </TableBody>
              </Table>
                <TablePagination
                    // rowsPerPageOptions={[ 20 ]}
                    rowsPerPageOptions={[15, 20, 30, 60]}
                    colSpan={3}
                    component="div"
                    count={(allButton === 'All') ? (countToDo + countDone) : countToDo}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
          </TableContainer>
      </div>
  );
}

const mapStateToProps = state => {
    return { users: state.users, refresh: state.refresh };
};

export default connect(mapStateToProps, { setRefresh })(TaskManagement);
