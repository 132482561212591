import React, { useState } from 'react';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      width: 500,
    },
    title: {
        maxWidth: 420,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles()((theme) => { return {
    root: {
        width: "100%",
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    grid: {
        alignSelf: 'flex-end',
    },
    gridTextField: {
        alignSelf: 'flex-end',
    },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle component="div" className={classes.root} {...other}>
          <Typography className={classes.title} variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="large">
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
    );
}, styles);

export default function PurchasePriceEdit(props) {
    const { classes } = useStyles();
    const { item, handlePurchasePriceEdit } = props;
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(item.price);

    function handleClickOpen() {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = event => {
        setPrice(event.target.value);
    };

    const handleSubmit = () => {
        handlePurchasePriceEdit(price);
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Edit" placement="top">
                <IconButton className={classes.editButton} onClick={handleClickOpen} size="large">
                    <EditIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
                className={classes.root}
            >
                <DialogTitle onClose={handleClose}>
                    Modification envoi: {item.trackingNumber}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={3}>
                            <FormControl variant="standard" className={classes.formControl}>
                                <Typography>
                                    Prix d’achat
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={7} className={classes.gridTextField}>
                            <TextField
                                variant="standard"
                                className={classes.textField}
                                autoFocus
                                autoComplete="off"
                                margin="dense"
                                id="edit-input"
                                type="number"
                                fullWidth
                                value={price}
                                onChange={handleChange} />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Valider
                    </Button>
                </DialogActions>
          </Dialog>
        </div>
    );
}
