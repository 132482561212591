import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';


const useStyles = makeStyles()((theme) => { return {
  headers1: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "grey",
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  showMoreInfo: {
    color: 'grey',
    backgroundColor: '#f9f9ff',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  displayNone: {
    display: 'none',
  },
}});

function HeaderTop(props) {
  const { classes } = useStyles();
  const { caSelectedShipment } = props;
  const [show,setShow] = useState(false);
  const [copyTitle, setCopyTitle] = useState(false);

  const handleClickShow = () => {
    setShow(!show);
  };

  const handleCopyTitle = () => {
    setCopyTitle(false);
  };

  return (
    <React.Fragment>
      <Typography className={classes.headers1}>
        <Button
          className={classes.buttonLowerCase}
          size="medium"
          onClick={handleClickShow}
        >
          N° suivi: {caSelectedShipment.tracking_numbers.replace(/<br\/>/g, ", ")}
        </Button>
        <ClickAwayListener
          onClickAway={handleCopyTitle}
        >
          <Tooltip
            title="Copié"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleCopyTitle}
            open={copyTitle}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button
              className={classes.colorGray}
              onClick={() => {
                navigator.clipboard.writeText(caSelectedShipment.tracking_numbers.replace(/<br\/>/g, " "));
                setCopyTitle(true);
            }}>
              copie
            </Button>
          </Tooltip>
        </ClickAwayListener>
        <br />
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className={show ? classes.showMoreInfo : classes.displayNone}
      >
        <Grid item xs={6}>
          <span><b>Expéditeur:</b></span><br />
          <span>{caSelectedShipment.pickup_contact}</span><br />
          {/* <span>{caSelectedShipment.pickup_address}</span><br /> */}
          <span>{caSelectedShipment.pickup_phone}</span><br />
          <span>{caSelectedShipment.pickup_email}</span>
        </Grid>
        <Grid item xs={6}>
          <span><b>Destinataire:</b></span><br />
          <span>{caSelectedShipment.delivery_contact}</span><br />
          {/* <span>{caSelectedShipment.delivery_address}</span><br /> */}
          <span>{caSelectedShipment.delivery_phone}</span><br />
          <span>{caSelectedShipment.delivery_email}</span>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return { caSelectedShipment: state.caSelectedShipment };
};

export default connect(mapStateToProps)(HeaderTop);
