import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { gapi } from 'gapi-script';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CalendarAddNewPopUp from './CalendarAddNewPopUp';
import CalendarShowSelected from './CalendarShowSelected';
import { convertDateToFrench } from '../common/functions';
import { CLIENT_ID, API_KEY, CALENDAR_ID } from '../apis/google';
import { setDashboardEventErrorMessage, setCalendarLoading } from '../actions';
// import CalendarList from './CalendarList';
// import CalendarSettings from './CalendarSettings';
import { getMonthFrench, getMonthFrenchAbbr } from '../common/constantsFR';
import { DAILY, WEEKLY, MONTHLY } from '../common/constantsEN';


const useStyles = makeStyles()((theme) => { return {
    cardContent: {
        border: '1px solid #ddd',
        overflow: 'hidden',
        paddingTop: 0,
        
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    contentHeader: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentBody: {
        overflowY: 'scroll',
        height: 250,
    },
    addEventButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tableBody: {
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },

        '& .MuiTableCell-body': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            '&:nth-of-type(2)': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'end',
            },
        },
    },
    displayNone: {
        display: 'none',
    },
    calendarLoading: {
        textAlign: 'left !important',
    },
    signOutButton: {
        display: 'flex',
        justifyContent: 'flex-start',

        '& button': {
            textTransform: 'none',
        },        
    },
    selectFormControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        display: 'flex',
        alignItems: 'flex-start',
        minWidth: 120,

        '& > div': {
            width: '100%',
        },
    },
}});

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const viewCalendar = [
    {
        name: DAILY
    },
    {
        name: WEEKLY
    },
    {
        name: MONTHLY
    },
];

function Calendar(props) {
    const { classes } = useStyles();
    const { eventErrorMessage,
        setDashboardEventErrorMessage,
        dashboardCalendarLoading,
        setCalendarLoading,
        // apiKeyAndClientId
    } = props;
    const [getEvents, setGetEvents] = useState([]);
    const [isSignedIn, setIsSignedIn] = useState(false);
    // const [selectedCalendarId, setSelectedCalendarId] = useState(CALENDAR_ID[0].calendarId);
    const [selectedCalendarId] = useState(CALENDAR_ID[0].calendarId);
    const [view, setView] = useState(viewCalendar[0].name);
    const [refreshGetEvent, setRefreshGetEvent] = useState(0);
    const [values, setValues] = useState({
        days: 0,
        weeks: 0,
        months: 0,
    });
    const [showDateValues, setShowDateValues] = useState({
        dateSetWeekMin: 0,
        dateSetWeekMax: 0,
        dateSetMonthMin: 0,
    });
    const [snackbarError, setSnackbarError] = useState({
        openSnackbarError: false,
        vertical: 'top',
        horizontal: 'center',
    });
    // const [listCalendars, setListCalendars] = useState([]);
    // const [runTest, setRunTest] = useState(false);
    
    const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

    // const API_KEY = runTest ? apiKeyAndClientId.apiKey : 'AIzaSyBFYZemGOxi4-h7qsMu-srBkOgyCTbq_hY';
    // const CLIENT_ID = runTest ? apiKeyAndClientId.clientId : '935634979232-9qqkqqoeeqt8j601e2jscglqhpgrhiit.apps.googleusercontent.com';
    
    const handleIsSignedIn = useCallback(() => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });

            gapi.client.load('calendar', 'v3', () => null);

            gapi.auth2.getAuthInstance().isSignedIn.listen(value => {
                setIsSignedIn(value);

                if(value) {
                    setRefreshGetEvent(oldKey => oldKey + 1);                
                }

                // if(value && runTest) {
                //     listCalendarIds();
                    
                // } else if(value && !runTest) {
                //     setRefreshGetEvent(oldKey => oldKey + 1);
                
                // }
            })
        });
    }, []); //runTest

    useEffect(() => {
        handleIsSignedIn();
    }, [handleIsSignedIn]);    
    
    
    const handleGetEvent = () => {
        let dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + values.days);
        setCalendarLoading(true);
    
        const dateSetHoursMin = dateNow.setHours(0, 0, 0);
        const dateSetHoursMax = dateNow.setHours(23, 59, 59);
    
        // Week change
        // let newDateForWeekly = new Date();
        const currentWeekDay = dateNow.getDay();
        const currentDate = dateNow.getDate();
    
        const dateSetWeekMin = new Date(dateSetHoursMin).setDate(currentDate - currentWeekDay + values.weeks * 7);
        const dateSetWeekMax = new Date(dateSetHoursMax).setDate(currentDate - currentWeekDay + 6 + values.weeks * 7);
    
        // Month change
        const currentMonth = dateNow.getMonth() + values.months;
        
        const dateSetDateMin = new Date(dateSetHoursMin).setDate(1);
        const dateSetMonthMin = new Date(dateSetDateMin).setMonth(currentMonth);
        
        const selectedMonth = new Date(dateSetMonthMin).getMonth();
        const selectedYear = new Date(dateSetMonthMin).getFullYear();
        const lastDayOfMonth = (new Date(selectedYear, selectedMonth + 1, 0)).getDate();
        const dateSetDateMax = new Date(dateSetMonthMin).setDate(lastDayOfMonth);
        const dateSetMonthMax = new Date(dateSetDateMax).setHours(23, 59, 59);

        let timeMin, timeMax;

        if (view === DAILY) {
            timeMin = new Date(dateSetHoursMin);
            timeMax = new Date(dateSetHoursMax);

        } else if (view === WEEKLY) {
            setShowDateValues({ ...showDateValues, dateSetWeekMin: dateSetWeekMin, dateSetWeekMax: dateSetWeekMax });

            timeMin = new Date(dateSetWeekMin);
            timeMax = new Date(dateSetWeekMax);

        } else if (view === MONTHLY) {
            setShowDateValues({ ...showDateValues, dateSetMonthMin: dateSetMonthMin });

            timeMin = new Date(dateSetMonthMin);
            timeMax = new Date(dateSetMonthMax);

        }

        // console.log('Min', timeMin);
        // console.log('Max', timeMax);

        gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });

            gapi.client.load('calendar', 'v3', () => {
                gapi.client.calendar.events.list({
                    'calendarId': selectedCalendarId,
                    'showDeleted': false,
                    'singleEvents': true,
                    'maxResults': 1000,
                    'orderBy': 'startTime',
                    'timeMin': timeMin.toISOString(),
                    'timeMax': timeMax.toISOString(),
                }).then(res => {
                    const events = res.result.items;
                    setGetEvents(events);
                    // console.log(events);
                    setCalendarLoading(false);
                });
            });
        });
    };

    const handleAddEvent = eventObj => {
        gapi.load('client:auth2', () => {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
        });

        gapi.client.load('calendar', 'v3', () => null);

        const request = gapi.client.calendar.events.insert({
            'calendarId': selectedCalendarId,
            'resource': eventObj,
        });

        request.execute(event => {
            if(event.code) {
                if(event.code === 400 && event.message === 'Invalid attendee email.') {
                    setDashboardEventErrorMessage('New event couldn\'t added: Guests error.');
                    handleClicksetSnackbarError({ vertical: 'top', horizontal: 'center' })();
                }
            }

            setRefreshGetEvent(oldKey => oldKey + 1);
        });
        });        
    };
    
    const handleEditEvent = (eventId, eventObj) => {
        gapi.load('client:auth2', () => {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
        });

        gapi.client.load('calendar', 'v3', () => null);

        gapi.auth2.getAuthInstance().signIn()
            .then(() => {
                const request = gapi.client.calendar.events.patch({
                    'calendarId': selectedCalendarId,
                    'eventId': eventId,
                    'resource': eventObj,
                });

                request.execute(event => {
                    if(event.code) {
                        if(event.code === 400 && event.message === 'Invalid attendee email.') {
                            setDashboardEventErrorMessage('Event couldn\'t edited: Guests error.');
                            handleClicksetSnackbarError({ vertical: 'top', horizontal: 'center' })();
                        }
                    }

                    setRefreshGetEvent(oldKey => oldKey + 1);
                });
            });
        });        
    };

    const handleDeleteEvent = eventId => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });

            gapi.client.load('calendar', 'v3', () => null);

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {
                    gapi.client.calendar.events.delete({
                        'calendarId': selectedCalendarId,
                        'eventId': eventId
                    })
                        .then(() => {
                            setRefreshGetEvent(oldKey => oldKey + 1);
                        });
                });
        });       
    };

    const handleSignIn = () => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });

            gapi.client.load('calendar', 'v3', () => null);

            gapi.auth2.getAuthInstance().signIn()
                .catch(error => console.error(error));
        });
    };

    // const handleSignOut = () => {
    //     gapi.load('client:auth2', () => {
    //         gapi.client.init({
    //             apiKey: API_KEY,
    //             clientId: CLIENT_ID,
    //             scope: SCOPES,
    //         });

    //         gapi.client.load('calendar', 'v3', () => null);

    //         gapi.auth2.getAuthInstance().signOut()
    //             .catch(error => console.error(error));
    //     });
    // };
    
    const showDate = () => {
        let desiredDay = new Date();
        desiredDay.setDate(desiredDay.getDate() + values.days);
        
        if(view === DAILY) {
            if(values.days === 0) {
                return 'Aujourd’hui';
            } else if(values.days === -1) {
                return 'Hier';
            } else {
                return convertDateToFrench(desiredDay);
            }
        } else if(view === WEEKLY) {
            return `${new Date(showDateValues.dateSetWeekMin).getDate()} ${getMonthFrenchAbbr(showDateValues.dateSetWeekMin)} - ${new Date(showDateValues.dateSetWeekMax).getDate()} ${getMonthFrenchAbbr(showDateValues.dateSetWeekMax)}`;
        } else if(view === MONTHLY) {
            return getMonthFrench(showDateValues.dateSetMonthMin);
        }
                                
    };
    
    // Add or Edit Google Events, error message: email error
    const { vertical, horizontal, openSnackbarError } = snackbarError;
    
    const handleClicksetSnackbarError = (newState) => () => {
        setSnackbarError({ openSnackbarError: true, ...newState });
    };
    
    const handleClosesetSnackbarError = () => {
        setSnackbarError({ ...snackbarError, openSnackbarError: false });
    };

    // const handleChangeCalendarId = event => {
    //     setSelectedCalendarId(event.target.value);
    //     setCalendarLoading(true);
    // };
    
    const handleChangeView = event => {
        setView(event.target.value);
        setCalendarLoading(true);
    };

    const handleChevronLeft = () => {
        if(view === DAILY) {
            setValues({ ...values, days: values.days - 1 });
            
        } else if(view === WEEKLY) {
            setValues({ ...values, weeks: values.weeks - 1 });
            
        } else if(view === MONTHLY) {
            setValues({ ...values, months: values.months - 1 });

        }
    };

    const ChandlehevronRight = () => {
        if(view === DAILY) {
            setValues({ ...values, days: values.days + 1 });
            
        } else if(view === WEEKLY) {
            setValues({ ...values, weeks: values.weeks + 1 });

        } else if(view === MONTHLY) {
            setValues({ ...values, months: values.months + 1 });

        }
    };

    useEffect(() => {
        handleGetEvent();
    }, [selectedCalendarId, view, refreshGetEvent, values]);

    const loading = (<TableRow>
        <TableCell colSpan={3} className={classes.calendarLoading}>
            Loading...
        </TableCell>
    </TableRow>);

    // function listCalendarIds() {
    //     setListCalendars([]);
    //     return gapi.client.calendar.calendarList.list({})
    //         .then(function(response) {
    //             setListCalendars(response.result.items);
    //                 // console.log(response.result.items);
    //                 // console.log(response.result.items[2].id);
    //                 // handleChangeCalendarId(response.result.items[2].id);
    //             },
    //             function(err) { console.error("Execute error", err); });
    // }

    // useEffect(() => {
    //     if(listCalendars.length) {
    //         if(listCalendars.length >= 3) {
    //             handleChangeCalendarId(listCalendars[2].id);
    //         } else {
    //             handleChangeCalendarId(listCalendars[0].id);
    //         }
    //     }
    // }, [listCalendars]);

    // useEffect(() => {
    //     if(apiKeyAndClientId.isSave && (apiKeyAndClientId.apiKey.length > 0) && (apiKeyAndClientId.clientId.length > 0)) {
    //         setRunTest(true);
    //         // handleIsSignedIn();
    //         listCalendarIds();
    //     } else {
    //         setRunTest(false);
    //     }
    // }, [apiKeyAndClientId]);

    return (
        <Card className="dashboard-box">
            <Grid container>
                <Grid item xs={4} className={classes.signOutButton}>
                    {/* <div>
                        <Button
                            // color="secondary"
                            variant="outlined"
                            onClick={handleSignOut}
                        >
                            Sign out (Google)
                        </Button>
                    </div> */}
                    {/* <CalendarSettings isSignedIn={isSignedIn} /> */}
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        className="box-title"
                        gutterBottom
                        variant="h5"
                        component="h2"
                    >
                        Agendas
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.addEventButton}>
                    <CalendarAddNewPopUp
                        handleAddEvent={handleAddEvent}
                        isSignedIn={isSignedIn}
                        dayDifference={values.days}
                    />
                </Grid>
            </Grid>
            <CardContent className={isSignedIn ? classes.displayNone : ''}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSignIn}
                >
                    Login Google
                </Button>
            </CardContent>
            <CardContent className={isSignedIn ? classes.cardContent : classes.displayNone}>
                <Grid container>
                    <Grid item xs={3}>
                        <FormControl variant="standard" className={classes.selectFormControl}>
                            {/* <Select
                                id="demo-simple-select"
                                value={selectedCalendarId}
                                onChange={handleChangeCalendarId}
                            >
                                {listCalendars.length ? listCalendars.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.summary}
                                    </MenuItem>
                                )) : CALENDAR_ID.map(item => (
                                    <MenuItem key={item.calendarId} value={item.calendarId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <CardActions className={classes.contentHeader}>
                            <IconButton 
                                size="small"
                                onClick={handleChevronLeft}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography
                            >
                                {showDate()}
                            </Typography>
                            <IconButton 
                                size="small"
                                onClick={ChandlehevronRight}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </CardActions>

                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="standard" className={classes.selectFormControl}>
                            <Select
                                variant="standard"
                                id="calendar-view"
                                value={view}
                                onChange={handleChangeView}>
                                {viewCalendar.map(item => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div className={classes.contentBody}>
                    <Table>
                        <colgroup>
                        <col span="1" style={{width: "35%"}} />
                        <col span="1" style={{width: "35%"}} />
                        <col span="1" style={{width: "30%"}} />
                        </colgroup>
                        <TableBody className={classes.tableBody}>
                            {dashboardCalendarLoading ? loading : getEvents.map((item, i) => (
                                <CalendarShowSelected
                                    key={i}
                                    item={item}
                                    handleEditEvent={handleEditEvent}
                                    handleDeleteEvent={handleDeleteEvent}
                                    isSignedIn={isSignedIn}
                                    days={values.days}
                                    view={view}
                                />
                            ))}
                            {/* <CalendarList /> */}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbarError}
                autoHideDuration={6000}
                onClose={handleClosesetSnackbarError}
                // message={eventErrorMessage}
                key={vertical + horizontal}
            >
                <Alert onClose={handleClosesetSnackbarError} severity="error">
                    {eventErrorMessage}
                </Alert>
            </Snackbar>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        eventErrorMessage: state.eventErrorMessage,
        dashboardCalendarLoading: state.dashboardCalendarLoading,
        // apiKeyAndClientId: state.apiKeyAndClientId
    };
};

export default connect(mapStateToProps, {
    setDashboardEventErrorMessage,
    setCalendarLoading
})(Calendar);
