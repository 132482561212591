import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setSnackbar } from '../actions';


function SnackbarMessage(props) {
  const { snackbar, setSnackbar } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const severity = {
    0: 'error',
    1: 'success',
  };

  const message = {
    0: 'An error occurred',
    1: 'Done!',
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={severity[snackbar.severity]}
        variant="filled"
        elevation={6}
      >
        {message[snackbar.severity]}
      </MuiAlert>
    </Snackbar>
  );
}

const mapStateToProps = state => {
  return { snackbar: state.snackbar };
};

export default connect(mapStateToProps, { setSnackbar })(SnackbarMessage);
