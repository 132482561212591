import React from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Tooltip from '@mui/material/Tooltip';
import CorrespondencesPopUp from './CorrespondencesPopUp';
import { convertDateToWithDash } from '../common/functions';


const useStyles = makeStyles()((theme) => { return {
  root: {
    margin: '0 4px 16px !important',
    '&:first-of-type': {
      marginTop: '0 !important',
    },
  },
  title: {
    margin: '0 0 4px 8px !important',
    fontSize: '1.1em',
  },
  grid: {
    border: '1px solid #999',
    backgroundColor: '#fefefe',
  },
  gridText: {
    padding: '4px !important',
    whiteSpace: 'pre-wrap',
    height: '4.5em',
    overflow: 'auto',

    '& .MuiTextField-root': {
      width: '100%',
    },
    
    '& .MuiInputBase-root': {
      fontSize: '1em',
    },
    
    '& fieldset': {
      border: 'none',
    },
  },
  gridButtons: {
    padding: '0 8px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'stretch',
  },
  button: {
    textTransform: 'none',
  },
  buttonFirst: {
    textTransform: 'none',
    backgroundColor: '#ffe0b2',
    '&:hover': {
      backgroundColor: '#ffe0b2',
    },
  },
  buttonSecond: {
    textTransform: 'none',
    backgroundColor: '#94eb94',
    '&:hover': {
      backgroundColor: '#94eb94',
    },
  },
  ifTextEmpty: {
    backgroundColor: '#eee',
  },
  helperButtons: {
    padding: theme.spacing(.5),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  dueToAndToWho: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& strong': {
      color: '#555',
    },
    '& span:first-of-type': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1.5),
    },
  },
  displayNone: {
    display: 'none',
  },
}});

const CorrespondenceSegment = (props) => {
  const { classes } = useStyles();
  const { title,
    goToTab,
    message,
    buttonOneText,
    buttonTwoText,
    taskStatus,
    selectedButton,
    taskId,
    navCurrent,
    navLength,
    navChange,
    dueDate,
    toWho,
    users
  } = props;

  const assignedTo = users.filter(t => toWho.split(',').includes(t.id.toString())).map(item => item.username).join(', ');
  
  const cleancombinedText = DOMPurify.sanitize(message, { USE_PROFILES: { html: true } });

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <CorrespondencesPopUp
          goToTab={goToTab}
          buttonTitle={title}
        />
        <IconButton
          disabled={navCurrent === 0}
          className={classes.helperButtons}
          onClick={() => navChange(navCurrent - 1)}
          size="large">
          <NavigateBeforeIcon />
        </IconButton>
        <CorrespondencesPopUp
          goToTab={goToTab}
          buttonTitle={<AssignmentIcon />}
          goToTask={taskId}
        />
        <IconButton
          disabled={navCurrent >= (navLength - 1)}
          className={classes.helperButtons}
          onClick={() => navChange(navCurrent + 1)}
          size="large">
          <NavigateNextIcon />
        </IconButton>
        <p className={taskStatus ? classes.dueToAndToWho : classes.displayNone}>
          <span><strong>Due to: </strong>{dueDate > 0 ? convertDateToWithDash(dueDate * 1000) : null}</span>
          <span>
            <strong>Attribué à: </strong>
            <Tooltip
              placement="top"
              title={assignedTo}
            >
              <span>{assignedTo}</span>
            </Tooltip>
          </span>
          
        </p>
      </div>
      <Grid container className={`${classes.grid} ${taskStatus ? '' : classes.ifTextEmpty}`}>
        <Grid item xs={9} className={classes.gridText}>
          <div
            className={classes.task}
            dangerouslySetInnerHTML={{ __html: cleancombinedText}}
          />
        </Grid>
        <Grid item xs={3} className={classes.gridButtons}>
          <Button
            disabled={!taskStatus}
            className={taskStatus !== 3 ? classes.buttonFirst : classes.button}
            variant={taskStatus !== 3 ? 'contained' : 'outlined'}
          >
            {buttonOneText}
          </Button>
          <Button
            disabled={!taskStatus}
            className={taskStatus === 3 ? classes.buttonSecond : classes.button}
            variant={taskStatus === 3 ? 'contained' : 'outlined'}
            onClick={() => {
              if(taskStatus !== 3) {
                navChange(0);
                selectedButton(taskId, 3);
              }
            }}
          >
            {buttonTwoText}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return { users: state.users };
};

export default connect(mapStateToProps)(CorrespondenceSegment);
