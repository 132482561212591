import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { convertDateToWithDash } from '../common/functions';
import OtherItemForm from './InvoicingOtherItemForm';
import TranspItemForm from './InvoicingTranspItemForm';
import { setCAButtons,
  getInvoiceByShipId,
  setInvoiceByShipId,
  postCreateInvoice,
  setInvoiceCreateSuccess,
} from '../actions';
import { customerAccountPath } from '../common/paths';
import useToken from '../login/useToken';
import { createOverbillingKeys } from '../invoice-overcharging/Overcharging';
import { CustomerAccountContext } from '../contexts/CustomerAccountContext';


const useStyles = makeStyles()((theme) => { return {
  invoicing: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    background: '#F7F7F7',
    minHeight: '60vh',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  headerMain: {
    color: 'gray',
  },
  headerInvoice: {
    marginBottom: theme.spacing(2),
    display: 'inline-flex',
    gap: 5,
  },
  gridDatePicker: {
    marginTop: theme.spacing(2),
  },
  textFieldDatePicker: {
    width: 200,
    textAlign: 'center',
  },
  dropDownMenu: {
    width: 300,
  },
  gridInvoices: {
    marginTop: theme.spacing(4),
  },
  itemGroup: {
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  description: {
    width: 400,
  },
  addItem: {
    display: 'flex',
  },
  addInvoiceTitle: {
    marginBottom: theme.spacing(1),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  gridActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },
  priceCorrection: {
    padding: theme.spacing(2),
    border: '1px solid #e5d2d2',
    width: 530,
  },
  priceCorrectionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  correctionAction: {
    textAlign: 'center',
  },
  deleteLater: {
    marginBottom: theme.spacing(5),
  },
  fontItalic: {
    fontStyle: 'italic',
  },
}});

const TypographyBody = withStyles(Typography, (theme) => ({
  root: {
    fontSize: '1em',
  },
}));

function Invoicing(props) {
  const { token } = useToken();
  const history = useHistory();
  const { classes } = useStyles();
  const { customer } = useContext(CustomerAccountContext);
  const { setCAButtons,
    caButtons,
    invoiceCurrent,
    invoiceShipIdLoading,
    getInvoiceByShipId,
    setInvoiceByShipId,
    invoiceCreateLoading,
    postCreateInvoice,
    invoiceCreateSuccess,
    setInvoiceCreateSuccess,
    caInvoicing,
    invoiceByShipId,
  } = props;
  const newDate = new Date();
  const dateWithDash = convertDateToWithDash(newDate);
  const valuesKeys = ['date', 'discountDesc', 'discount'];
  const [values, setValues] = useState({
    [valuesKeys[0]]: dateWithDash,    [valuesKeys[1]]: '',
    [valuesKeys[2]]: '',
  });
  const [itemsArr, setItemsArr] = useState([1]);
  const defaultOther = {
    [createOverbillingKeys[0]]: 1,
    [createOverbillingKeys[1]]: '',
    [createOverbillingKeys[2]]: '',
    [createOverbillingKeys[3]]: '',
  };

  const defaultTransp = {
    [createOverbillingKeys[0]]: 1,
    [createOverbillingKeys[1]]: '',
    [createOverbillingKeys[2]]: '',
    [createOverbillingKeys[3]]: '',
    [createOverbillingKeys[4]]: '',
    [createOverbillingKeys[5]]: '',
  };
  const [createOverbilling, setCreateOverbilling] = useState({
    [itemsArr[0]]: caButtons.transport ? defaultTransp : defaultOther,
  });
  const [trackNumHas,setTrackNumHas] = useState(-1);
  const [insuranceHas,setInsuranceHas] = useState(-1);

  useEffect(() => {
    if(!caButtons.invoiceCreate && !caButtons.invoiceOffer) {
      history.push(customerAccountPath);
    }

    return () => {
      setCAButtons({ invoiceCreate: false, invoiceOffer: false });
    };
  }, [setCAButtons]);


  useEffect(() => {
    if(Object.keys(caInvoicing.currentUser).length) {
      getInvoiceByShipId(token, caInvoicing.currentUser.user.id, true);
    }

    return () => {
      setInvoiceByShipId({});
    };
  }, [token, invoiceCurrent, caInvoicing]);

  const handleValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleAddItem = () => {
    setItemsArr([...itemsArr, itemsArr[itemsArr.length-1] + 1]);
    setCreateOverbilling({
      ...createOverbilling,
      [itemsArr[itemsArr.length-1] + 1]: caButtons.transport ? defaultTransp : defaultOther,
    });
  };

  const handleRemove = ind => () => {
    if(caButtons.transport && createOverbilling[ind][createOverbillingKeys[4]].length) {
      setTrackNumHas(-1);
    }
    if(caButtons.transport && createOverbilling[ind][createOverbillingKeys[5]].length) {
      setInsuranceHas(-1);
    }
    const removeFromArr = itemsArr.filter(flt => (flt !== ind));
    setItemsArr(removeFromArr);
    
    delete createOverbilling[ind];
  };

  const handleCancel = () => {
    history.push(customerAccountPath);    
  };
  
  const handleChangeCreate = arrIndex => (e) => {
    if(e.target.name === createOverbillingKeys[4] && e.target.value.trim().length) {
      setTrackNumHas(arrIndex);
    }

    if(e.target.name === createOverbillingKeys[5] && e.target.value.trim().length) {
      setInsuranceHas(arrIndex);
    }
    
    if(arrIndex === trackNumHas && !e.target.value.trim().length) {
      setTrackNumHas(-1);
    }
    
    if(arrIndex === insuranceHas && !e.target.value.trim().length) {
      setInsuranceHas(-1);
    }

    setCreateOverbilling({ ...createOverbilling,
      [arrIndex]: {
        ...createOverbilling[arrIndex],
        [e.target.name]: (e.target.name === createOverbillingKeys[0]) ? Number(e.target.value) : e.target.value.trim()
    }});
  };
  
  const handleCreateInvoice = () => {
    const discountAmount = values[valuesKeys[2]].length ? Number(values[valuesKeys[2]]) : 0;
    const articles = Object.keys(createOverbilling).length ? Object.values(createOverbilling) : [];

    const body = {
      date: values[valuesKeys[0]],

      articles: articles,

      discount_description: values[valuesKeys[1]],
      discount_amount: discountAmount,
    };

    postCreateInvoice(token, caInvoicing.currentUser.user.id, body, true);
  };

  useEffect(() => {
    if(invoiceCreateSuccess) {
      handleCancel();
      setInvoiceCreateSuccess(false);
    }
  }, [invoiceCreateSuccess]);


  return (
    <div className={classes.invoicing}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={7} container>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.headerMain}>
              Facturation ({caButtons.transport ? 'Transport' : 'Autre'})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.headerInvoice}>
              Invoice n° : {invoiceShipIdLoading ? <div className={classes.fontItalic}><CircularProgress size="1em" /> Loading...</div> : <strong>…</strong>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TypographyBody>
              Client (n°client): <strong>{invoiceByShipId.customer_nr}</strong>
            </TypographyBody>
            <TypographyBody>
              Entreprise: <strong>{invoiceByShipId.customer_company}</strong>
            </TypographyBody>
            <TypographyBody>
              Prénom Nom: <strong>{invoiceByShipId.customer_contact_name}</strong>
            </TypographyBody>
            <TypographyBody>
              Adresse: <strong>{invoiceByShipId.customer_address}</strong>
            </TypographyBody>
            <TypographyBody>
              NPA Localité: <strong>{invoiceByShipId.customer_zip_code}</strong>
            </TypographyBody>
            <TypographyBody>
              Pays: <strong>{invoiceByShipId.customer_country}</strong>
            </TypographyBody>
            <TypographyBody>
              Nom d’utilisateur: <strong>{invoiceByShipId.customer_username}</strong>
            </TypographyBody>
          </Grid>
          <Grid item xs={12} className={classes.gridDatePicker}>
            <TextField
              variant="standard"
              className={classes.textFieldDatePicker}
              type="date"
              name={valuesKeys[0]}
              InputLabelProps={{
                shrink: true,
              }}
              value={values[valuesKeys[0]]}
              onChange={handleValues} />
          </Grid>
        </Grid>

        <Grid item xs={4} />
        
        <Grid item xs={1} />

        <Grid item xs={12} className={classes.gridInvoices}>
          <div className={classes.itemGroup}>
            {caButtons.transport ? itemsArr.map((_, i) => <TranspItemForm
                key={_}
                orderNumber={_}
                index={i}
                selectOptions={Object.keys(invoiceByShipId).length ? invoiceByShipId.articles_ref : []}
                handleChangeCreate={handleChangeCreate}
                selectValue={createOverbilling[_][createOverbillingKeys[0]]}
                handleRemove={handleRemove}
                trackNumHas={trackNumHas}
                insuranceHas={insuranceHas}
              />)
            : itemsArr.map((_, i) => <OtherItemForm
                key={_}
                orderNumber={_}
                index={i}
                selectOptions={Object.keys(invoiceByShipId).length ? invoiceByShipId.articles_ref : []}
                handleChangeCreate={handleChangeCreate}
                selectValue={createOverbilling[_][createOverbillingKeys[0]]}
                handleRemove={handleRemove}
                trackNumHas={trackNumHas}
                insuranceHas={insuranceHas}
            />)}

            <div className={classes.addItem}>
              <IconButton onClick={handleAddItem} size="large">
                <AddCircleIcon />
              </IconButton>
              <p>Ajouter un article</p>
            </div>
          </div>

          <div className={classes.addInvoiceGroup}>
            <Typography className={classes.addInvoiceTitle}>Ajouter un rabais</Typography>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                size="small"
                className={classes.description}
                variant="outlined"
                label="Description pour client"
                name={valuesKeys[1]}
                value={values[valuesKeys[1]]}
                onChange={handleValues}
              />

              <TextField
                size="small"
                variant="outlined"
                label="Rabais (Ex. : 32.20)"
                type="number"
                name={valuesKeys[2]}
                value={values[valuesKeys[2]]}
                onChange={handleValues}
              />
            </form>
          </div>
        </Grid>
        
        <Grid item xs={12} className={classes.gridActions}>
          <Button
            disabled={invoiceShipIdLoading || invoiceCreateLoading || !Object.keys(customer).length}
            className={classes.buttonLowerCase}
            variant="contained"
            color="primary"
            onClick={handleCreateInvoice}
          >
            {(invoiceShipIdLoading || invoiceCreateLoading) ? <CircularProgress size="2em" /> : 'Créer facture'}
          </Button>
          <Button
            disabled={invoiceCreateLoading}
            className={classes.buttonLowerCase}
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            {invoiceCreateLoading ? <CircularProgress color="secondary" size="2em" /> : 'Cancel and Go Back'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    caButtons: state.caButtons,
    invoiceCurrent: state.invoiceCurrent,
    invoiceShipIdLoading: state.invoiceShipIdLoading,
    invoiceCreateLoading: state.invoiceCreateLoading,
    invoiceCreateSuccess: state.invoiceCreateSuccess,
    caInvoicing: state.caInvoicing,
    invoiceByShipId: state.invoiceByShipId,
  };
};

export default connect(mapStateToProps, {
  setCAButtons,
  getInvoiceByShipId,
  setInvoiceByShipId,
  postCreateInvoice,
  setInvoiceCreateSuccess,
})(Invoicing);
