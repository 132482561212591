import React from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Pallet } from '../common/icons';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles()((theme) => { return {
    paddingLeftZero: {
        paddingLeft: theme.spacing(0),
    },
    flipHorizontal: {
      transform: 'scaleX(-1)',
    },
}});

const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle component="div" className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

function PackageIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
      </SvgIcon>
    );
}

export default function PopUpPackage(props) {
    const { classes } = useStyles();
    const { item } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

  return (
    <div>
        <Tooltip
            className={classes.paddingLeftZero}
            title={<div>
                {item.package_group.packages.map((pkg) => (<div key={pkg.id}>{`${pkg.length} x ${pkg.width} x ${pkg.height} cm - ${pkg.weight}kg`}<br /></div>))}
                <br />
                {item.is_ddp ? 'DDP' : 'DAP'}
                <br /><hr /><br />
                {item.package_group.goods.map((i, e) => (<div key={e}>- {i.quantity} {i.description}<br /></div>))}
                <br />Total CHF: {item.package_group.customs_total_value} -
                contenu</div>}
            placement="left-end"
            >
            <IconButton onClick={handleClickOpen} size="large">
                <Typography >x{item.package_count}</Typography >
                {item.is_doc ? (<MailOutlineIcon fontSize="large" />) : 
                item.is_pallet ? <Pallet fontSize="large" />
                : (<PackageIcon className={classes.flipHorizontal} fontSize="large" />)}
            </IconButton>
        </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
    >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Dimensions, Weight, DDP/DAP, Goods, {`&`} Total:
        </DialogTitle>
        <DialogContent dividers>
            <div>
                {item.package_group.packages.map((pkg) => (<div key={pkg.id}>{`${pkg.length} x ${pkg.width} x ${pkg.height} cm - ${pkg.weight}kg`}<br /></div>))}
                <br /><hr style={{ width: "60%", float: "left" }} /><br />
                {item.is_ddp ? 'DDP' : 'DAP'}
                <br /><hr style={{ width: "60%", float: "left" }} /><br />
                {item.package_group.goods.map((i, e) => (<div key={e}>- {i.quantity} {i.description}<br /></div>))}
                <br />Total CHF: {item.package_group.customs_total_value} -
                contenu
            </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
