import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { setIssuesSearchInput, setTransportFilters } from '../actions';


const useStyles = makeStyles()((theme) => { return {
    headText: {
      '& .MuiTypography-root': {
          color: 'gray',
          fontSize: theme.spacing(6),
          whiteSpace: 'nowrap',
      },
    },
    search: {
        width: '90%',
        "& .MuiButtonBase-root": {
            padding: 0,
            color: "gray",
        },
    },
    buttonLowerCase: {
      textTransform: "none",
    },
    issuesCount: {
        '&.MuiGrid-item': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    filterButtons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignSelf: 'center',
    },
}});

function IssuesHeader(props) {
    const { classes } = useStyles();
    const { issuesSearchInput,
        transportFilters,
        setIssuesSearchInput,
        setTransportFilters,
        issuesCount
    } = props;

    return (
        <div>
            <Grid 
                container
                spacing={1}
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
            >
                <Grid item xs={3} className={classes.headText}>
                    <Typography>
                        CAS LITIGIEUX
                    </Typography>
                </Grid>

                <Grid item xs={6} className={classes.filterButtons}>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedFedEx ? "contained" : "outlined"}
                        color={transportFilters.isClickedFedEx ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedFedEx: !transportFilters.isClickedFedEx })}
                    >
                        FedEx
                    </Button>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedUPS ? "contained" : "outlined"}
                        color={transportFilters.isClickedUPS ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedUPS: !transportFilters.isClickedUPS })}
                    >
                        UPS
                    </Button>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedTNT ? "contained" : "outlined"}
                        color={transportFilters.isClickedTNT ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedTNT: !transportFilters.isClickedTNT })}
                    >
                        TNT
                    </Button>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedAramex ? "contained" : "outlined"}
                        color={transportFilters.isClickedAramex ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedAramex: !transportFilters.isClickedAramex })}
                    >
                        Aramex
                    </Button>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedSpring ? "contained" : "outlined"}
                        color={transportFilters.isClickedSpring ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedSpring: !transportFilters.isClickedSpring })}
                    >
                        Spring
                    </Button>                    
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedTransnat ? "contained" : "outlined"}
                        color={transportFilters.isClickedTransnat ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedTransnat: !transportFilters.isClickedTransnat })}
                    >
                        Transnat
                    </Button>
                    <Button
                        className={classes.buttonLowerCase}
                        size="small"
                        variant={transportFilters.isClickedFretcargo ? "contained" : "outlined"}
                        color={transportFilters.isClickedFretcargo ? "secondary" : "v4default"}
                        onClick={() => setTransportFilters({ ...transportFilters, isClickedFretcargo: !transportFilters.isClickedFretcargo })}
                    >
                        Fretcargo
                    </Button>
                </Grid>

                <Grid item xs={1} />
                        
                <Grid item xs={2}>
                    <Grid 
                        container 
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    size="small" 
                                    id="outlined-basic" 
                                    label="Rechercher…" 
                                    variant="outlined" 
                                    className={classes.search}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            ev.preventDefault();
                                            if(issuesSearchInput) {
                                                setIssuesSearchInput(issuesSearchInput);
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {issuesSearchInput.length > 0 ? (<IconButton onClick={() => setIssuesSearchInput('')} size="large">
                                                <HighlightOffIcon size="small" />
                                            </IconButton>) : ''}
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={issuesSearchInput}
                                    onChange={e => setIssuesSearchInput(e.target.value)}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} className={classes.issuesCount}>
                            Issues: {issuesCount}
                        </Grid>         
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        issuesSearchInput: state.issuesSearchInput,
        transportFilters: state.issuesTransportFilters,
        issuesCount: state.issuesCount
    };
};

export default connect(mapStateToProps, { setIssuesSearchInput, setTransportFilters })(IssuesHeader);
