import { CHAT_UNREAD } from '../actions/types';


const chatUnread = (state = [], action) => {
  switch(action.type) {
    case CHAT_UNREAD:
      return action.payload;
    default:
      return state;
  }
};


export default chatUnread;
