// All Invoice Pages
export const invoicesExpedismartPath = '/invoices-expedismart';
export const overchargingPath = '/overcharging';
export const taxPath = '/tax';


// Customer-Account Page 
export const customerAccountPath = '/customer-account';
export const caInvoicingOtherPath = '/invoicing-other';
export const caInvoicingTranspPath = '/invoicing-transportation';
