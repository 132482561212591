import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TaskForm from '../task/TaskForm';
import TaskOpenSelected from './TaskOpenSelected';
import { URL_All } from '../common/Constants';
import { setRefresh, getTasksForDashboard, getTaskCount } from '../actions';
import { convertDateToWithDash, convertDateToFrench } from '../common/functions';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
    cardContent: {
        border: '1px solid #ddd',
        overflow: 'hidden',
        paddingTop: 0,
        
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    contentHeader: {
        justifyContent: 'center',
        alignItems: 'center',

        '& .MuiGrid-item:first-of-type': {
            textAlign: 'start',
        },
        '& .MuiGrid-item:last-child': {
            textAlign: 'end',
        },
    },
    contentBody: {
        overflowY: 'scroll',
        height: 250,
    },
    addEventButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tableBody: {
        '& .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },

        '& .MuiTableCell-body': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            
            '&:nth-of-type(2)': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'end',
            },
        },
    },
    subtitleCenter: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subtitleCenterDisabled: {
        color: '#999',
        '& .MuiSvgIcon-root': {
            color: '#999',
    },
    },
    textTransformNone: {
        textTransform: 'none',
    },
}});

// const someData = [
//     {
//         task: 'Seoul airway',
//         toWho: 'ikompar',
//         dueTime: '09:00',
//     },
//     {
//         task: 'Review',
//         toWho: 'Reda',
//         dueTime: '14:00',
//     },
//     {
//         task: 'Request photo',
//         toWho: 'Jehona',
//         dueTime: '15:00',
//     },
//     {
//         task: 'Call back customer',
//         toWho: 'Hannah',
//         dueTime: '15:50',
//     },
//     {
//         task: 'Ask again',
//         toWho: 'Alex',
//         dueTime: '16:10',
//     },
//     {
//         task: 'Return',
//         toWho: 'Micheal',
//         dueTime: '16:30',
//     },
// ];

function Tasks(props) {
    const { classes } = useStyles();
    const { users,
        refresh,
        setRefresh,
        getTasksForDashboard,
        dashboardGetTasks,
        getTaskCount,
        taskCount
    } = props;
    const [isClickedPast, setIsClickedPast] = useState(false);
    const [isClickedFuture, setIsClickedFuture] = useState(false);
    const [daysForTask, setDaysForTask] = useState(0);

    let currentUser = 0;
    const username = localStorage.getItem('username');
    if(!username) logOut();
    const currentUser2 = () => users.map(y => {
        if(y.username.toLowerCase() === username.toLowerCase()){
            return currentUser = y.id;
        } else {
            return null;
        }
    });
    currentUser2();

    useEffect(() => {
        if(users.length) {
            getTaskCount(users);
        }
    }, [users, getTaskCount, refresh]);

    useEffect(() => {
        if(currentUser > 0) {
            if(isClickedPast) {
                return getTasksForDashboard(`get_tasks_past?user_id=${currentUser}`);
            } else if(isClickedFuture) {
                return getTasksForDashboard(`get_tasks_future?user_id=${currentUser}`);
            } else {
                let desiredDay = new Date();
                desiredDay.setDate(desiredDay.getDate() + daysForTask);
        
                getTasksForDashboard(`get_tasks_day?day=${convertDateToWithDash(desiredDay)}&user_id=${currentUser}`);
            }
        }
    }, [refresh, currentUser]);

    const optionsAxios = {
        headers: { 'enctype': 'multipart/form-data' },
        onUploadProgress: ProgressEvent => {
            console.log(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
        }
    };

    const getTasksByDate = days => {
        let desiredDay = new Date();
        desiredDay.setDate(desiredDay.getDate() + days);

        getTasksForDashboard(`get_tasks_day?day=${convertDateToWithDash(desiredDay)}&user_id=${currentUser}`);
    };
    
    const selectedDayName = () => {
        let desiredDay = new Date();
        desiredDay.setDate(desiredDay.getDate() + daysForTask);

        if(daysForTask === 0) {
            return 'Aujourd’hui';
        } else if(daysForTask === -1) {
            return 'Hier';
        } else {
            return convertDateToFrench(desiredDay);
        }
                                
    };

    const postTask = (task, file, toUsers, dueDate, isTask) => {
        const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);

        if(toUsers === null) {
            toUsers = 'undefined';
        }

        const fd = new FormData();
        if(file.length){
            for (let fN=0; fN<file.length; fN++) {
                fd.append(`file-${fN+1}`, file[fN]);
            }
        }
        fd.append('shipment_id',null);
        fd.append('customer_id', null);
        fd.append('comment', task);
        fd.append('created_by', currentUser);
        fd.append('to_users', toUsers);
        fd.append('due_date', dueDateConvert);
        fd.append('tracking_nbr', '');
        fd.append('is_task', isTask);
        axios.post(URL_All.CommentTask, fd, optionsAxios)
            .then(() => setRefresh(refresh + 1));
    }

    return (
        <Card className="dashboard-box">
            <Grid container>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Typography
                        className="box-title"
                        gutterBottom
                        variant="h5"
                        component="h2"
                    >
                        Tâches
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.addEventButton}>
                    <TaskForm postCommentTask={postTask} />
                </Grid>
            </Grid>
            <CardContent className={classes.cardContent}>
                <CardActions className={classes.contentHeader}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Button
                                disabled={isClickedFuture}
                                className={classes.textTransformNone}
                                variant={isClickedPast ? 'contained' : 'outlined'}
                                color={isClickedPast ? 'secondary' : 'primary'}
                                onClick={() => {
                                    if(isClickedPast) {
                                        setIsClickedPast(false);
                                        getTasksByDate(daysForTask);
                                    } else {
                                        setIsClickedPast(true);
                                        getTasksForDashboard(`get_tasks_past?user_id=${currentUser}`);
                                    }
                                }}
                            >
                                {taskCount.userpast} en retard
                            </Button>
                        </Grid>
                        <Grid item xs={6} className={`${classes.subtitleCenter} ${(isClickedFuture || isClickedPast) ? classes.subtitleCenterDisabled : ''}`}>
                            <IconButton
                                disabled={isClickedFuture || isClickedPast}
                                size="small"
                                onClick={() => {
                                    setDaysForTask(daysForTask - 1);
                                    getTasksByDate(daysForTask - 1);
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography
                            >
                                {selectedDayName()}
                            </Typography>
                            <IconButton
                                disabled={isClickedFuture || isClickedPast}
                                size="small"
                                onClick={() => {
                                    setDaysForTask(daysForTask + 1);
                                    getTasksByDate(daysForTask + 1);
                                }}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                disabled={isClickedPast}
                                className={classes.textTransformNone}
                                variant={isClickedFuture ? 'contained' : 'outlined'}
                                color={isClickedFuture ? 'secondary' : 'primary'}
                                onClick={() => {
                                    if(isClickedFuture) {
                                        setIsClickedFuture(false);
                                        getTasksByDate(daysForTask);
                                    } else {
                                        setIsClickedFuture(true);
                                        getTasksForDashboard(`get_tasks_future?user_id=${currentUser}`);
                                    }
                                }}
                            >
                                {taskCount.userfuture} à faire
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <div className={classes.contentBody}>
                    <Table>
                        <colgroup>
                            <col span="1" style={{width: "35%"}} />
                            <col span="1" style={{width: "35%"}} />
                            <col span="1" style={{width: "30%"}} />
                        </colgroup>
                        <TableBody className={classes.tableBody}>
                            {dashboardGetTasks.map(item => (
                                <TaskOpenSelected
                                    key={item.id}
                                    item={item}
                                    isClickedFuture={isClickedFuture}
                                    isClickedPast={isClickedPast}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        users: state.users,
        refresh: state.refresh,
        dashboardGetTasks: state.dashboardGetTasks,
        taskCount: state.taskCount
    };
};

export default connect(mapStateToProps, { setRefresh, getTasksForDashboard, getTaskCount })(Tasks);
