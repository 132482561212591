import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ArchiveTrackingHeader from './ArchiveTrackingHeader';
import ArchiveTrackingList from './ArchiveTrackingList';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { URL_All } from '../common/Constants';
import useToken from '../login/useToken';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
      stickyHead: {
        position: 'sticky',
        top: theme.spacing(-1),
        zIndex: 1,
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        background: '#F7F7F7',
      },
  }});

function ArchiveTrackingController(props) {
    const { classes } = useStyles();
    const { token } = useToken();
    const { statusObjectValues,
      refresh } = props;
    const [tracking, setTracking] = useState([]);
    const [isClickedHighlight, setClickedHighlight] = useState(false);
    const [statusValue, setStatusValue] = useState({});
    const [highlightCount, setHighlightCount] = useState([]);
    const [refreshKey,setRefreshKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageZero, setPageZero] = useState(0);

    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let findQuery = useQuery();
    let query = findQuery.get("search");
    const [input, setInput] = useState(query ? query : '');

    const location = useLocation();
 
    useEffect(() => {
      let mounted = true;
  
      if(mounted && query) {
        if(!token) return logOut();
      
        setIsLoading(true);
  
        fetch(URL_All.SearchShipment + query, {
          headers: {
            'authorization': `JWT ${token}`
          }
        })
          .then(response => {
            if (response.status === 403) {
              return logOut();
            } else if (response.status === 200) {
              return response.json();
            }
          })
          .then(json => {
            setIsLoading(false);
            setTracking(json.results);
          })
          .catch(error => console.error(error))
      }
  
      return () => mounted = false;
    }, [query, location, token]);

    useEffect(() => {
      fetch(URL_All.getStatusshipments)
          .then(response => response.json())
          .then(json => {
            setStatusValue(json);
            setHighlightCount(Object.values(json).filter(r => (r.highlighted === 1 & r.is_actif === 0)).map(g => g.shipment_id));
          })
          .catch(error => console.error(error))
    }, [setStatusValue, setHighlightCount, refreshKey, refresh]);

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => { 
      location.pathname === '/archive' && handleAllBtn();
    })
  },[history]);

  function handleAllBtn(){
      return [
        setClickedHighlight(false)
      ];
  }

  return (<div>
      <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
              <Paper className={classes.paper}>
                  <ArchiveTrackingHeader
                      input={input}
                      setInput={setInput}
                      setTracking={setTracking}
                      isClickedHighlight={isClickedHighlight}
                      setClickedHighlight={setClickedHighlight}
                      highlightCount={highlightCount.length}
                      setPageZero={setPageZero}
                  />
              </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
              <ArchiveTrackingList
                  tracking={tracking}
                  input={input}
                  refreshKey={refreshKey}
                  setRefreshKey={setRefreshKey}
                  isClickedHighlight={isClickedHighlight}
                  statusValue={statusValue}
                  highlightCount={highlightCount}
                  statusObjectValues={statusObjectValues}
                  isLoading={isLoading}
                  pageZero={pageZero}
              />
          </Grid>
      </Grid>
  </div>);
}

const mapStateToProps = state => {
  return { refresh: state.refresh };
};

export default connect(mapStateToProps)(ArchiveTrackingController);
