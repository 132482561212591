import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SubjectIcon from '@mui/icons-material/Subject';
import Grid from '@mui/material/Grid';
import { orange } from '@mui/material/colors';
import FormHelperText from '@mui/material/FormHelperText';
import { convertDateToWithDash, convertDateToTimestamp } from '../common/functions';


const useStyles = makeStyles()((theme) => { return {
  button: {
    textTransform: 'none',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 600,

    '&>*': {
      width: 350,
    },
  },
  participants: {
    marginBottom: 0,
  },
  timeFrom: {
    marginRight: theme.spacing(3),
  },
  error: {
    color: 'red',

    '& *': {
      color: 'red',
    },
    
    '& svg': {
      color: 'red',
    },
  },
  fields: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },

    '& .MuiTextField-root': {
      width: 300,
    },

    '& .MuiSvgIcon-root': {
      color: '#555',
    },
  },
  datePickers: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}});

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

const EditButton = withStyles(Button, (theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[300]),
    backgroundColor: orange[300],
    '&:hover': {
      backgroundColor: orange[500],
    },
  },
}));

function CalendarEditPopUp(props) {
  const { classes } = useStyles();
  const { handleEdit, item } = props;
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    addTitle: item.summary,
    dateFrom: convertDateToWithDash(item.start.dateTime),
    dateTo: convertDateToWithDash(item.end.dateTime),
    timeFrom: `${(new Date(item.start.dateTime)).getHours().toString().padStart(2, '0')}:${(new Date(item.start.dateTime)).getMinutes().toString().padStart(2, '0')}`,
    timeTo: `${(new Date(item.end.dateTime)).getHours().toString().padStart(2, '0')}:${((new Date(item.end.dateTime)).getMinutes()).toString().padStart(2, '0')}`,
    guests: item.attendees ? item.attendees : [],
    location: item.location,
    description: item.description
  });
  const [error, setError] = useState({
    addTitle: false,
    date: false,
    guests: false,
    location: false,
    description: false
  });
  const [editGuest, setEditGuest] = useState(item.attendees ? item.attendees.map(item => item.email).join(', ') : '');
  

  let startTime = new Date(newEvent.dateFrom).setHours(parseInt(newEvent.timeFrom.split(':')[0]), parseInt(newEvent.timeFrom.split(':')[1]), 0, 0);
  let endTime = new Date(newEvent.dateTo).setHours(parseInt(newEvent.timeTo.split(':')[0]), parseInt(newEvent.timeTo.split(':')[1]), 0, 0);

  const googleCalendarEvent = {
    'summary': newEvent.addTitle,
    'location': newEvent.location,
    'description': newEvent.description,
    'start': {
      'dateTime': new Date(startTime).toISOString(),
    },
    'end': {
      'dateTime': new Date(endTime).toISOString(),
    },
    'attendees': newEvent.guests,
    'reminders': {
    'useDefault': false,
    }
  };

  const handleClickOpen = () => {
    setOpen(true);

    setEditGuest(item.attendees ? item.attendees.map(item => item.email).join(', ') : '');
    
    setNewEvent({
      ...newEvent,
      addTitle: item.summary,
      dateFrom: convertDateToWithDash(item.start.dateTime),
      dateTo: convertDateToWithDash(item.end.dateTime),
      timeFrom: `${(new Date(item.start.dateTime)).getHours().toString().padStart(2, '0')}:${(new Date(item.start.dateTime)).getMinutes().toString().padStart(2, '0')}`,
      timeTo: `${(new Date(item.end.dateTime)).getHours().toString().padStart(2, '0')}:${((new Date(item.end.dateTime)).getMinutes()).toString().padStart(2, '0')}`,
      guests: item.attendees ? item.attendees : [],
      location: item.location,
      description: item.description
    });
    
    setError({
      ...error,
      addTitle: false,
      date: false,
      guests: false,
      location: false,
      description: false
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSave = () => {
    let dateTimeFromCheck = convertDateToTimestamp(new Date(startTime).toISOString());
    let dateTimeToCheck = convertDateToTimestamp(new Date(endTime).toISOString());

    if (dateTimeFromCheck > dateTimeToCheck) {
      setError({ ...error, date: true });

    } else {
      setError({ ...error, addTitle: false, date: false, guests: false });
      
      setOpen(false);
      handleEdit(googleCalendarEvent);
    }
  };

  const handleChangeGuests = event => {
    setEditGuest(event.target.value);
    
    const newArr = [];

    event.target.value.split(/[,\s;:/]/g).map(item => {
      if(item.length) {
        const newObject = new Object();
        newObject.email = item;
        return newArr.push(newObject);
      }
      
      return null;
    });
    
    setNewEvent({ ...newEvent, guests: newArr});
  };

  return (
    <div>
      <EditButton
        size="small"
        onClick={handleClickOpen}
      >
        Edit
      </EditButton>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Event
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <TextField
            variant="standard"
            error={error.addTitle}
            autoFocus
            id="outlined-basic"
            label="Add title"
            size="small"
            value={newEvent.addTitle}
            onChange={e => setNewEvent({ ...newEvent, addTitle: e.target.value})}
            helperText={error.addTitle ? 'This area cannot be empty' : ''} />
          <br />
          <form className={classes.formDatePicker} noValidate>
            <div className={classes.datePickers}>
              <TextField
                variant="standard"
                id="dateFrom"
                className={error.date ? classes.error : ''}
                label="Date From"
                type="date"
                value={newEvent.dateFrom}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setNewEvent({ ...newEvent, dateFrom: e.target.value })} />
              <TextField
                variant="standard"
                id="dateTo"
                className={error.date ? classes.error : ''}
                label="Date To"
                type="date"
                value={newEvent.dateTo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setNewEvent({ ...newEvent, dateTo: e.target.value })} />
            </div>
            <br />
            <div>
              <TextField
                variant="standard"
                id="time"
                label="From"
                type="time"
                value={newEvent.timeFrom}
                className={`${classes.timeFrom} ${error.date ? classes.error : ''}`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={e => setNewEvent({ ...newEvent, timeFrom: e.target.value })}
                aria-describedby="date-and-time-error-helper" />
              <TextField
                variant="standard"
                id="time"
                label="To"
                type="time"
                value={newEvent.timeTo}
                className={error.date ? classes.error : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={e => setNewEvent({ ...newEvent, timeTo: e.target.value })} />
            </div>
            <div>
              <FormHelperText
                id="date-and-time-error-helper"
                className={error.date ? classes.error : ''}
              >
                "Date{'&'}Time From" cannot be late than "Date{'&'}Time To"
              </FormHelperText>              
            </div>
          </form>
          <br />
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <PeopleAltIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                id="outlined-basic"
                label="Add guests (Only emails)"
                size="small"
                value={editGuest}
                onChange={handleChangeGuests} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <LocationOnIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                id="outlined-basic"
                label="Add location"
                size="small"
                value={newEvent.location}
                onChange={e => setNewEvent({ ...newEvent, location: e.target.value})} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            className={classes.fields}
            alignItems="flex-end"
          >
            <Grid item>
              <SubjectIcon fontSize="small" />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                id="outlined-basic"
                label="Add description"
                size="small"
                value={newEvent.description}
                onChange={e => setNewEvent({ ...newEvent, description: e.target.value})} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(null)(CalendarEditPopUp);
