import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { setSCustomerFilterButtons } from '../../actions';


const useStyles = makeStyles()((theme) => { return {
  secondLine: {
    marginBottom: theme.spacing(.5),
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  marginLeft16: {
    marginLeft: theme.spacing(2),
  },
}});

const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1664,
    },
  },
  palette: {
    v4default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
  },
});

const theme2 = createTheme({
  breakpoints: {
    values: {
      lg: 1820,
    },
  },
  palette: {
    v4default: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
    },
  },
});

function ByTypeOfCustomerHeader(props) {
  const { classes } = useStyles();
  const { statisticsCustomerFilterButtons,
    setSCustomerFilterButtons,
    drawerOpen,
  } = props;

  const handleFilters = props => () => {
    if(props === 'all') {
      return setSCustomerFilterButtons({ ...statisticsCustomerFilterButtons,
        twelveMonth: true,
        quarterFirst: true,
        quarterSecond: true,
        quarterThird: true,
        quarterFouth: true,
        daysSeven: true,
        daysOne: true,
        n1: true,
        n2: true,
        n3: true,
        inactiveOne: true,
        inactiveThree: true,
        inactiveSix: true,
        inactiveTwelve: true,
      });
    }

    return setSCustomerFilterButtons({ ...statisticsCustomerFilterButtons, [props]: !statisticsCustomerFilterButtons[props] });
  };
  
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={drawerOpen ? theme2 : theme}>
          <Grid container spacing={2}>
            <Grid item xs={2} lg={2} />
            <Grid item xs={7} lg={7}
              container
              justifyContent="space-between"
            >
              <Button
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsCustomerFilterButtons.twelveMonth ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.twelveMonth ? "secondary" : "v4default"}
                onClick={handleFilters('twelveMonth')}
              >
                12 mois
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.quarterFirst ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.quarterFirst ? "secondary" : "v4default"}
                onClick={handleFilters('quarterFirst')}
              >
                1ère trimestre
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.quarterSecond ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.quarterSecond ? "secondary" : "v4default"}
                onClick={handleFilters('quarterSecond')}
              >
                2ème trimestre
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.quarterThird ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.quarterThird ? "secondary" : "v4default"}
                onClick={handleFilters('quarterThird')}
              >
                3ème trimestre
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.quarterFouth ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.quarterFouth ? "secondary" : "v4default"}
                onClick={handleFilters('quarterFouth')}
              >
                4ème trimestre
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.daysSeven ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.daysSeven ? "secondary" : "v4default"}
                onClick={handleFilters('daysSeven')}
              >
                7 jours
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.daysOne ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.daysOne ? "secondary" : "v4default"}
                onClick={handleFilters('daysOne')}
              >
                1 jours
              </Button>
            </Grid>
            <Grid item xs={3} lg={3} />
            <Grid item xs={2} lg={2} />
            <Grid item xs={4} lg={3}
              className={classes.secondLine}
              container
              justifyContent="flex-start"
            >
              <Button
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsCustomerFilterButtons.n1 ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.n1 ? "secondary" : "v4default"}
                onClick={handleFilters('n1')}
              >
                N-1
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.n2 ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.n2 ? "secondary" : "v4default"}
                onClick={handleFilters('n2')}
              >
                N-2
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.n3 ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.n3 ? "secondary" : "v4default"}
                onClick={handleFilters('n3')}
              >
                N-3
              </Button>
            </Grid>
            <Grid item xs={3} lg={4} />
            <Grid item xs={3} lg={3} />

            <Grid item xs={2} lg={2} />
            <Grid item xs={7} lg={6}
              className={classes.secondLine}
              container
              justifyContent="flex-start"
            >
              <Button
                className={classes.buttonLowerCase}
                size="small"
                variant={statisticsCustomerFilterButtons.inactiveOne ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.inactiveOne ? "secondary" : "v4default"}
                onClick={handleFilters('inactiveOne')}
              >
                {'Inactifs < 1 mois'}
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.inactiveThree ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.inactiveThree ? "secondary" : "v4default"}
                onClick={handleFilters('inactiveThree')}
              >
                {'Inactifs < 3 mois'}
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.inactiveSix ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.inactiveSix ? "secondary" : "v4default"}
                onClick={handleFilters('inactiveSix')}
              >
                {'Inactifs < 6 mois'}
              </Button>
              <Button
                className={`${classes.buttonLowerCase} ${classes.marginLeft16}`}
                size="small"
                variant={statisticsCustomerFilterButtons.inactiveTwelve ? "contained" : "outlined"}
                color={statisticsCustomerFilterButtons.inactiveTwelve ? "secondary" : "v4default"}
                onClick={handleFilters('inactiveTwelve')}
              >
                {'Inactifs < 12 mois'}
              </Button>
            </Grid>        
            <Grid item xs={3} lg={4} />
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    statisticsCustomerFilterButtons: state.statisticsCustomerFilterButtons,
    drawerOpen: state.drawerOpen,
  };
};

export default connect(mapStateToProps, { setSCustomerFilterButtons })(ByTypeOfCustomerHeader);
