import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const useStyles = makeStyles()((theme) => { return {
    root: {
        // background: '#f00',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: 4,
        padding: '16px 8px 8px 16px',
    },
    button: {
        margin: '16px 0 32px 0',
        padding: '5px 20px',
        textTransform: 'none',
    },
    tableBody: {
        '& .MuiTableRow-root': {
            '& .MuiTableCell-root:first-of-type': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        '& .MuiTableCell-root': {
            padding: theme.spacing(1),
            borderBottom: 'none',
        },

        '& .offline .MuiTableCell-root:last-child': {
            color: '#555',
        },

        '& .online .MuiSvgIcon-root': {
            color: '#32a852',
        },
        '& .offline .MuiSvgIcon-root': {
            color: '#999',
        },
    },
    statusTitle: {
        fontWeight: 'bold',
        color: '#555',
        marginTop: theme.spacing(1),
    },
}});

const someData = [
    {
        isOnline: true,
        person: 'Guillaume',
        lastSeen: 'now',
    },
    {
        isOnline: true,
        person: 'Léonard',
        lastSeen: 'now',
    },
    {
        isOnline: true,
        person: 'Reda',
        lastSeen: 'now',
    },
    {
        isOnline: false,
        person: 'Yavuz',
        lastSeen: '8h',
    },
    {
        isOnline: false,
        person: 'Jehona',
        lastSeen: '10min',
    },
];


function Personnel() {
    const { classes } = useStyles();
    
    return (
        <div className={`personnel ${classes.root}`}>
            <Typography>
                Lundi 28 décembre 2021
            </Typography>
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
            >
                Pointage
            </Button>
            <Typography variant="h5">
                Connexions
            </Typography>
            <Typography className={classes.statusTitle}>
                En ligne
            </Typography>
            <Table>
                <colgroup>
                <col span="1" style={{width: "10%"}} />
                <col span="1" style={{width: "90%"}} />
                </colgroup>
                <TableBody className={classes.tableBody}>
                    {someData.filter(person => person.isOnline).map((item, i) => (
                        <TableRow key={i} className="online">
                            <TableCell><FiberManualRecordIcon fontSize="small" /></TableCell>
                            <TableCell>{item.person}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
            <Typography className={classes.statusTitle}>
                Hors ligne
            </Typography>
            <Table>
                <colgroup>
                <col span="1" style={{width: "10%"}} />
                <col span="1" style={{width: "70%"}} />
                <col span="1" style={{width: "20%"}} />
                </colgroup>
                <TableBody className={classes.tableBody}>
                    {someData.filter(person => !person.isOnline).map((item, i) => (
                        <TableRow key={i} className="offline">
                            <TableCell><FiberManualRecordIcon fontSize="small" /></TableCell>
                            <TableCell>{item.person}</TableCell>
                            <TableCell>{item.lastSeen}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default Personnel;
